import React from 'react';
import getDayKey from '../../../helpers/get-day-key';
import { DailyLogEntry } from '../../../services/typed_data';
import "./NotesTimeline.css"
import { format } from 'date-fns';
import { getDatesForMonth } from '../../../helpers/date-helpers';
import language from '../../../helpers/language';

export interface NotesTimelineProps {
	logEntries: { [key: string]: DailyLogEntry }
	currentMonth: number
	currentYear: number
}

export default function (props: NotesTimelineProps) {
	var monthDays = getDatesForMonth(props.currentYear, props.currentMonth);

	var daysWithNotes = monthDays.filter((m) => props.logEntries[getDayKey(m)]?.notes?.length);
	if (!daysWithNotes.length) {
		return null;
	}

	return (
		<div className="notes-timeline">
			<div className="title">{language["notes"]}</div>
			<div className="timeline-line"></div>
			{daysWithNotes.map((m) =>
				<div key={m.toISOString()} className="day">
					<i className={"day-icon fa fa-" + (props.logEntries[getDayKey(m)].icon ? props.logEntries[getDayKey(m)].icon : 'circle')}></i>
					<div className="day-title">{format(m, 'MMMM d, yyyy')}</div>
					<div className="note">{props.logEntries[getDayKey(m)].notes}</div>
				</div>
			)}
		</div>
	);
}