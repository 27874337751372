import React, { useEffect, useState } from 'react'
import { Card, ConnectGarmin, DateRangeNavigator, Layout, LoadingIndicator, StatusBarBackground, TextBlock } from "@careevolution/mydatahelps-ui"
import MyDataHelps, { ExternalAccount } from "@careevolution/mydatahelps-js"
import metricsConfiguration from '../../../helpers/metrics';
import loadAvailableMetrics from '../../../helpers/load-available-metrics';
import { useMemo } from 'react';
import symptomSharkData, { SymptomSharkParticipantInfo } from '../../../services/typed_data';
import { parseISO, add } from 'date-fns';
import getFitbitProviderID from '../../../helpers/get-fitbit-provider-id';
import { ConnectAppleHealth, ConnectFitbit, Correlator, DeviceActivityFilters, SyncIndicator } from '../../../components';
import language from '../../../helpers/language';
import getGarminProviderID from '../../../helpers/get-garmin-provider-id';

export interface DeviceActivityProps {
    colorScheme?: "light" | "dark" | "auto";
    onDaySelected(d: Date): void;
    onVisit?(): void;
    appleHealthConnectionSurvey?: string;
}

export default function (props: DeviceActivityProps) {
    var currentDate = new Date();
    currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0);
    var initialIntervalStart = currentDate;
    while (initialIntervalStart.getDay() != 0) {
        initialIntervalStart = add(initialIntervalStart, { days: -1 });
    }
    const [highlightOutOfRange, setHighlightOutOfRange] = useState(false);
    const [loading, setLoading] = useState(true);
    const [platform, setPlatform] = useState("");
    const [selectedDate, setSelectedDate] = useState(currentDate);
    const [intervalStartDate, setIntervalStartDate] = useState(initialIntervalStart);
    const [availableMetrics, setAvailableMetrics] = useState<string[]>([]);
    const [selectedMetrics, setSelectedMetrics] = useState<string[] | null>(null);
    const [selectedSymptoms, setSelectedSymptoms] = useState<string[]>([]);
    const [selectedTreatments, setSelectedTreatments] = useState<string[]>([]);
    const [symptomSharkParticipantInfo, setSymptomSharkParticipantInfo] = useState<SymptomSharkParticipantInfo | null>(null);
    const [fitbitExternalAccount, setFitbitExternalAccount] = useState<ExternalAccount | null>(null);
    const [garminExternalAccount, setGarminExternalAccount] = useState<ExternalAccount | null>(null);
    const [appleHealthEnabledDate, setAppleHealthEnabledDate] = useState<Date | null>(null);

    function load() {
        function finishLoad(savedSelectedMetrics: string[] | null) {
            MyDataHelps.getDeviceInfo().then(function (info) {
                symptomSharkData.getParticipantInfo().then(function (symptomSharkInfo) {
                    loadAvailableMetrics().then(function (metrics) {
                        setSymptomSharkParticipantInfo(symptomSharkInfo);
                        setPlatform(info?.platform ?? "iOS");
                        //only update metrics if they have actually changed, to prevent triggering a full correlator reload
                        setAvailableMetrics(function (previousMetrics) {
                            if (JSON.stringify(previousMetrics) == JSON.stringify(metrics)) {
                                return previousMetrics;
                            }
                            return metrics;
                        });
                        if (savedSelectedMetrics != null) {
                            savedSelectedMetrics = savedSelectedMetrics.filter(s => metrics.indexOf(s) != -1);
                            setSelectedMetrics(function (previousMetrics) {
                                if (JSON.stringify(savedSelectedMetrics) == JSON.stringify(previousMetrics)) {
                                    return previousMetrics;
                                }
                                return savedSelectedMetrics;
                            });
                        } else {
                            setSelectedMetrics([]);
                        }
                        setLoading(false);
                    });
                });
            });
        }

        if (selectedMetrics == null) {
            MyDataHelps.queryDeviceData({
                namespace: "Project",
                type: "SelectedDeviceDataTypes",
                limit: 1
            }).then(function (page) {
                if (page.deviceDataPoints.length) {
                    var selectedDataTypes = JSON.parse(page.deviceDataPoints[0].value);
                    finishLoad(selectedDataTypes);
                } else {
                    finishLoad(null);
                }
            });
        } else {
            finishLoad(selectedMetrics);
        }

        MyDataHelps.getExternalAccounts().then(function (accounts) {
            for (let i = 0; i < accounts.length; i++) {
                if (accounts[i].provider.id == getFitbitProviderID()) {
                    setFitbitExternalAccount(accounts[i]);
                }
            }
            for (let i = 0; i < accounts.length; i++) {
                if (accounts[i].provider.id == getGarminProviderID()) {
                    setGarminExternalAccount(accounts[i]);
                }
            }
            MyDataHelps.querySurveyAnswers({
                surveyName: props.appleHealthConnectionSurvey
            }).then(function (page) {
                if (page.surveyAnswers.length) {
                    setAppleHealthEnabledDate(parseISO(page.surveyAnswers[0].date));
                }
            })
        });
    }

    function trackPageVisit() {
        props.onVisit?.();
    }

    useEffect(() => {
        load();
        trackPageVisit();
        MyDataHelps.on("applicationDidBecomeVisible", load);
        MyDataHelps.on("applicationDidBecomeVisible", trackPageVisit);
        MyDataHelps.on("externalAccountSyncComplete", load);
        return () => {
            MyDataHelps.off("applicationDidBecomeVisible", load);
            MyDataHelps.off("applicationDidBecomeVisible", trackPageVisit);
            MyDataHelps.off("externalAccountSyncComplete", load);
        }
    }, []);

    function updateIntervalStart(intervalStart: Date) {
        setSelectedDate(intervalStart);
        setIntervalStartDate(intervalStart);
    }

    function updateSelectedDate(selectedDate: Date) {
        if (selectedDate > currentDate) {
            return;
        }
        setSelectedDate(selectedDate);
    }

    function getFinalMetrics() {
        if (!availableMetrics.length || !selectedMetrics) {
            return [];
        }
        return metricsConfiguration.filter((m) => selectedMetrics.indexOf(m.key) != -1);
    }

    function updateSelectedMetrics(selectedMetrics: string[]) {
        setSelectedMetrics(selectedMetrics);
        MyDataHelps.persistDeviceData([{
            type: "SelectedDeviceDataTypes",
            value: JSON.stringify(selectedMetrics),
            identifier: ""
        }]);
    }

    var availableMetricConfigurations = metricsConfiguration.filter((m) => availableMetrics.indexOf(m.key) != -1);
    var finalMetrics = useMemo(() => getFinalMetrics(), [selectedMetrics, availableMetrics]);

    let externalAccounts: ExternalAccount[] = [];
    if (fitbitExternalAccount) {
        externalAccounts.push(fitbitExternalAccount);
    }
    if (garminExternalAccount) {
        externalAccounts.push(garminExternalAccount);
    }

    return (
        <>
            {loading &&
                <LoadingIndicator />
            }
            {!loading &&
                <>
                    {availableMetricConfigurations.length > 0 &&
                        <>
                            <DateRangeNavigator variant="rounded" intervalStart={intervalStartDate} intervalType="Week" onIntervalChange={(d) => updateIntervalStart(d)} />
                        </>
                    }
                    <SyncIndicator externalAccounts={externalAccounts} appleHealthEnabledDate={appleHealthEnabledDate} />
                    {symptomSharkParticipantInfo && availableMetricConfigurations.length > 0 && !!selectedMetrics &&
                        <DeviceActivityFilters
                            appleHealthConnectionSurvey={props.appleHealthConnectionSurvey}
                            selectedMetrics={selectedMetrics}
                            selectedTreatments={selectedTreatments}
                            selectedSymptoms={selectedSymptoms}
                            symptomSharkParticipantInfo={symptomSharkParticipantInfo}
                            onMetricsSelectionChange={(m) => updateSelectedMetrics(m)}
                            onSymptomsSelectionChange={(s) => setSelectedSymptoms(s)}
                            onTreatmentsSelectionChange={(s) => setSelectedTreatments(s)}
                            availableMetricConfigurations={availableMetricConfigurations}
                            hasConnectedFitbit={!!fitbitExternalAccount}
                            hasConnectedGarmin={!!garminExternalAccount}
                            hasEnabledAppleHealth={!!appleHealthEnabledDate}
                            highlightOutOfRange={highlightOutOfRange}
                            onHighlightOutOfRangeChange={(h) => setHighlightOutOfRange(h)} />
                    }
                    {finalMetrics.length > 0 && !!symptomSharkParticipantInfo &&
                        <Correlator onIntervalChange={(d) => updateIntervalStart(d)}
                            intervalStartDate={intervalStartDate}
                            selectedDate={selectedDate}
                            onDateSelected={(d) => updateSelectedDate(d)}
                            metrics={finalMetrics}
                            symptomSharkParticipantInfo={symptomSharkParticipantInfo}
                            selectedSymptoms={selectedSymptoms}
                            selectedTreatments={selectedTreatments}
                            highlightOutOfRange={highlightOutOfRange}
                            onDaySelected={(d) => props.onDaySelected(d)} />
                    }
                    {!availableMetricConfigurations.length &&
                        <>
                            {!externalAccounts.find(a => a.provider.id == getFitbitProviderID()) &&
                                <Card>
                                    <ConnectFitbit />
                                </Card>
                            }
                            {!externalAccounts.find(a => a.provider.id == getGarminProviderID()) &&
                                <Card>
                                    <ConnectGarmin />
                                </Card>
                            }
                        </>
                    }
                    {!availableMetricConfigurations.length && platform == "iOS" && !!props.appleHealthConnectionSurvey && !appleHealthEnabledDate &&
                        <Card>
                            <ConnectAppleHealth appleHealthConnectionSurvey={props.appleHealthConnectionSurvey} />
                        </Card>
                    }
                    {availableMetricConfigurations.length > 0 && finalMetrics.length == 0 &&
                        <TextBlock>
                            <div style={{ textAlign: "center" }}>
                                {language["select-some-data-types"]}
                            </div>
                        </TextBlock>
                    }
                </>
            }
        </>
    )
}