import React, { useState } from "react";
import { SymptomConfiguration, TreatmentConfiguration } from "../../../services/typed_data";
import { ShinyOverlay, UnstyledButton } from "@careevolution/mydatahelps-ui";
import "./SetupStepItem.css";
import colors from "../../../helpers/colors";
import language from "../../../helpers/language";

export interface SetupStepItemProps {
	highlighted: boolean;
	item: SymptomConfiguration | TreatmentConfiguration;
	onItemChange(item: SymptomConfiguration | TreatmentConfiguration): void;
	onHighlight(item: SymptomConfiguration | TreatmentConfiguration): void;
	onDelete(item: SymptomConfiguration | TreatmentConfiguration): void;
}

function isSymptom(item: SymptomConfiguration | TreatmentConfiguration): item is SymptomConfiguration {
	return (item as SymptomConfiguration).severityTracking !== undefined;
}

export default function (props: SetupStepItemProps) {
	const [editing, setEditing] = useState<boolean>(false);

	var itemType = 'Treatment';
	if (isSymptom(props.item)) {
		itemType = 'Symptom';
	}

	var updateItemName = function (name: string) {
		var newItem = { ...props.item };
		newItem.name = name;
		props.onItemChange(newItem);
	}

	var updateColor = function (color: string) {
		var newItem = { ...props.item };
		newItem.color = color;
		props.onItemChange(newItem);
	}

	var updateSeverityTracking = function (severityTracking: 'None' | '3PointScale' | '10PointScale') {
		var newItem = { ...props.item } as SymptomConfiguration;
		newItem.severityTracking = severityTracking;
		props.onItemChange(newItem);
	}

	var updateInactive = function (inactive: boolean) {
		var newItem = { ...props.item };
		newItem.inactive = inactive;
		props.onItemChange(newItem);
	}

	return <div className={"setup-step-item" + (props.item.inactive ? " muted" : "") + (props.highlighted ? " highlighted" : "") + (!props.item.name.length ? " error" : "")}>
		<div className="item-list-item-content">
			<div className="item-color" style={{ backgroundColor: props.item.color }}></div>
			<input disabled={props.item.locked} title={language["enter-symptom-name"]} type="text" placeholder={itemType == "Symptom" ? language["enter-symptom-name"] : language["enter-treatment-name"]} value={props.item.name} onChange={(e) => updateItemName(e.target.value)} onFocus={() => props.onHighlight(props.item)} />
			{!props.item.locked &&
				<a href="javascript:{}" className="edit-button" onClick={() => setEditing(!editing)}>
					{language["options"]} <i className={"fa" + (!editing ? ' fa-chevron-down' : ' fa-chevron-up')}></i>
				</a>
			}
		</div>
		{editing &&
			<div className="item-editor">
				<div className="item-setting">
					<div className="color-picker">
						<div className="color-picker-slider">
							{colors.map(c =>
								<div key={c} className={"color" + (props.item.color == c ? ' selected' : "")} style={{ backgroundColor: c }} onClick={() => updateColor(c)}></div>
							)}
						</div>
					</div>
				</div>
				{itemType == "Symptom" &&
					<div className="item-setting">
						<div className="option-select-vertical" style={{ marginTop: "0" }}>
							<UnstyledButton className={"option" + ((props.item as SymptomConfiguration).severityTracking == 'None' ? ' selected' : '')} onClick={() => updateSeverityTracking('None')}>
								{language["severity-tracking-none"]}
								<ShinyOverlay />
							</UnstyledButton>
							<UnstyledButton className={"option" + ((props.item as SymptomConfiguration).severityTracking == '3PointScale' ? ' selected' : '')} onClick={() => updateSeverityTracking('3PointScale')}>
								{language["severity-tracking-3point"]}
								<ShinyOverlay />
							</UnstyledButton>
							<UnstyledButton className={"option" + ((props.item as SymptomConfiguration).severityTracking == '10PointScale' ? ' selected' : '')} onClick={() => updateSeverityTracking('10PointScale')}>
								{language["severity-tracking-10point"]}
								<ShinyOverlay />
							</UnstyledButton>
						</div>
					</div>
				}
				<div className="item-setting">
					<div className="option-select-horizontal">
						<UnstyledButton className={"option" + (!props.item.inactive ? " selected" : "")} onClick={() => updateInactive(false)}>
							{language["not-muted"]}
							<ShinyOverlay />
						</UnstyledButton>
						<UnstyledButton className={"option" + (props.item.inactive ? " selected" : "")} onClick={() => updateInactive(true)}>
							{language["muted"]}
							<ShinyOverlay />
						</UnstyledButton>
						<div style={{ clear: "both" }}></div>
					</div>
				</div>
				<div className="item-setting delete-button">
					<a href="javascript:{}" onClick={() => props.onDelete(props.item)}><i className="fa fa-trash"></i> {language["delete"]}</a>
				</div>
			</div>
		}
	</div>;
}