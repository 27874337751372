import React from 'react';
import "./FAQsPage.css"
import Nav from '../Nav/Nav';
import WebPage from '../WebPage/WebPage';
import Wave from '../Wave/Wave';
import FAQ from '../FAQ/FAQ';

export default function () {
	return (
		<WebPage className="faqs-page">
			<Nav selectedPage="FAQs" />
			<div className="page-header story-page-header">
				<img className="header-spice" src='/images/hero-spice.png' />
				<Wave reverse={true} reverseX={true} />
			</div>
			<div className="page-content">
				<h2>Frequently Asked Questions</h2>
				<FAQ question="What do the symbols on the calendar mean?">
					<p>The calendar is a quick way to view your logged symptoms and treatments over time.</p>
					<p style={{ textAlign: "center" }}>
						<img width="80" src="images/pie.png" />
					</p>
					<p>The big circle (pie chart) displays all the colors of symptoms logged for that day.</p>
					<p>The small dots above a day indicate the treatments used.  Up to 3 treatments are displayed.</p>
				</FAQ>
				<FAQ question="How do I log symptom severity?">
					<p>
						Symptom Shark lets you track symptom severity in 3 different ways:
					</p>
					<ul>
						<li>No severity tracking (default)</li>
						<li>Mild/Moderate/Severe Rating</li>
						<li>1-10 Point Rating</li>
					</ul>
					<p>
						You can set this up by going to "Settings", selecting "Edit Symptoms", and selecting "Options" for the symptom you want to track severity for:
					</p>
					<p style={{ textAlign: "center" }}>
						<img className="tutorial-image" src="images/severity_setup.png" />
					</p>
					<p>
						If a symptom has severity tracking enabled, it will prompt you for the severity when you select that symptom:
					</p>
					<p style={{ textAlign: "center" }}>
						<img className="tutorial-image" src="images/severity_10point.png" />
						<img className="tutorial-image" src="images/severity_3point.png" />
					</p>
					<p>
						You can then view this information by tapping or clicking the symptom on the symptom counter below the calendar:
					</p>
					<p style={{ textAlign: "center" }}>
						<img className="tutorial-image" src="images/calendar_severity.png" />
						<img className="tutorial-image" src="images/detail.png" />
					</p>
					<p>
						When you select specific symptoms to view on the calendar, the severity tracking for those symptoms will appear at the bottom of this screen.  This enables you to view and compare multiple symptoms at one time.
					</p>
					<p style={{ textAlign: "center" }}>
						<img className="tutorial-image" src="images/compare.png" />
					</p>
					<p>
						This information will also be displayed in any reports you generate which include the severity tracked symptoms:
					</p>
					<p style={{ textAlign: "center" }}>
						<img className="tutorial-image" src="images/reports.png" />
					</p>
				</FAQ>
				<FAQ question="How do I log symptom timing, or treatment quantity or timing?">
					<p>
						Symptom Shark is primarily a daily symptom and treatment tracker which is meant to track the presence or absence of a symptom of treatment across time.  We're continuing to add more features which enable more detailed tracking.
					</p>
					<p>
						However, there are a few ways to use Symptom Shark for more detailed tracking:
					</p>
					<ul>
						<li>Use notes on a particular day to record more detailed information</li>
						<li>Set up different symptoms for different timings - for instance, "Migraine - Morning"</li>
						<li>Set up different treatments for different quantities or timings - for instance, "Tylenol 500mg" or "Migraine - Morning"</li>
					</ul>
				</FAQ>
				<FAQ question="How does Symptom Shark help me prepare for appointments?">
					<p>
						As questions about your health come up, we recommend logging them via <strong>Dr. Q&amp;A</strong> so you can reference them during your next appointment.
					</p>
					<p>
						Using <strong>Reports</strong>, you can also export a history of your symptoms and treatments and print or share it with your doctor or caregiver.
					</p>
				</FAQ>
				<FAQ question="How do I update my symptoms or treatments?">
					<p>
						Go to "Settings" to add, remove, or update your symptoms or treatments.
					</p>
					<p>
						If you don't want to remove a symptom or treatment but want to hide it for the time being, you may "mute" it.  This will leave the data intact but hide that symptom or treatment in the app.
					</p>
				</FAQ>
				<FAQ question="How do I record an important event that may impact my symptoms (for instance, starting a new medication)?">
					<p>
						The icons (updated by selecting the "star") can be used to denote important days.  It is also recommended to enter notes on the day for future reference.
					</p>
				</FAQ>
				<div className="pacing" id="pacing">
					<FAQ question="Why am I not receiving pacing notification, or why are my pacing notifications delayed?" initiallyExpanded={window.location.hash.substr(1) == "pacing"}>
						<p>Pacing notifications can only be sent when Symptom Shark receives data from Fitbit or Apple Health.  Sometimes there can be delays in syncing that result in notifications not being sent in a timely fashion.  Additionally, if you are using an Apple Health device (Apple Watch, Garmin, Withings, etc.) data can only sync when your phone is unlocked.</p>
						<p>If data is not coming in, follow these steps to troubleshoot:</p>
						<ul>
							<li>Check that your phone has bluetooth enabled</li>
							<li>Check that your wearable device is syncing with it's app (e.g. the Fitbit or Garmin app)</li>
							<li>Wait a few minutes.  Especially when you first sync a device, it may take a few minutes for data to come in.</li>
						</ul>
						<p>
							If you are connecting via Apple Health, there are some additional troubleshooting steps you can take:
						</p>
						<ul>
							<li>Ensure that your device is connecting to Apple Health.  For instance, if you are using a Garmin or Withings device, you will need to go into the Garmin or Withings app to enable the device to send data to Apple Health.</li>
							<li>
								Check that you have enabled the right permissions for MyDataHelps to access Apple Health data:
								<ol>
									<li>Open the "Settings" app</li>
									<li>Select "Privacy"</li>
									<li>Select "Health"</li>
									<li>Select "MyDataHelps"</li>
									<li>Enable the categories of data you would like to share with Symptom Shark</li>
								</ol>
							</li>
							<li>Ensure that the data you are looking for is showing in Apple Health and in the Symptom Shark "Activity" view.  For instance, the steps you see in Apple Health for yesterday should match thet steps you see in Symptom Shark for yesterday.</li>
						</ul>
					</FAQ>
				</div>
				<FAQ question="What if I want a different Pacing Notification that I don't see on the  &quot;Pacing Nofications&quot; screen?" initiallyExpanded={window.location.hash.substr(1) == "pacing"}>
					<p>We would love your feedback!  We plan to continually improve pacing notifications based on feedback from the community.</p>
					<p>Submit your feedback in the Symptom Shark app, or email <a href="mailto:symptomshark@careevolution.com">symptomshark@careevolution.com</a>.</p>
				</FAQ>
				<FAQ question="How can I connect my wearable device to view activity, heart rate, and/or sleep data on the &quot;activity&quot; view?">
					<p>The "activity" view currently supports Fitbit or Apple Health compatible devices (for instance, Apple Watch).  </p>
					<p>You may connect your Fitbit by visiting the "Activity" tab and selecting "Connect to Fitbit".  You will be prompted to log into Fitbit to complete the connection.</p>
					<p>If you are using an iPhone, you can select "Connect to Apple Health" to import your Apple Health data. </p>
					<p>Apple Health connects to a variety of different apps and devices, such as Apple Watch, Garmin, and Withings smartwatches and activity trackers.</p>
					<p>If you're using an iPhone and have already connected Fitbit or Apple Health, you may connect to the other by opening the "Data Types" dropdown.</p>
				</FAQ>

				<FAQ question="Why isn't my data showing up on the &quot;activity&quot; view?">
					<p>Symptom Shark will periodically retrieve your data from Fitbit or Apple Health.  If data is not coming in, follow these steps to troubleshoot:</p>
					<ul>
						<li>Check that your phone has bluetooth enabled</li>
						<li>Check that your wearable device is syncing with it's app (e.g. the Fitbit or Garmin app)</li>
						<li>Wait a few minutes.  Especially when you first sync a device, it may take a few minutes for data to come in.</li>
					</ul>
					<p>
						If you are connecting via Apple Health, there are some additional troubleshooting steps you can take:
					</p>
					<ul>
						<li>Ensure that your device is connecting to Apple Health.  For instance, if you are using a Garmin or Withings device, you will need to go into the Garmin or Withings app to enable the device to send data to Apple Health.</li>
						<li>
							Check that you have enabled the right permissions for MyDataHelps to access Apple Health data:
							<ol>
								<li>Open the "Settings" app</li>
								<li>Select "Privacy"</li>
								<li>Select "Health"</li>
								<li>Select "MyDataHelps"</li>
								<li>Enable the categories of data you would like to share with Symptom Shark</li>
							</ol>
						</li>
						<li>Ensure that the data you are looking for is showing in Apple Health.  For instance, the steps you see in Apple Health for yesterday should match thet steps you see in Symptom Shark for yesterday.</li>
					</ul>
				</FAQ>
				<FAQ question="How does the &quot;activity&quot; view work?">
					<p>The activity view brings in your Fitbit or Apple Health activity, heart rate, and sleep data and can display it alongside your symptoms in a weekly view.</p>
					<p>Use the "Data Types" dropdown to select what types of data you'd like to see.</p>
					<p>You can also filter your Symptoms and Treatments displayed in the weekly view using the "Select Symptoms" and "Select Treatments" dropdowns.</p>
					<p>Your 30 day average is used to calculate the height of the bars in the bar graph.  For instance, if your 30 day average was 5000 steps, and today you walked 10,000 steps, the bar would be twice as tall as your 30 day average.</p>
					<p>You can also select "Highlight out of range values" to see days which are different from your average. </p>
				</FAQ>
				<FAQ question="How do you make sure my data is kept private and secure? How do you ensure my confidentiality?">
					<p>
						All data collected is encrypted and secured by the third party CareEvolution. CareEvolution has undergone rigorous third party independent verification of the processes and technologies it uses to manage the security and privacy of the information it manages. It meets the stringent FISMA (Federal Information Security Management Act) 800-53 Rev4 guidelines to protect information. Regular penetration testing and third party audits verifying that CareEvolution continues to meet the security and privacy requirements that are in place.
					</p>
					<p>
						All data is maintained in the United States.
					</p>
				</FAQ>
				<FAQ question="How will my data be used if I join Symptom Shark?">
					<p>
						Your data will never be sold or used for advertising.
					</p>
					<p>
						Symptom Shark collects your data for the purposes of:
					</p>
					<ul>
						<li>Improving general health, medical and fitness management and for the purposes of medical research.</li>
						<li>Understanding, customizing and improving user experience. For example, we may engage analytics services to analyze this information in order to help us understand how users engage with and navigate Symptom Shark, how and when features within Symptom Shark are used and by how many users.</li>
						<li>We may use the data without your identifying information (name, contact information, email address) to support research and health or quality improvement initiatives with external collaborators and partners.</li>
					</ul>
				</FAQ>
			</div>
		</WebPage >
	);
}