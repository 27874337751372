import { Calendar, DateRangeNavigator, NavigationBar, Section, useInterval } from '@careevolution/mydatahelps-ui';
import React, { useEffect, useRef, useState } from 'react';
import { CalendarDay, DailyLogEntry, getDayKey, OverallExperienceChart, SymptomTreatmentFilters, SymptomTreatmentHistograms } from '../../..';
import { SymptomSharkParticipantInfo } from '../../../services/typed_data';
import { demoLogEntries, demoSymptoms, demoTreatments } from '../DemoData';
import "./DemoCalendar.css"

export default function () {
	var intervalStartDate = new Date(2022, 10, 1, 0, 0, 0, 0);

	var logEntries = demoLogEntries;

	const calendarElement = useRef<HTMLDivElement>(null);

	var info: SymptomSharkParticipantInfo = {
		symptoms: demoSymptoms,
		treatments: demoTreatments,
		participantID: "1",
		appleHealthEnabled: false,
		phoneNumber: "1",
		promotePacingNotifications: false,
		reminder1Enabled: false,
		reminder2Enabled: false,
		reminderTime1: "",
		reminderTime2: ""
	}

	function renderDay(year: number, month: number, day?: number) {
		if (!day) {
			return <div />;
		}
		var date = new Date(year, month, day);
		var logEntry = logEntries[getDayKey(date)];

		var future = false;
		if (date > new Date()) {
			future = true;
		}

		return <CalendarDay onClick={(d) => { }}
			day={day}
			logEntry={logEntry}
			symptomConfigurations={info.symptoms}
			treatmentConfigurations={info.treatments}
			future={future} />;
	}

	const [scrolledToBottom, setScrolledToBottom] = useState<boolean>(false);
	function advanceAnimation() {
		setScrolledToBottom(!scrolledToBottom);
	}

	useInterval(advanceAnimation, 7000);
	useEffect(function () {
		setScrolledToBottom(true);
	}, []);

	return (
		<div className="demo-calendar-wrapper">
			<div className="demo-calendar" style={{ top: scrolledToBottom ? "-530px" : "0px" }}>
				<NavigationBar title="" variant='compressed'>
					<DateRangeNavigator className='demo-navbar' variant="rounded" intervalStart={intervalStartDate} intervalType="Month" onIntervalChange={(d) => { }} />
				</NavigationBar>
				<Section>
					<SymptomTreatmentFilters
						selectedSymptoms={[]}
						selectedTreatments={[]}
						expandedDropdown={null}
						onExpandedDropdownChange={(e) => { }}
						onSymptomsSelectionChange={(s) => { }}
						onTreatmentsSelectionChange={(t) => { }}
						symptomSharkParticipantInfo={info} />
				</Section>
				<Section>
					<div style={{ borderTop: "solid 1px #ddd" }}>
						<Calendar month={intervalStartDate.getMonth()}
							year={intervalStartDate.getFullYear()}
							dayRenderer={renderDay} />
					</div>
				</Section>
				<SymptomTreatmentHistograms symptoms={info.symptoms}
					treatments={info.treatments}
					currentMonth={intervalStartDate.getMonth()}
					currentYear={intervalStartDate.getFullYear()}
					logEntries={logEntries}
					onSymptomSelected={(s) => { }} />
				<OverallExperienceChart currentYear={intervalStartDate.getFullYear()}
					currentMonth={intervalStartDate.getMonth()}
					logEntries={logEntries} />
				<div style={{ height: "16px" }} />
			</div>
		</div>
	);
}