import React from "react";
import "./MonthReport.css";
import { DailyLogEntry, SymptomConfiguration, TreatmentConfiguration } from "../../../services/typed_data";
import NotesTimeline from "../NotesTimeline/NotesTimeline";
import { getMonthName } from "../../../helpers/date-helpers";
import BulletChart from "../BulletChart/BulletChart";
import OverallExperienceChart from "../OverallExperienceChart/OverallExperienceChart";
import SymptomSeverityChart from "../SymptomSeverityChart/SymptomSeverityChart";

interface MonthReportProps {
	symptoms: SymptomConfiguration[];
	treatments: TreatmentConfiguration[];
	logEntries: { [key: string]: DailyLogEntry };
	currentMonth: number;
	currentYear: number;
	includeNotes: boolean;
	includeDailyOverallFeeling: boolean;
	productLogo?: string;
	productName?: string;
	productUrl?: string;
}

export default function (props: MonthReportProps) {
	return <table className="month-report" cellPadding="0" cellSpacing="0">
		<thead>
			<tr>
				<th>
					<div className="header">
						{getMonthName(props.currentMonth)} {props.currentYear}
						<div className="product">
							{props.productLogo &&
								<img src={props.productLogo} />
							}
							{props.productName &&
								<div className="product-name">{props.productName}</div>
							}
							{props.productUrl &&
								<a href={"https://" + props.productUrl} className="product-url">{props.productUrl}</a>
							}
						</div>
					</div>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>
					<BulletChart symptoms={props.symptoms.filter(s => !s.inactive)} treatments={props.treatments.filter(s => !s.inactive)} currentMonth={props.currentMonth} currentYear={props.currentYear} logEntries={props.logEntries} />
					{props.includeDailyOverallFeeling &&
						<OverallExperienceChart logEntries={props.logEntries} showAllDays={true} currentMonth={props.currentMonth} currentYear={props.currentYear} />
					}
					{props.symptoms.filter((s) => !s.inactive && s.severityTracking && s.severityTracking != 'None').map(s =>
						<SymptomSeverityChart symptom={s} showAllDays={true} currentMonth={props.currentMonth} currentYear={props.currentYear} logEntries={props.logEntries} />
					)}
					{props.includeNotes &&
						<NotesTimeline logEntries={props.logEntries} currentMonth={props.currentMonth} currentYear={props.currentYear} />
					}
				</td>
			</tr>
		</tbody>
	</table>;
}