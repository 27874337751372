import MyDataHelps, { PersistableDeviceDataPoint } from "@careevolution/mydatahelps-js";
import { add, parseISO } from "date-fns";
import { MetricConfiguration } from "../types/MetricConfiguration";
import { checkDailyDataAvailability, queryDailyData } from "@careevolution/mydatahelps-ui"
import getDayKey from "./get-day-key";
import queryAllDeviceData from "./query-all-device-data";

export interface MetricsQueryResult {
	metricsByDay: { [key: string]: { [key: string]: number } };
}

export function queryMetrics(startDate: Date, endDate: Date, metricConfigurations: MetricConfiguration[]): Promise<MetricsQueryResult> {
	var promises = metricConfigurations.map((p) => queryDailyData(p.dailyDataType, startDate, endDate));
	var result: MetricsQueryResult = {
		metricsByDay: {}
	};
	return Promise.all(promises).then((values) => {
		var date = startDate;
		while (date < endDate) {
			var dayKey = getDayKey(date);

			result.metricsByDay[dayKey] = {};
			metricConfigurations.forEach((m, index) => {
				if (values[index][dayKey]) {
					result.metricsByDay[dayKey][m.key] = values[index][dayKey];
				}
			});
			date = add(date, { days: 1 });
		}
		return result;
	});
}

export function bustCache(metricConfigurations: MetricConfiguration[], cacheFreshnessDates: { [key: string]: Date }, cache: MetricsQueryResult) {
	var metricsToBust = metricConfigurations.filter(m => cacheFreshnessDates[m.key]);
	var availabilityChecks = metricsToBust.map(m => checkDailyDataAvailability(m.dailyDataType, cacheFreshnessDates[m.key]));
	return Promise.all(availabilityChecks).then(function (values) {
		for (var i = 0; i < metricsToBust.length; i++) {
			if (values[i]) {
				for (var key in cache.metricsByDay) {
					delete cache.metricsByDay[key][metricsToBust[i].key];
				}
			}
		}
	});
}

export function queryCachedMetrics(startDate: Date, endDate: Date, metricConfigurations: MetricConfiguration[]): Promise<MetricsQueryResult> {
	var types = metricConfigurations.map(t => "DailyMetric_" + t.key);
	return queryAllDeviceData({
		observedAfter: add(startDate, { days: -1 }).toISOString(),
		observedBefore: add(endDate, { days: 1 }).toISOString(),
		namespace: "Project",
		type: types,
		modifiedAfter: "2022-09-24T18:19:19Z"
	}).then(function (cachedItems) {
		var result: MetricsQueryResult = { metricsByDay: {} };
		var cacheFreshnessDates: { [key: string]: Date } = {};
		for (var i = 0; i < cachedItems.length; i++) {
			var dayKey = cachedItems[i].observationDate!.substr(0, 10);
			if (!result.metricsByDay[dayKey]) {
				result.metricsByDay[dayKey] = {};
			}

			var metricKey = cachedItems[i].type.substr("DailyMetric_".length);
			result.metricsByDay[dayKey][metricKey] = parseFloat(cachedItems[i].value);

			var modifiedDate = parseISO(cachedItems[i].modifiedDate);
			if (!cacheFreshnessDates[metricKey] || modifiedDate > cacheFreshnessDates[metricKey]) {
				cacheFreshnessDates[metricKey] = modifiedDate;
			}
		}

		return bustCache(metricConfigurations, cacheFreshnessDates, result).then(function () {
			var metricsToQuery: MetricConfiguration[] = [];
			var promises: Promise<{ [key: string]: number }>[] = [];
			for (var i = 0; i < metricConfigurations.length; i++) {
				var queryStartDate = startDate;
				while (queryStartDate < endDate) {
					if (!result.metricsByDay[getDayKey(queryStartDate)] || (!result.metricsByDay[getDayKey(queryStartDate)][metricConfigurations[i].key] && result.metricsByDay[getDayKey(queryStartDate)][metricConfigurations[i].key] !== 0)) {
						break;
					}
					queryStartDate = add(queryStartDate, { days: 1 });
				}

				var queryEndDate = endDate;
				while (queryEndDate > startDate) {
					if (!result.metricsByDay[getDayKey(queryEndDate)] || (!result.metricsByDay[getDayKey(queryEndDate)][metricConfigurations[i].key] && result.metricsByDay[getDayKey(queryEndDate)][metricConfigurations[i].key] !== 0)) {
						break;
					}
					queryEndDate = add(queryEndDate, { days: -1 });
				}

				if (queryStartDate < queryEndDate) {
					metricsToQuery.push(metricConfigurations[i]);
					promises.push(queryDailyData(metricConfigurations[i].dailyDataType, queryStartDate, queryEndDate));
				}
			}

			if (metricsToQuery.length == 0) {
				return result;
			}

			return Promise.all(promises).then((values) => {
				var newCachedItems: PersistableDeviceDataPoint[] = [];
				var date = startDate;
				while (date < endDate) {
					var dayKey = getDayKey(date);
					if (!result.metricsByDay[dayKey]) {
						result.metricsByDay[dayKey] = {};
					}

					metricsToQuery.forEach((m, index) => {
						if (values[index][dayKey]) {
							result.metricsByDay[dayKey][m.key] = values[index][dayKey];
							newCachedItems.push({
								identifier: "",
								observationDate: dayKey + "T00:00:00+00:00",
								value: values[index][dayKey].toString(),
								type: "DailyMetric_" + m.key,
								properties: { "Calculated": new Date().toISOString() }
							});
						} else if (!result.metricsByDay[dayKey][m.key]) {
							newCachedItems.push({
								identifier: "",
								observationDate: dayKey + "T00:00:00+00:00",
								value: "0",
								type: "DailyMetric_" + m.key,
								properties: { "Calculated": new Date().toISOString() }
							});
						}
					});
					date = add(date, { days: 1 });
				}
				MyDataHelps.persistDeviceData(newCachedItems);
				return result;
			});
		})
	})
}