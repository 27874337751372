import React from 'react';
import "./HomePage.css"
import { SetupStep } from '../..';
import { SymptomConfiguration } from '../../..';
import language from '../../../helpers/language';
import Feature from '../Feature/Feature';
import FeatureContent from '../FeatureContent/FeatureContent';
import FeatureImage from '../FeatureImage/FeatureImage';
import Hero from '../Hero/Hero';
import Nav from '../Nav/Nav';
import Wave from '../Wave/Wave';
import WebPage from '../WebPage/WebPage';
import DemoCalendar from '../DemoCalendar/DemoCalendar';

export default function () {
	var initialSymptoms: SymptomConfiguration[] = [
		{ 'id': 'Fatigue', 'name': "Fatigue", 'color': '#c4291c', severityTracking: '3PointScale' },
		{ 'id': 'BrainFog', 'name': "Brain Fog", 'color': '#e35c33', severityTracking: '3PointScale' },
		{ 'id': 'Pain', 'name': "Pain", 'color': '#5db37e', severityTracking: '10PointScale' },
		{ 'id': 'Migraine', 'name': "Migraine", 'color': '#429bdf', severityTracking: 'None' },
		{ 'id': 'Anxiety', 'name': "Anxiety", 'color': '#7b88c6', severityTracking: 'None' },
		{ 'id': 'RacingHeart', 'name': "Racing Heart", 'color': '#616161', severityTracking: 'None' },
		{ 'id': 'StomachProblems', 'name': "Stomach Problems", 'color': '#d98177', severityTracking: '3PointScale' }
	];

	return (
		<WebPage className="home-page">
			<Nav selectedPage="Home" />
			<Hero />
			<Feature waves={false} className="your-symptom-tracker" imagePosition="Left">
				<FeatureImage>
					<SetupStep
						title={language["symptom-setup-intro"]}
						description={language["symptom-setup-description"]}
						doneButtonText={language["symptom-setup-next"]}
						initialSetup={true}
						requireItems={true}
						initialItems={initialSymptoms}
						inputPlaceholder={language["symptom-setup-placeholder"]}
						stepIdentifier="Symptoms"
						concept="Symptoms" />
				</FeatureImage>
				<FeatureContent>
					<h2>
						<strong>YOUR</strong> Symptom Tracker
					</h2>
					<p>
						Designed for patients with multiple or complex chronic conditions.  Choose the symptoms and treatments YOU want to track.  For managing conditions like:
					</p>
					<ul>
						<li>Migraines</li>
						<li>Anxiety and Depression</li>
						<li>Fibromyalgia</li>
						<li><a href="me-cfs-long-covid.html">Long COVID and ME/CFS</a></li>
						<li>Other Chronic Illness</li>
					</ul>
				</FeatureContent>
			</Feature>
			<Feature waves={true} className="come-prepared" imagePosition="Right">
				<FeatureImage>
					<DemoCalendar />
				</FeatureImage>
				<FeatureContent>
					<h2 style={{ paddingTop: "40px" }}>
						<strong>Come Prepared</strong> to Appointments
					</h2>
					<p>
						Communicating accurate information to your doctor can be overwhelming.  This is especially true if you have many symptoms. Symptom Shark enables you to:
					</p>
					<ul>
						<li>Quickly access stats and trends of symptoms and treatments</li>
						<li>Generate reports to share with others</li>
						<li>Keep track of questions for your next appointment</li>
					</ul>
				</FeatureContent>
			</Feature>
			<Feature waves={false} className="fatigued" imagePosition="Left">
				<FeatureImage>
					<img src="/images/tracking.png" />
				</FeatureImage>
				<FeatureContent>
					<h2>
						Fatigued or have "Brain Fog"?
					</h2>
					<p>
						Symptom Shark was designed by a patient to be as easy to use as possible, even when you don’t have energy.  Just select the symptoms experienced and treatments used, and optionally enter some notes about your day.  You can always go back and update your log later.
					</p>
				</FeatureContent>
			</Feature>
			<Feature waves={true} className="device-data" imagePosition="Right">
				<FeatureImage>
					<img src="/images/activity.png" />
				</FeatureImage>
				<FeatureContent>
					<h2 style={{ paddingTop: "40px" }}>
						Sync your Sleep, Steps, <span style={{ whiteSpace: "nowrap" }}>Heart Rate</span> and more
					</h2>
					<p>
						If you have a Fitbit or Apple Health enabled wearable, you can connect it to Symptom Shark.  Compare your wearable data to your recent average.
					</p>
					<p>
						Enable <a style={{ color: "#FFF", textDecoration: "underline" }} href="me-cfs-long-covid.html">Pacing Notifications</a> to get updates on your activity throughout the day.
					</p>
				</FeatureContent>
			</Feature>
			<div className="security">
				<div className="logos">
					<img src="images/mydatahelps.svg" />
					<img src="images/ce.svg" />
				</div>
				<p>
					Symptom Shark is on the secure MyDataHelps platform, built by <a href="https://careevolution.com">CareEvolution</a>.  CareEvolution has been managing patient data for over 15 years.   We will never sell your data or use it for advertising.
				</p>
				<p>
					We use appropriate physical, organizational, and technical safeguards designed to protect the confidentiality, security, and integrity of your data. For example, your data is encrypted both while it is stored and while it is transmitted using National Institutes of Standards and Technology (NIST) Federal Information Processing Standard (FIPS) Publication 140-2 recommended security standards for securing data.
				</p>
				<p><a target="_blank" href="https://careevolution.com/AppPrivacyPolicy.html">View the Privacy Policy</a></p>
			</div>
		</WebPage>
	);
}