import React from "react";
import { ActivityMeter } from "@careevolution/mydatahelps-ui";
import "./DailyDeviceActivityMetrics.css"
import { DailyMetric } from "../../../helpers/summarize-metrics";
import { MetricConfiguration } from "../../../types/MetricConfiguration";
import { getDailyMetricColor } from "../../../helpers/get-daily-metric-color";
import language from "../../../helpers/language";

export interface DailyDeviceActivityMetricsProps {
	metricConfigurations: MetricConfiguration[];
	metricValues: { [key: string]: DailyMetric };
	averageFillPercent: number;
	highlightOutOfRange: boolean;
}

export default function (props: DailyDeviceActivityMetricsProps) {
	if (props.metricConfigurations.length == 0) {
		return null;
	}

	function getDescription(metric: DailyMetric | null) {
		if (!metric) { return ""; }
		var variance = metric.variance;
		if (variance == null) {
			return language["not-enough-data"]
		}
		if (variance <= -2) {
			return language["below-personal-range"];
		}
		if (variance >= 2) {
			return language["above-personal-range"];
		}
		return language["in-personal-range"];
	}

	return <div className="daily-device-activity-metrics">
		{props.metricConfigurations.map((m) => {
			return <ActivityMeter key={m.key}
				className="ss-activity-meter"
				label={m.label}
				averageFillPercent={props.averageFillPercent}
				fillPercent={props.metricValues[m.key] ? props.metricValues[m.key].barFillPercent : 0}
				color={props.highlightOutOfRange ? getDailyMetricColor(props.metricValues[m.key]) : (props.metricValues[m.key] ? m.color : "#bbbbbb")}//  getDailyMetricColor(props.metricValues[m.key])}
				icon={m.icon}
				value={props.metricValues[m.key] ? m.formatter(props.metricValues[m.key].value) : "--"}
				message={props.highlightOutOfRange ? getDescription(props.metricValues[m.key]) : undefined} />
		})}
	</div>;
}