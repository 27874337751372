import React from 'react'
import { Layout, StatusBarBackground } from "@careevolution/mydatahelps-ui"

import DeviceActivity from '../../components/Container/DeviceActivity/DeviceActivity';

export interface DeviceActivityViewProps {
	colorScheme?: "light" | "dark" | "auto";
	onDaySelected(d: Date): void;
	onVisit?(): void;
	appleHealthConnectionSurvey?: string;
}

export default function (props: DeviceActivityViewProps) {
	return (
		<Layout className="device-activity-view symptom-shark-view" colorScheme={props.colorScheme || "light"}>
			<StatusBarBackground color="var(--mdhui-background-color-1)" />
			<DeviceActivity {...props} />
		</Layout>
	)
}