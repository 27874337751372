import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { CardTitle, Button } from "@careevolution/mydatahelps-ui"
import "./ConnectFitbit.css"
import MyDataHelps, { ExternalAccount } from "@careevolution/mydatahelps-js"
import language from '../../../helpers/language'

export default function () {
	const [loading, setLoading] = useState(true);
	const [fitbitExternalAccount, setFitbitExternalAccount] = useState<ExternalAccount | null>(null);

	function getFitbitProviderID() {
		var fitbitProviderID = 564;
		if (!MyDataHelps.baseUrl || MyDataHelps.baseUrl.startsWith("https://mdhorg.ce.dev")) {
			fitbitProviderID = 2;
		}
		return fitbitProviderID;
	}

	function initialize() {
		MyDataHelps.getExternalAccounts().then(function (accounts) {
			for (let i = 0; i < accounts.length; i++) {
				if (accounts[i].provider.id == getFitbitProviderID()) {
					setFitbitExternalAccount(accounts[i]);
				}
			}
			setLoading(false);
		});
	}

	function connectToFitbit() {
		MyDataHelps.connectExternalAccount(getFitbitProviderID());
	}

	useEffect(() => {
		initialize();
		MyDataHelps.on("applicationDidBecomeVisible", initialize);
		MyDataHelps.on("externalAccountSyncComplete", initialize);
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", initialize);
			MyDataHelps.off("externalAccountSyncComplete", initialize);
		}
	}, []);

	if (loading) { return null; }

	return (
		<div className="connect-fitbit">
			<CardTitle title="Connect to Fitbit" />
			{!loading &&
				<div className="content">
					{!fitbitExternalAccount &&
						<div>
							<div className="subtitle">{language["fitbit-intro"]}</div>
							<Button className="connect-button" onClick={() => connectToFitbit()}>{language["fitbit-connect-button"]}</Button>
						</div>
					}
					{fitbitExternalAccount &&
						<div className="subtitle downloading">
							<FontAwesomeIcon icon={faRefresh} spin /> {language["fitbit-connecting"]}
						</div>
					}
				</div>
			}
		</div>
	);
}