import MyDataHelps from "@careevolution/mydatahelps-js"
import englishStrings from "./strings-en"
import spanishStrings from "./strings-es"
import dutchStrings from "./strings-nl"

var getLanguageStrings = function () {
	var keys = Object.keys(englishStrings);
	var result: { [key: string]: string } = {};
	keys.forEach(k => {
		result[k] = englishStrings[k];
		if (MyDataHelps.getCurrentLanguage().toLowerCase().startsWith("es") && spanishStrings[k]) {
			result[k] = spanishStrings[k];
		}
		if (MyDataHelps.getCurrentLanguage().toLowerCase().startsWith("nl") && dutchStrings[k]) {
			result[k] = dutchStrings[k];
		}
	});
	return result;
}

export default getLanguageStrings();