import { DayTrackerSymbol, Face, SparkBarChart, SparkBarChartBar } from '@careevolution/mydatahelps-ui';
import React from 'react';
import { getDailyMetricColor } from '../../../helpers/get-daily-metric-color';
import { DailyMetric } from '../../../helpers/summarize-metrics';
import { DailyLogEntry, SymptomSharkParticipantInfo } from '../../../services/typed_data';
import { MetricConfiguration } from '../../../types/MetricConfiguration';
import "./DeviceActivityCalendarDay.css"

export interface DeviceActivityCalendarDayProps {
	loading: boolean;
	averageFillPercent: number;
	date: Date;
	metricConfigurations: MetricConfiguration[];
	metricValues: { [key: string]: DailyMetric };
	selected: boolean;
	logEntry?: DailyLogEntry;
	participantInfo: SymptomSharkParticipantInfo;
	selectedSymptoms: string[];
	selectedTreatments: string[];
	highlightOutOfRange: boolean;
}

export default function (props: DeviceActivityCalendarDayProps) {
	var currentDate = new Date();
	currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0);
	var isFuture = false;
	if (props.date > currentDate) {
		isFuture = true;
	}

	function getDayTracker(entry: DailyLogEntry) {
		var selectedSymptoms = props.selectedSymptoms.length > 0 ? entry.symptoms.filter((s) => props.selectedSymptoms.indexOf(s.id) != -1) : entry.symptoms;
		var selectedTreatments = props.selectedTreatments.length > 0 ? entry.treatments.filter((s) => props.selectedTreatments.indexOf(s.id) != -1) : entry.treatments;
		var primaryColors = selectedSymptoms.map(t => props.participantInfo.symptoms.find(s => s.id == t.id)?.color).filter(t => !!t).map(t => t!);
		var secondaryColors = selectedTreatments.map(t => props.participantInfo.treatments.find(s => s.id == t.id)?.color).filter(t => !!t).map(t => t!);
		if (primaryColors.length === 0 && secondaryColors.length === 0) { return null; }
		//key prevents rendering weirdness, for some reason?  
		return <DayTrackerSymbol key={primaryColors.join('|') + ',' + secondaryColors.join('|')} primaryColors={primaryColors} secondaryColors={secondaryColors} />
	}

	var bars: SparkBarChartBar[] = [];
	if (!!props.metricValues) {
		bars = props.metricConfigurations.map((b) => {
			return {
				color: props.highlightOutOfRange ? getDailyMetricColor(props.metricValues[b.key]) : b.color,
				barFillPercent: !props.metricValues[b.key] ? 0 : props.metricValues[b.key].barFillPercent
			};
		});
	}

	return <div key={props.date.getTime()} className={"device-activity-calendar-day " + (props.selected ? "selected" : "") + (isFuture ? "future" : "")}>
		<div className="day-symptoms-marker">
			{!props.loading && !!props.logEntry && getDayTracker(props.logEntry)}
		</div>
		<div className="face-wrapper">
			{!props.loading && props.logEntry && props.logEntry.overallFeeling &&
				<Face className="ss-face" faceValue={props.logEntry.overallFeeling} selected={true} />
			}
		</div>
		<div className="day-bar-wrapper">
			{!props.loading && props.metricValues &&
				<SparkBarChart
					averageFillPercent={props.averageFillPercent}
					bars={bars} />
			}
		</div>
	</div>;
}
