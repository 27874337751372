import { DayTrackerSymbol, UnstyledButton } from '@careevolution/mydatahelps-ui';
import React from 'react';
import { DailyLogEntry, SymptomConfiguration, TreatmentConfiguration } from '../../../services/typed_data';
import "./CalendarDay.css"

export interface CalendarDayProps {
	day: number;
	logEntry: DailyLogEntry;
	symptomConfigurations: SymptomConfiguration[]
	treatmentConfigurations: TreatmentConfiguration[]
	onClick(day: number): void;
	future: boolean;
}

export default function (props: CalendarDayProps) {
	function getDayTracker() {
		if (!props.logEntry) {
			return <DayTrackerSymbol className='day-tracker' primaryColors={[]} secondaryColors={[]} />;
		}
		var primaryColors = props.symptomConfigurations.filter(t => !t.inactive && props.logEntry.symptoms.find(s => s.id == t.id)).map(t => t.color);
		var secondaryColors = props.treatmentConfigurations.filter(t => !t.inactive && props.logEntry.treatments.find(s => s.id == t.id)).map(t => t.color);
		if (primaryColors.length === 0 && secondaryColors.length === 0) { return <DayTrackerSymbol className='day-tracker' primaryColors={[]} secondaryColors={[]} />; }
		return <DayTrackerSymbol className='day-tracker' key={Math.random()} primaryColors={primaryColors} secondaryColors={secondaryColors} />
	}

	return (
		<UnstyledButton className={"calendar-day" + (props.future ? " future" : "")} onClick={() => props.onClick(props.day)}>
			{getDayTracker()}
			<div className="day-label">
				<i className={"day-icon fa fa-" + (props.logEntry?.icon ? props.logEntry.icon : 'star') + (props.logEntry?.icon && props.logEntry.icon != 'star-o' ? " visible" : "")} ></i>
				&nbsp;{props.day}&nbsp;
				<i className={"fa fa-file-text-o" + (props.logEntry?.notes.length ? " visible" : "")}></i>
			</div>
		</UnstyledButton>
	);
}