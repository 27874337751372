import { DailyMetric } from "./summarize-metrics";

export function getDailyMetricColor(dailyMetric: DailyMetric) {
	var color = "#e0dcd7";
	if (!dailyMetric || dailyMetric.variance == null) {
		return color;
	}
	if (Math.abs(dailyMetric.variance) >= 0) {
		color = "#a1d3b4";
	}
	if (Math.abs(dailyMetric.variance) >= 2) {
		color = "#f5b722";
	}
	if (Math.abs(dailyMetric.variance) >= 3) {
		color = "#ee8800";
	}
	if (Math.abs(dailyMetric.variance) >= 4) {
		color = "#e35c33";
	}
	return color;
}
