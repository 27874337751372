import React from 'react';
import "./StoryPage.css"
import Nav from '../Nav/Nav';
import WebPage from '../WebPage/WebPage';
import Wave from '../Wave/Wave';

export default function () {
	return (
		<WebPage className="story-page">
			<Nav selectedPage="Story" />
			<div className="page-header story-page-header">
				<img className="header-spice" src='/images/hero-spice.png' />
				<Wave reverse={true} reverseX={true} />
			</div>
			<div className="page-content">
				<h2>Designed by a Patient</h2>
				<p>
					My name is Amber and I designed Symptom Shark because of my experience being a patient over the years. 
				</p>
				<p>
					When I was 10 years old, I started having migraines, which have continued through my adulthood. A few years ago, I had a medical event that triggered multiple chronic illnesses to develop. I have since learned to manage fibromyalgia, ME/CFS, muscle spasms, insomnia, and numerous other health issues.
					I became a professional patient and started to notice patterns as I was meeting with doctors or specialists:
				</p>
				<ul>
					<li>The appointment would be a month or two away</li>
					<li>By the time the day of my appointment rolled around, I was often more concerned about a different issue (for instance I would be seeing a migraine specialist - but my recent problem was insomnia)</li>
					<li>During the appointment, I would be asked many “on the spot” questions about the issue.  For instance, how many migraines I had over the past 3 months</li>
				</ul>
				<p>
					It could take me hours to prepare for appointments. I had to sift through my paper journal and prepare a summary. I’d pull out the information I thought the doctor would want to ask me about. Even then, I had a hard time giving them accurate information and often felt like I was “spinning my wheels.” This was all made more complicated and overwhelming because I was dealing with multiple chronic issues at the same time (not to mention brain fog).
				</p>
				<p>
					I was hearing the same story from other patients, and wanted to put my experience to work in finding a solution. I recruited my husband, who works for <a target="_blank" href="https://careevolution.com">CareEvolution</a> on projects like the <a target="_blank" href="https://detectstudy.org">DETECT health study</a>.  Working with him, I was able to bring “Symptom Shark” to life.  We designed it to be quick to set up and as easy as possible to use on a day-to-day basis, while remaining flexible.
				</p>
				<p>
					Advocating for your own health is so important.  The more data you have at hand, the more accurate a diagnosis or treatment plan can be.  I designed Symptom Shark to help me keep track of my own health.  My hope is that it can do the same for others.
				</p>
				<p>Sincerely,<br />Amber</p>
			</div>
		</WebPage>
	);
}