import React from 'react';
import Wave from '../Wave/Wave';
import "./Feature.css"

export interface FeatureProps {
	children?: React.ReactNode;
	className?: string;
	imagePosition: "Left" | "Right";
	waves: boolean;
}

export default function (props: FeatureProps) {
	return (
		<div className={"feature-wrapper" + (props.className ? (" " + props.className) : "") + (props.imagePosition == "Right" ? " image-right" : "")}>
			{props.waves &&
				<Wave reverse={false} />
			}
			<div className="feature">
				{props.children}
			</div>
			{props.waves &&
				<Wave reverse={true} />
			}
		</div>
	);
}