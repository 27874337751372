import React from 'react'
import "./PacingNotificationConfiguration.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Switch } from '@careevolution/mydatahelps-ui';

export interface PacingNotificationConfigurationProps {
	icon: IconDefinition;
	title: string;
	description: string;
	enabled: boolean;
	color: string;
	onChange(enabled: boolean): void;
	children?: React.ReactNode;
}

export default function (props: PacingNotificationConfigurationProps) {

	return (
		<div className="pacing-notification-configuration">
			<FontAwesomeIcon className="pacing-notification-icon" style={{ color: props.enabled ? props.color : "#999" }} icon={props.icon} />
			<div className="pacing-notification-title">{props.title}</div>
			<div className="pacing-notification-description">{props.description}</div>
			<Switch className='pacing-notification-switch' isOn={props.enabled} onValueChanged={(e) => props.onChange(e)} onBackgroundColor="#429bdf" />
			{props.children}
		</div>
	)
}