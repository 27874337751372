import React, { useState, useEffect } from 'react';
import MyDataHelps from "@careevolution/mydatahelps-js"
import symptomSharkData, { PacingNotificationsConfiguration, SymptomSharkParticipantInfo } from '../../services/typed_data';
import { Layout, LoadingIndicator, NavigationBar, Section, UnstyledButton } from '@careevolution/mydatahelps-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faRunning } from '@fortawesome/free-solid-svg-icons';
import "./SettingsView.css"
import language from '../../helpers/language';
import { SymptomSharkLogo } from '../../components/Presentational/SymptomSharkLogo/SymptomSharkLogo';

export interface SettingsViewProps {
	colorScheme?: "light" | "dark" | "auto";
	onVisit?(): void;
}

export default function (props: SettingsViewProps) {
	const [loading, setLoading] = useState(true);
	const [participantInfo, setParticipantInfo] = useState<SymptomSharkParticipantInfo | null>(null);
	const [pacingNotificationConfiguration, setPacingNotificationConfiguration] = useState<PacingNotificationsConfiguration | null>(null);

	function trackPageVisit() {
		props.onVisit?.();
	}

	function initialize() {
		symptomSharkData.getParticipantInfo().then(function (participantInfo) {
			symptomSharkData.getPacingNotificationsConfiguration().then(function (pacingConfig) {
				setPacingNotificationConfiguration(pacingConfig);
				setParticipantInfo(participantInfo);
				setLoading(false);
			});
		});
	}

	function startSurvey(survey: string) {
		MyDataHelps.startSurvey(survey);
	}

	function showAccount() {
		MyDataHelps.showDashboard("Account", { title: "Account", modal: false });
	}

	function openFaqs() {
		MyDataHelps.openEmbeddedUrl(new URL("../faqs.html?hideNav=true", document.location as any).href);
	}

	function showPacingNotifications() {
		MyDataHelps.openApplication(new URL("./pacing-notifications.html", document.location as any).href, { modal: true });
	}

	function pacingNotificationStatus() {
		if (!pacingNotificationConfiguration?.notificationsEnabled.length) {
			return language["disabled"];
		}

		var types: string[] = [];
		if (pacingNotificationConfiguration?.notificationsEnabled.indexOf("steps") != -1) {
			types.push(language["steps"]);
		}
		if (pacingNotificationConfiguration?.notificationsEnabled.indexOf("appleHealthHeartRate") != -1 ||
			pacingNotificationConfiguration?.notificationsEnabled.indexOf("fitbitElevatedHeartRate") != -1) {
			types.push(language["heart-rate"]);
		}
		if (pacingNotificationConfiguration?.notificationsEnabled.indexOf("fitbitCaloriesBurned") != -1) {
			types.push(language["calories-burned"]);
		}

		if (!types.length) { return language["disabled"]; }
		return types.join(" • ");
	}

	useEffect(() => {
		initialize();
		trackPageVisit();
		MyDataHelps.on("applicationDidBecomeVisible", initialize);
		MyDataHelps.on("applicationDidBecomeVisible", trackPageVisit);
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", initialize);
			MyDataHelps.off("applicationDidBecomeVisible", trackPageVisit);
		}
	}, []);

	return (
		<Layout className="settings-view symptom-shark-view" colorScheme={props.colorScheme || "light"}>
			<NavigationBar variant='compressed' title={language["settings"]} />
			{loading &&
				<LoadingIndicator />
			}
			{!loading && participantInfo && pacingNotificationConfiguration &&
				<Section>
					<UnstyledButton className="menu-item" onClick={() => startSurvey('UpdateSymptoms')}>
						<i className="fa fa-gear item-icon" style={{ color: "#429bdf" }}></i>
						<div className="menu-item-title">{language["edit-symptoms"]}</div>
						<div className="menu-item-description">{participantInfo.symptoms.filter((s) => !s.inactive).length} {language["active-symptoms"]}</div>
						<i className="fa fa-chevron-right"></i>
					</UnstyledButton>
					<UnstyledButton className="menu-item" onClick={() => startSurvey('UpdateTreatments')}>
						<i className="fa fa-gear item-icon" style={{ color: '#ee8800' }}></i>
						<div className="menu-item-title">{language["edit-treatments"]}</div>
						<div className="menu-item-description">{participantInfo.treatments.filter((s) => !s.inactive).length} {language["active-treatments"]}</div>
						<i className="fa fa-chevron-right"></i>
					</UnstyledButton>
					<UnstyledButton className="menu-item" onClick={() => startSurvey('UpdateReminders')}>
						<i className="fa fa-clock-o item-icon" style={{ color: '#5db37e' }}></i>
						<div className="menu-item-title">{language["edit-reminders"]}</div>
						{(participantInfo.reminder1Enabled || participantInfo.reminder2Enabled) &&
							<div className="menu-item-description">
								{participantInfo.reminder1Enabled &&
									<span>{participantInfo.reminderTime1}</span>
								}
								{participantInfo.reminder1Enabled && participantInfo.reminder2Enabled &&
									<span>&nbsp;&bull;&nbsp;</span>
								}
								{participantInfo.reminder2Enabled &&
									<span>{participantInfo.reminderTime2}</span>
								}
							</div>
						}
						{!participantInfo.reminder1Enabled && !participantInfo.reminder2Enabled &&
							<div className="menu-item-description">{language["no-reminders"]}</div>
						}
						<i className="fa fa-chevron-right"></i>
					</UnstyledButton>
					<UnstyledButton className="menu-item" onClick={() => showPacingNotifications()}>
						<FontAwesomeIcon icon={faRunning} style={{ color: "#f5b722" }} className="item-icon" />
						<FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#e35c33" }} className="item-icon-accessory" />
						<div className="menu-item-title">{language["pacing-notifications"]}</div>
						<div className="menu-item-description">{pacingNotificationStatus()}</div>
						<i className="fa fa-chevron-right"></i>
					</UnstyledButton>
					<UnstyledButton className="menu-item" onClick={() => showAccount()}>
						<i className="fa fa-user item-icon" style={{ color: '#7b88c6' }} ></i>
						<div className="menu-item-title">{language["account"]}</div>
						<div className="menu-item-description">{language["account-description"]}</div>
						<i className="fa fa-chevron-right"></i>
					</UnstyledButton>
				</Section>
			}
			<div style={{ height: "1px" }} />
			<Section>
				<UnstyledButton className="menu-item" onClick={() => openFaqs()}>
					<i className="fa fa-question-circle-o item-icon" style={{ color: '#eec04c' }}></i>
					<div className="menu-item-title">{language["faqs"]}</div>
					<div className="menu-item-description">{language["faqs-description"]}</div>
					<i className="fa fa-chevron-right"></i>
				</UnstyledButton>
				<UnstyledButton className="menu-item" onClick={() => MyDataHelps.startSurvey('Feedback')}>
					<i className="fa fa-comment-o item-icon" style={{ color: '#8333a4' }}></i>
					<div className="menu-item-title">{language["provide-feedback"]}</div>
					<div className="menu-item-description">{language["provide-feedback-description"]}</div>
					<i className="fa fa-chevron-right"></i>
				</UnstyledButton>
			</Section>
			<SymptomSharkLogo />
		</Layout>
	);
}