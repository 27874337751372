import { UnstyledButton } from "@careevolution/mydatahelps-ui";
import React from "react";
import "./IconSelector.css"

interface IconSelectorProps {
	onIconSelect(icon: string): void;
}

export default function (props: IconSelectorProps) {
	var icons = [
		'star-o',
		'star',
		'user-md',
		'hospital-o',
		'ambulance',
		'flag',
		'moon-o',
		'flask',
		'bolt',
		'question-circle-o',
		'thermometer',
		'trophy',
		'spoon'
	];


	return <div className="icon-selector">
		{icons.map(i =>
			<UnstyledButton key={i} title={i} className="unstyled-button icon-item" onClick={() => props.onIconSelect(i)}>
				<i className={"fa fa-" + i}></i>
			</UnstyledButton>
		)}
	</div >;

}