import React from 'react';
import "./Wave.css"

export interface WaveProps {
	reverse: boolean,
	reverseX: boolean
}

export default function (props: WaveProps) {
	return (
		<div className={"wave-container" + (props.reverse ? " reverse" : "") + (props.reverseX ? " reverse-x" : "")}>
			<svg viewBox="0 0 500 500" preserveAspectRatio="none">
				<path d="M 0 35 C 150 135 350 -65 500 35 L 500 -65 L 0 -65 Z" style={{ stroke: "none", fill: "#FFF", width: "100%" }}></path>
			</svg>
		</div>
	);
}