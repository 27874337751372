import React from 'react';
import { SymptomConfiguration, SymptomSharkParticipantInfo, TreatmentConfiguration } from '../../../services/typed_data';
import { ShinyOverlay, TrackerItem, UnstyledButton } from '@careevolution/mydatahelps-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import language from '../../../helpers/language';
import "./SymptomTreatmentFilters.css";

export interface SymptomTreatmentFiltersProps {
	expandedDropdown: "Symptoms" | "Treatments" | null;
	onExpandedDropdownChange(expandedDropdown: "Symptoms" | "Treatments" | null): void;
	selectedSymptoms: string[];
	selectedTreatments: string[];
	symptomSharkParticipantInfo: SymptomSharkParticipantInfo;
	onSymptomsSelectionChange(symptoms: string[]): void;
	onTreatmentsSelectionChange(treatments: string[]): void;
}

export default function (props: SymptomTreatmentFiltersProps) {

	function toggleSymptom(item: SymptomConfiguration) {
		var newSelectedItems = [...props.selectedSymptoms];
		if (newSelectedItems.indexOf(item.id) != -1) {
			newSelectedItems.splice(newSelectedItems.indexOf(item.id), 1);
		} else {
			newSelectedItems.push(item.id);
		}
		props.onSymptomsSelectionChange(newSelectedItems);
	}

	function toggleTreatment(item: TreatmentConfiguration) {
		var newSelectedItems = [...props.selectedTreatments];
		if (newSelectedItems.indexOf(item.id) != -1) {
			newSelectedItems.splice(newSelectedItems.indexOf(item.id), 1);
		} else {
			newSelectedItems.push(item.id);
		}
		props.onTreatmentsSelectionChange(newSelectedItems);
	}

	return <div className="symptom-treatment-filters">
		<UnstyledButton className="dropdown-button" onClick={() => props.expandedDropdown == "Symptoms" ? props.onExpandedDropdownChange(null) : props.onExpandedDropdownChange("Symptoms")}
			style={{ fontWeight: props.selectedSymptoms.length > 0 ? "bold" : "" }}>
			{language["select-symptoms"]} <FontAwesomeIcon icon={props.expandedDropdown == "Symptoms" ? faCaretUp : faCaretDown} />
			<ShinyOverlay />
		</UnstyledButton>
		<UnstyledButton className="dropdown-button" onClick={() => props.expandedDropdown == "Treatments" ? props.onExpandedDropdownChange(null) : props.onExpandedDropdownChange("Treatments")}
			style={{ fontWeight: props.selectedTreatments.length > 0 ? "bold" : "" }}>
			{language["select-treatments"]} <FontAwesomeIcon icon={props.expandedDropdown == "Treatments" ? faCaretUp : faCaretDown} />
			<ShinyOverlay />
		</UnstyledButton>
		{props.expandedDropdown == "Symptoms" &&
			<div className="items-list">
				{props.symptomSharkParticipantInfo.symptoms.filter(s => !s.inactive).map((m) =>
					<TrackerItem className="item" selected={props.selectedSymptoms.indexOf(m.id) != -1} onClick={() => toggleSymptom(m)} color={m.color} key={m.id} text={m.name} />
				)}
			</div>
		}
		{props.expandedDropdown == "Treatments" &&
			<div className="items-list">
				{props.symptomSharkParticipantInfo.treatments.filter(s => !s.inactive).map((m) =>
					<TrackerItem className="item" selected={props.selectedTreatments.indexOf(m.id) != -1} onClick={() => toggleTreatment(m)} color={m.color} key={m.id} text={m.name} />
				)}
			</div>
		}
	</div>;
}