import React from 'react';
import "./DeviceDataTypeFilter.css";
import { MetricConfiguration } from "../../../types/MetricConfiguration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Button, PlatformSpecificContent, ShinyOverlay, TrackerItem, UnstyledButton } from '@careevolution/mydatahelps-ui';
import MyDataHelps from "@careevolution/mydatahelps-js"
import getFitbitProviderID from '../../../helpers/get-fitbit-provider-id';
import language from '../../../helpers/language';
import getGarminProviderID from '../../../helpers/get-garmin-provider-id';

export interface DeviceDataTypeFilterProps {
	appleHealthConnectionSurvey?: string;
	allAvailableMetrics: MetricConfiguration[];
	selectedMetrics: string[];
	highlightOutOfRange: boolean;
	onSelectionChange(metrics: string[]): void;
	onHighlightOutOfRangeChange(highlightOutOfRange: boolean): void;
	expanded: boolean;
	onExpand(): void;
	hasConnectedFitbit: boolean;
	hasConnectedGarmin: boolean;
	hasEnabledAppleHealth: boolean;
}

export default function (props: DeviceDataTypeFilterProps) {
	function toggleMetric(metric: MetricConfiguration) {
		var newSelectedMetrics = [...props.selectedMetrics];
		if (newSelectedMetrics.indexOf(metric.key) != -1) {
			newSelectedMetrics.splice(newSelectedMetrics.indexOf(metric.key), 1);
			MyDataHelps.trackCustomEvent({
				eventType: "ActivityMetricDisabled",
				properties: {
					"Metric": metric.key as any
				}
			}).catch();
		} else {
			newSelectedMetrics.push(metric.key);
			MyDataHelps.trackCustomEvent({
				eventType: "ActivityMetricEnabled",
				properties: {
					"Metric": metric.key as any
				}
			}).catch();
		}
		props.onSelectionChange(newSelectedMetrics);
	}

	let sources = 0;
	if (props.allAvailableMetrics.find((p) => p.source == "Apple Health")) sources++;
	if (props.allAvailableMetrics.find((p) => p.source == "Fitbit")) sources++;
	if (props.allAvailableMetrics.find((p) => p.source == "Garmin")) sources++;
	var multiSource = sources > 1;

	return <div className="device-data-type-filter">
		<UnstyledButton className="dropdown-button" onClick={() => props.onExpand()}>
			{language["select-data-types"]} ({props.selectedMetrics.length}/{props.allAvailableMetrics.length}) <FontAwesomeIcon icon={props.expanded ? faCaretUp : faCaretDown} />
			<ShinyOverlay />
		</UnstyledButton>
		{props.expanded &&
			<div className="metrics-list">
				{multiSource && !!props.allAvailableMetrics.find((p) => p.source == "Apple Health") &&
					<div className="metrics-section-header">
						Apple Health
					</div>
				}
				{props.allAvailableMetrics.filter((p) => p.source == "Apple Health").map((m) =>
					<TrackerItem className="metric-selector" selected={props.selectedMetrics.indexOf(m.key) != -1} onClick={() => toggleMetric(m)} color={m.color} key={m.key} text={m.label} />
				)}
				{multiSource && !!props.allAvailableMetrics.find((p) => p.source == "Fitbit") &&
					<div className="metrics-section-header">
						Fitbit
					</div>
				}
				{props.allAvailableMetrics.filter((p) => p.source == "Fitbit").map((m) =>
					<TrackerItem className="metric-selector" selected={props.selectedMetrics.indexOf(m.key) != -1} onClick={() => toggleMetric(m)} color={m.color} key={m.key} text={m.label} />
				)}
				{multiSource && !!props.allAvailableMetrics.find((p) => p.source == "Garmin") &&
					<div className="metrics-section-header">
						Garmin
					</div>
				}
				{props.allAvailableMetrics.filter((p) => p.source == "Garmin").map((m) =>
					<TrackerItem className="metric-selector" selected={props.selectedMetrics.indexOf(m.key) != -1} onClick={() => toggleMetric(m)} color={m.color} key={m.key} text={m.label} />
				)}
				<div className="metrics-section-header">
					{language["options"]}
				</div>
				<TrackerItem selected={props.highlightOutOfRange} onClick={() => props.onHighlightOutOfRangeChange(!props.highlightOutOfRange)} color="#7b88c6" text={language["highlight-out-of-range"]} />
				<p>{language["range-explanation"]}</p>
				{!props.hasConnectedFitbit &&
					<Button variant='subtle' onClick={() => MyDataHelps.connectExternalAccount(getFitbitProviderID())}>{language["connect-fitbit-prompt"]}</Button>
				}
				{!props.hasConnectedGarmin &&
					<Button variant='subtle' onClick={() => MyDataHelps.connectExternalAccount(getGarminProviderID())}>{language["connect-garmin-prompt"]}</Button>
				}
				<PlatformSpecificContent platforms={["iOS"]}>
					{!props.hasEnabledAppleHealth && !!props.appleHealthConnectionSurvey &&
						<Button variant='subtle' onClick={() => MyDataHelps.startSurvey(props.appleHealthConnectionSurvey!)}>{language["apple-health-connect-button"]}</Button>
					}
				</PlatformSpecificContent>
			</div>
		}
	</div >;
}