import React, { useState } from 'react';
import "./Nav.css"

export interface NavProps {
	selectedPage: "Home" | "Story" | "ME/CFS" | "FAQs";
}

export default function (props: NavProps) {
	const [expanded, setExpanded] = useState<Boolean | null>(null);

	if (new URLSearchParams(window.location.search).get("hideNav") == "true") {
		return null;
	}

	return (
		<div className="ss-webnav">
			<div className="nav">
				<a className="logo" href="/">
					<img src="app/images/ss_new.png" />
					&nbsp;<strong>Symptom</strong> Shark
				</a>
				<div className="nav-open" onClick={() => setExpanded(!expanded)}><i className="fa fa-bars"></i></div>
				<div className={"nav-options" + (expanded ? " expanded" : "")}>
					<a href="https://www.instagram.com/symptomshark/"><i className="fa fa-instagram"></i></a>
					<a className={props.selectedPage == "Home" ? "selected" : ""} href="index.html">Home</a>
					<a className={props.selectedPage == "Story" ? "selected" : ""} href="story.html">Story</a>
					<a className={props.selectedPage == "ME/CFS" ? "selected" : ""} href="me-cfs-long-covid.html">ME/CFS &amp; Long COVID</a>
					<a className={props.selectedPage == "FAQs" ? "selected" : ""} href="faqs.html">FAQs</a>
					<a href="https://mydatahelps.org/identityserver/local/participant?projectCode=SHARKS#/login">Login</a>
				</div>
			</div>
		</div>
	);
}