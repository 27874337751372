let strings: { [key: string]: string } = {
    "back": "Terug",
    "close": "Sluiten",
    "done": "Klaar",
    "add": "Toevoegen",
    "options": "Opties",
    "symptoms": "Symptomen",
    "next": "Volgende",
    "treatments": "Behandelingen",
    "symptoms-experiencing-today": "Welke symptomen ervaar je vandaag?",
    "symptoms-experiencing-previous": "Welke symptomen ervaarde je eerder?",
    "treatments-experiencing-today": "Welke behandelingen heb je vandaag uitgevoerd?",
    "treatments-experiencing-previous": "Welke behandelingen voerde je eerder uit?",
    "feeling-overall-today": "Hoe voel je je vandaag in het algemeen?",
    "feeling-overall-previous": "Hoe voelde je je eerder in het algemeen?",
    "additional-notes": "Eventuele aanvullende opmerkingen?",
    "how-severe-is": "Hoe ernstig is je",
    "how-severe-was": "Hoe ernstig was je",
    "clear-symptom": "Symptoom wissen",
    "apple-health-intro": "Volg je symptomen in relatie tot je gegevens van Apple Health. Begin door je Apple Health-gegevens te koppelen met MyDataHelps.",
    "apple-health-connect-button": "Verbind met Apple Health",
    "apple-health-syncing": "De Apple Health-gegevens die je hebt gekozen om te delen, worden nu gesynchroniseerd met Symptom Shark. Keer over een paar minuten terug naar dit scherm om je gegevens te bekijken.",
    "apple-health-troubleshoot-intro": "Als je niet hebt goedgekeurd of het delen van je Apple Health-gegevens met Symptom Shark hebt uitgeschakeld en dit wilt inschakelen, volg dan deze stappen:",
    "apple-health-troubleshoot-1": "Open de app \"Instellingen\"",
    "apple-health-troubleshoot-2": "Selecteer \"Privacy\"",
    "apple-health-troubleshoot-3": "Selecteer \"Gezondheid\"",
    "apple-health-troubleshoot-4": "Selecteer \"MyDataHelps\"",
    "apple-health-troubleshoot-5": "Schakel de categorieën gegevens in die je wilt delen met Symptom Shark",
    "fitbit-intro": "Als je een Fitbit hebt, kun je deze koppelen om te zien hoe je activiteit, slaap en hartslaggegevens je symptomen en welzijn beïnvloeden.",
    "fitbit-connect-button": "Verbind met Fitbit",
    "fitbit-connecting": "Verbinden...",
    "mild": "Mild",
    "moderate": "Matig",
    "severe": "Ernstig",
    "question-for-doctor": "Vraag voor de dokter",
    "question": "Vraag",
    "question-placeholder": "Bijv., Hoeveel paracetamol kan ik per dag nemen?",
    "answer": "Antwoord",
    "answer-placeholder": "Bijv., 3000mg of 6 extra sterke pillen",
    "add-question": "Voeg een vraag toe voor mijn dokter",
    "add-question-subtitle": "Houd een overzicht bij van de vragen die je wilt stellen aan je dokter tijdens je volgende bezoek.",
    "unanswered": "Onbeantwoord",
    "all": "Alle",
    "search-questions": "Zoek Vragen",
    "choose-report-month": "Kies rapportmaand",
    "include-symptoms": "Inclusief symptomen",
    "select-symptoms": "Selecteer symptomen",
    "include-treatments": "Inclusief behandelingen",
    "select-treatments": "Selecteer behandelingen",
    "include-overall-experience": "Inclusief dagelijkse algehele ervaring",
    "include-notes": "Inclusief notities",
    "create-report": "Maak PDF-rapport",
    "item-delete-warning": "Waarschuwing: Door te gaan worden de onderstaande items en alle bijbehorende gegevens permanent uit je logboeken verwijderd. Als je deze items niet wilt verwijderen, selecteer dan \"Annuleren\".",
    "unsaved-changes": "Niet-opgeslagen wijzigingen",
    "not-enough-data": "Niet genoeg gegevens",
    "below-personal-range": "Onder persoonlijke range",
    "above-personal-range": "Boven persoonlijke range",
    "highlight-out-of-range": "Markeer waarden buiten bereik",
    "range-explanation": "Waarden buiten bereik worden bepaald met behulp van het 30-daagse gemiddelde voorafgaand aan de waarde.",
    "in-personal-range": "Binnen persoonlijke range",
    "log-entry": "Logboekvermelding",
    "tap-to-log-today": "Tik hier om je symptomen of behandelingen te loggen!",
    "select-data-types": "Selecteer gegevenssoorten",
    "connect-fitbit-prompt": "Verbind Fitbit",
    "connect-garmin-prompt": "Verbind Garmin",
    "today": "Vandaag",
    "yesterday": "Gisteren",
    "severe-shortened": "ernst", //Does this need to be shorter? ChatGPT suggests "ern".
    "moderate-shortened": "matig",
    "mild-shortened": "mild",
    "add-notes": "Voeg notities toe",
    "notes": "Notities",
    "daily-overall-experience": "Dagelijkse Algehele Ervaring",
    "average": "Gemiddelde",
    "enter-symptom-name": "Voer symptoomnaam in",
    "enter-treatment-name": "Voer behandelingsnaam in",
    "severity-tracking-none": "Ernst niet bijhouden",
    "severity-tracking-3point": "Milde / Matige / Ernstige beoordeling",
    "severity-tracking-10point": "1 - 10 Punten Beoordeling",
    "muted": "Gedempt",
    "not-muted": "Niet gedempt",
    "delete": "Verwijderen",
    "severity": "Ernst",
    "sync-in-progress": "Synchronisatie bezig. Keer over enkele ogenblikken terug om de nieuwste gegevens te bekijken.",
    "symptom-setup-intro": "Welke symptomen wil je bijhouden?",
    "symptom-setup-description": "Voer een lijst in van symptomen die je over tijd wilt bijhouden. Maak je geen zorgen over het onthouden van alle symptomen - je kunt altijd later meer toevoegen!",
    "symptom-setup-next": "Volgende: Behandelingen",
    "symptom-setup-placeholder": "Symptoomnaam, bijv., Hoofdpijn",
    "symptom-edit-description": "Selecteer 'Opties' om symptoomkleur te wijzigen, ernst van het symptoom bij te houden of een symptoom te dempen.",
    "symptom-edit-next": "Symptomen opslaan",
    "treatment-setup-intro": "Welke behandelingen wil je bijhouden?",
    "treatment-setup-description": "Behandelingen kunnen alles zijn wat je doet om je symptomen te verminderen. Voor de beste resultaten, focus op 'zo nodig' behandelingen en niet op dingen die je altijd elke dag doet (zoals dagelijkse medicatie).",
    "treatment-setup-next": "Volgende: Herinneringen",
    "treatment-setup-placeholder": "Behandelingsnaam, bijv., Paracetamol",
    "treatment-edit-description": "Selecteer \"Opties\" om de behandelingskleur te wijzigen of een behandeling te dempen.",
    "treatment-edit-next": "Behandelingen opslaan",
    "select-some-data-types": "Selecteer een of meer gegevenssoorten hierboven.",
    "pacing-notifications-intro-ios": "Heb je een draagbaar apparaat, zoals een Fitbit, Apple Watch of Garmin?",
    "pacing-notifications-intro": "Heb je een Fitbit?",
    "long-covid-can-cause": "Langdurige COVID en ME/CVS kunnen ",
    "post-exertional-malaise": "Post-exertionele malaise (PEM) veroorzaken",
    "pacing-notifications-intro-1": "Als je PEM ervaart, of gewoon jezelf wilt doseren, kan Symptom Shark je <strong>doseermeldingen</strong> sturen.",
    "pacing-notifications-intro-2": "Doseermeldingen kunnen worden verzonden wanneer je specifieke activiteit- of hartslagniveaus overschrijdt. Wanneer je een melding ontvangt, kun je beslissen of je jezelf wilt doseren.",
    "pacing-notifications-get-started-ios": "Om te beginnen met doseermeldingen, verbind je met Fitbit of Apple Health.",
    "pacing-notifications-get-started": "Om te beginnen met doseermeldingen, verbind je met Fitbit.",
    "pacing-notifications-troubleshooting": " <strong>Meldingen kunnen worden vertraagd</strong> als je tracker of smartwatch onlangs niet is gesynchroniseerd.",
    "learn-more": "Meer informatie",
    "pacing-notification-steps": "Krijg een melding wanneer je dagelijkse stappen in de buurt of boven je recente gemiddelde liggen.",
    "pacing-notification-heart-rate": "Krijg een melding wanneer recente hartslagen boven een bepaald niveau waren. Hartslagen synchroniseren alleen met Symptom Shark wanneer je telefoon is ontgrendeld.",
    "pacing-notification-elevated-heart-rate": "Krijg een melding wanneer Fitbit-hartslagzone minuten toenemen.",
	"pacing-notification-calories": "Krijg een melding wanneer de verbrande calorieën in de buurt van of boven je recente gemiddelde liggen. Verbrande calorieën worden berekend op basis van onder andere activiteit- en hartslaggegevens.",
	"send-via-sms": "Verstuur via SMS?",
	"send-via-sms-no-phone": "Stel een telefoonnummer in onder \"Account\" om doseermeldingen via SMS te ontvangen",
	"send-via-sms-with-phone": "Krijg doseermeldingen via SMS-tekst naar",
	"message-rates-apply": "Bericht- en gegevenskosten kunnen van toepassing zijn.",
	"send-via-push": "Verstuur via Push?",
	"send-via-push-description": "Krijg doseermeldingen via pushmelding naar je telefoon.",
	"notify-when-above": "Meld wanneer boven:",
	"beats-per-minute": "slagen per minuut",
	"steps": "Stappen",
	"heart-rate": "Hartslag",
	"elevated-heart-rate": "Verhoogde hartslag",
	"calories-burned": "Verbrande calorieën",
	"sleep-time": "Slaaptijd",
	"rem-sleep-time": "REM-slaaptijd",
	"light-sleep-time": "Lichte slaaptijd",
	"deep-sleep-time": "Diepe slaaptijd",
	"in-bed-time": "In bed tijd",
	"flights-climbed": "Beklommen verdiepingen",
	"activity-time": "Activiteitstijd",
	"lightly-active-time": "Licht actieve tijd",
	"fairly-active-time": "Matig actieve tijd",
	"very-active-time": "Zeer actieve tijd",
	"floors-climbed": "Beklommen verdiepingen",
	"stand-time": "Sta-tijd",
	"max-heart-rate": "Maximale hartslag",
	"elevated-heart-rate-time": "Tijd met verhoogde hartslag",
	"fat-burn-time": "Vetverbrandingstijd",
	"cardio-time": "Cardiotijd",
	"peak-time": "Piektijd",
	"resting-heart-rate": "Rusthartslag",
	"heart-rate-variability": "Hartslagvariabiliteit",
	"walking-heart-rate": "Wandelhartslag",
	"breathing-rate": "Ademhalingssnelheid",
	"stress-time": "Stresstijd",
	"low-stress-time": "Lage stresstijd",
	"medium-stress-time": "Gemiddelde stresstijd",
	"high-stress-time": "Hoge stresstijd",
	"max-stress-level": "Maximaal stressniveau",
	"sleep-score": "Slaapscores",
	"adjust-notifications-later": "Je kunt je doseermeldingen later altijd aanpassen onder \"Instellingen\"",
	"reports": "Rapporten",
	"edit-symptoms": "Symptomen bewerken",
	"edit-treatments": "Behandelingen bewerken",
	"active-symptoms": "Actieve symptomen",
	"active-treatments": "Actieve behandelingen",
	"edit-reminders": "Herinneringen bewerken",
	"no-reminders": "Geen herinneringen",
	"pacing-notifications": "Doseermeldingen",
	"disabled": "Uitgeschakeld",
	"account": "Account",
	"account-description": "E-mail/telefoon en andere instellingen bijwerken",
	"faqs": "Veelgestelde vragen",
	"faqs-description": "Bekijk veelgestelde vragen",
	"provide-feedback": "Geef feedback",
	"provide-feedback-description": "Deel opmerkingen, feedback of functiesuggesties",
	"loading": "Laden…",
	"settings": "Instellingen",
	"entries": "Inzendingen",
	"dr-q-a": "Dr. Q&A"
};

export default strings;