import React, { useEffect, useState } from "react";
import { Button, CardTitle, LoadingIndicator } from "@careevolution/mydatahelps-ui";
import "./ConnectAppleHealth.css"
import MyDataHelps from "@careevolution/mydatahelps-js"
import language from "../../../helpers/language";

export interface ConnectAppleHealthProps {
	appleHealthConnectionSurvey: string;
}

export default function (props: ConnectAppleHealthProps) {
	const [appleHealthEnabled, setAppleHealthEnabled] = useState<Boolean | null>(null);
	function initialize() {
		MyDataHelps.getParticipantInfo().then(function (info) {
			if (info.customFields["AppleHealthEnabled"] == "Yes") {
				setAppleHealthEnabled(true);
			} else {
				setAppleHealthEnabled(false);
			}
		})
	}

	useEffect(() => {
		initialize();
		MyDataHelps.on("applicationDidBecomeVisible", initialize);
		MyDataHelps.on("externalAccountSyncComplete", initialize);
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", initialize);
			MyDataHelps.off("externalAccountSyncComplete", initialize);
		}
	}, []);

	
	if (appleHealthEnabled == null) { return null; }
	if (appleHealthEnabled) {
		return <div className="connect-apple-health">
			<CardTitle title="Connecting to Apple Health..." />
			<LoadingIndicator />
			<div className="content">
				<div className="subtitle" style={{ marginBottom: "16px" }}>
					{language["apple-health-syncing"]}
				</div>
				<div className="subtitle" style={{ marginBottom: "16px" }}>
					{language["apple-health-troubleshoot-intro"]}
				</div>
				<ol>
					<li>{language["apple-health-troubleshoot-1"]}</li>
					<li>{language["apple-health-troubleshoot-2"]}</li>
					<li>{language["apple-health-troubleshoot-3"]}</li>
					<li>{language["apple-health-troubleshoot-4"]}</li>
					<li>{language["apple-health-troubleshoot-5"]}</li>
				</ol>
			</div>
		</div>
	} else {
		return <div className="connect-apple-health">
			<CardTitle title="Connect to Apple Health" />
			<div className="content">
				<div className="subtitle">
					{language["apple-health-intro"]}
				</div>
				<Button onClick={() => MyDataHelps.startSurvey(props.appleHealthConnectionSurvey)}>{language["apple-health-connect-button"]}</Button>
			</div>
		</div>
	}

}