import React from 'react';
import "./PacingNotificationsPage.css"
import Nav from '../Nav/Nav';
import WebPage from '../WebPage/WebPage';
import Wave from '../Wave/Wave';

export default function () {
	return (
		<WebPage className="pacing-notifications-page">
			<Nav selectedPage="ME/CFS" />
			<div className="page-header pacing-page-header">
				<img className="header-spice" src='/images/hero-spice.png' />
				<Wave reverse={true} reverseX={true} />
			</div>
			<div className="page-content">
				<h2>
					Pacing Notifications for ME/CFS and Long COVID
				</h2>
				<p>
					We know how important pacing is for people with ME/CFS and Long COVID.  A hallmark symptom of these conditions is <a href="https://www.cdc.gov/me-cfs/healthcare-providers/clinical-care-patients-mecfs/treating-most-disruptive-symptoms.html#anchor_1528128659388" target="_blank">"Post-Exertional Malaise" (PEM)</a>.
				</p>
				<p style={{ textAlign: "center" }}>
					<img src="images/notif_hr.png" style={{ width: "350px" }} />
					<img src="images/notif_steps.png" style={{ width: "350px" }} />
				</p>
				<p>
					Using a wearable device (such as a Fitbit, Apple Watch, or Garmin) can help with pacing.  Wearables can track steps, heart rate, and more.
				</p>
				<p>
					Symptom Shark connects to your wearable data and can send you <strong>Pacing Notifications</strong>.
				</p>
				<p>
					You can connect your Fitbit or Apple Health enabled device and get updates on your activity levels. When you receive a notification, you can decide if you need to pace yourself.
				</p>
				<p>
					You can choose what pacing notifications you want to enable under settings:
				</p>
				<p style={{ textAlign: "center" }}>
					<img className="tutorial-image" src="images/settings_screen.png" />
					<img className="tutorial-image" src="images/pacing_screen.png" />
				</p>
				<p>
					<strong>Your feedback is especially important</strong>!  We will continue to refine Pacing Notifications and Symptom Shark based on community feedback.  Submit your feedback in the Symptom Shark app, or email <a href="mailto:symptomshark@careevolution.com">symptomshark@careevolution.com</a>.
				</p>
				<p>
					<a href="faqs.html#pacing">Frequently Asked Questions about Pacing Notifications</a>
				</p>
				<h3>More Information / Resources</h3>
				<p>
					<a href="https://www.meaction.net/wp-content/uploads/2020/10/Pacing-and-Management-Guide-for-ME_CFS-8.pdf" target="_blank">Pacing and Management Guide for ME/CFS</a>
				</p>
				<p>
					<a href="https://solvecfs.org/using-a-heart-rate-monitor-to-prevent-post-exertional-malaise-in-me-cfs/" target="_blank">Using a Heart Rate Monitor to Prevent Post-Exertional Malaise in ME/CFS</a>
				</p>
				<p>
					<a href="https://workwellfoundation.org/wp-content/uploads/2021/03/HRM-Factsheet.pdf" target="_blank">ME/CFS Activity Management with a Heart Rate Monitor</a>
				</p>
			</div>
		</WebPage >
	);
}