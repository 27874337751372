import React, { useEffect } from 'react';
import { Layout } from '@careevolution/mydatahelps-ui';
import { parseISO } from 'date-fns';
import getDayKey from '../../helpers/get-day-key';
import { LogEntryEdit } from '../../components';

export interface DayViewProps {
	colorScheme?: "light" | "dark" | "auto";
	viewName?: string;
	onVisit?(day: string): void;
}

export default function (props: DayViewProps) {
	var date = new Date();
	var search = new URLSearchParams(window.location.search);
	if (search.get("date")) {
		date = parseISO(search.get("date")!)
	}

	useEffect(() => {
		props.onVisit?.(getDayKey(date));
	}, []);

	return (
		<Layout className="symptom-shark-view" bodyBackgroundColor="var(--mdhui-background-color-0)" colorScheme={props.colorScheme || "light"}>
			<LogEntryEdit date={date} promptForReviewAfterDays={7} />
		</Layout>
	);
}