import React, { useEffect, useState } from "react";
import symptomSharkData, { QuestionForDoctor } from "../../../services/typed_data";
import { Action, Card, LoadingIndicator, SegmentedControl, ShinyOverlay } from "@careevolution/mydatahelps-ui";
import { parseISO } from "date-fns";
import MyDataHelps from "@careevolution/mydatahelps-js";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { getDayOfWeek, getFullDateString } from "../../../helpers/date-helpers";
import language from "../../../helpers/language";
import Question from "../../Presentational/Question/Question";
import "./QuestionList.css";

export interface QuestionListProps {
	onQuestionSelected(questionDateString: string): void;
}

export default function (props: QuestionListProps) {
	const [questions, setQuestions] = useState<QuestionForDoctor[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [filter, setFilter] = useState<string>('all');
	const [search, setSearch] = useState<string>("");

	function initialize() {
		symptomSharkData.getQuestionsForDoctor().then(function (q) {
			setQuestions(q);
			setLoading(false);
		})
	}

	useEffect(() => {
		initialize();
		MyDataHelps.on("applicationDidBecomeVisible", initialize);
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", initialize);
		}
	}, []);

	function filterQuestion(q: QuestionForDoctor) {
		if (!q.question) {
			return false;
		}

		if (filter == 'unanswered' && q.answer) {
			return false;
		}

		if (search && (!q.answer?.toLowerCase()?.includes(search.toLowerCase()) && !q.question.toLowerCase().includes(search.toLowerCase()))) {
			return false;
		}

		return true;
	}

	function formatDate(dateString: string) {
		var date = parseISO(dateString);
		return getDayOfWeek(date) + ", " + getFullDateString(date);
	}

	var filteredQuestions = questions.filter(filterQuestion);
	var addQuestion = function () {
		props.onQuestionSelected("");
	}

	return <div className="question-list">
		{loading &&
			<LoadingIndicator />
		}
		{!loading &&
			<>
				<Card className="add-question">
					<Action onClick={() => addQuestion()}
						title={language["add-question"]}
						subtitle={language["add-question-subtitle"]}
						indicatorIcon={faPlusCircle} />
					<ShinyOverlay />
				</Card>
				{questions.length > 0 &&
					<>
						<div className="filter">
							<SegmentedControl color="var(--ss-secondary-theme-color)" selectedSegment={filter} segments={[{ key: "all", title: language["all"] }, { key: "unanswered", title: language["unanswered"] }]} onSegmentSelected={(s: string) => setFilter(s)} />
						</div>
						<div className="search">
							<input onChange={(e) => setSearch(e.target.value)} value={search} type="search" className="search-box" placeholder={language["search-questions"]} />
							<i className="fa fa-search"></i>
						</div>
					</>
				}
				{filteredQuestions.map((q, index) =>
					<div key={q.date}>
						{(index == 0 || formatDate(q.date) != formatDate(filteredQuestions[index - 1].date)) &&
							<div className="question-date-header">
								{formatDate(q.date)}
							</div>
						}
						<Question question={q} onQuestionSelected={(q) => props.onQuestionSelected(q)} />
					</div>
				)}
			</>
		}
	</div>;
}