import React from "react";
import { Card, ShinyOverlay, UnstyledButton } from "@careevolution/mydatahelps-ui";
import { DailyLogEntry, SymptomSharkParticipantInfo } from "../../../services/typed_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import LogEntryContent from "../LogEntryContent/LogEntryContent";
import "./DeviceActivityLogEntry.css"
import language from "../../../helpers/language";

interface DeviceActivityLogEntry {
	date: Date;
	logEntry?: DailyLogEntry;
	participantInfo: SymptomSharkParticipantInfo;
	highlightedSymptoms: string[];
	highlightedTreatments: string[];
	onDaySelected(d: Date): void;
}

export default function (props: DeviceActivityLogEntry) {
	function startEditing() {
		props.onDaySelected(props.date);
	}

	if (!props.logEntry || !(props.logEntry.symptoms.length > 0 || props.logEntry.treatments.length > 0 || (props.logEntry && props.logEntry.notes.length > 0))) {
		return <Card className="device-activity-log-entry" >
			<UnstyledButton style={{ width: "100%" }} className="day-header no-log-entry today-highlight" onClick={() => startEditing()}>
				<div style={{ paddingTop: 0 }} className="message"> {language["tap-to-log-today"]}</div>
				<div className="day-options">
					<i className="fa fa-plus-circle"></i>
				</div>
				<ShinyOverlay />
			</UnstyledButton>
		</Card>;
	}

	return <Card className="device-activity-log-entry">
		<UnstyledButton style={{ width: "100%" }} onClick={() => startEditing()}>
			<div className="card-title">{language["log-entry"]} <FontAwesomeIcon icon={faEdit} /> </div>
			<LogEntryContent highlightedSymptoms={props.highlightedSymptoms}
				highlightedTreatments={props.highlightedTreatments}
				participantInfo={props.participantInfo}
				logEntry={props.logEntry} />
		</UnstyledButton>
	</Card>;

}