let strings: { [key: string]: string } = {
	"back": "Atrás",
	"close": "Cerrar",
	"done": "Aceptar",
	"add": "Agregar",
	"options": "Opciones",
	"symptoms": "Síntomas",
	"next": "Siguiente",
	"treatments": "Tratamientos",
	"symptoms-experiencing-today": "¿Qué síntomas está experimentando?",
	"symptoms-experiencing-previous": "¿Qué síntomas estaba experimentando?",
	"treatments-experiencing-today": "¿Qué tratamientos ha hecho?",
	"treatments-experiencing-previous": "¿Qué tratamientos hizo?",
	"feeling-overall-today": "¿Cómo se siente en general?",
	"feeling-overall-previous": "¿Cómo se sentía en general?",
	"additional-notes": "¿Alguna nota adicional?",
	"how-severe-is": "¿Cuál es la gravedad de su",
	"how-severe-was": "¿Cuál era la gravedad de su",
	"clear-symptom": "Eliminar síntomas",
	"apple-health-intro": "Track your symptoms against your data from Apple Health.  Get started by connecting to your Apple Health data with MyDataHelps.",
	"apple-health-connect-button": "Connect to Apple Health",
	"apple-health-syncing": "The Apple Health data you have selected to share is now syncing with Symptom Shark.  Return to this screen in a few minutes to view your data.",
	"apple-health-troubleshoot-intro": "If you did not approve or have disabled sharing of your Apple Health data with Symptom Shark and would like to enable it, follow these steps:",
	"apple-health-troubleshoot-1": "Open the \"Settings\" app",
	"apple-health-troubleshoot-2": "Select \"Privacy\"",
	"apple-health-troubleshoot-3": "Select \"Health\"",
	"apple-health-troubleshoot-4": "Select \"MyDataHelps\"",
	"apple-health-troubleshoot-5": "Enable the categories of data you would like to share with Symptom Shark",
	"fitbit-intro": "If you have a Fitbit, you can connect it to see how your activity, sleep, and heart rate data impacts your symptoms and wellbeing.",
	"fitbit-connect-button": "Connect to Fitbit",
	"fitbit-connecting": "Connecting...",
	"mild": "Leve",
	"moderate": "Moderado",
	"severe": "Grave",
	"question-for-doctor": "Question for Doctor",
	"question": "Question",
	"question-placeholder": "E.g. How much tylenol can I take each day?",
	"answer": "Answer",
	"answer-placeholder": "E.g. 3000mg or 6 extra strength pills",
	"add-question": "Add a Question for My Doctor",
	"add-question-subtitle": "Keep a record of the questions you want to remember to ask your doctor during your next visit.",
	"unanswered": "Unanswered",
	"all": "All",
	"search-questions": "Search Questions",
	"choose-report-month": "Elegir el mes del informe",
	"include-symptoms": "Incluir síntomas",
	"select-symptoms": "Seleccionar síntomas",
	"include-treatments": "Incluir tratamientos",
	"select-treatments": "Seleccionar tratamientos",
	"include-overall-experience": "Incluir la experiencia general diaria",
	"include-notes": "Incluir notas",
	"create-report": "Crear informe",
	"item-delete-warning": "Advertencia: Si continúa, se eliminarán de forma permanente los siguientes elementos y todos los datos asociados de sus registros. Si no desea eliminar estos elementos, seleccione \"Cancelar\".",
	"unsaved-changes": "Cambios no guardados",
	"not-enough-data": "Not enough data",
	"below-personal-range": "Below personal range",
	"above-personal-range": "Above personal range",
	"highlight-out-of-range": "Highlight Out of Range Values",
	"range-explanation": "Out of range values are determined using the 30-day average previous to the value.",
	"in-personal-range": "In personal range",
	"log-entry": "Entrada de registro",
	"tap-to-log-today": "Pulse aquí para registrar sus síntomas o tratamientos.",
	"select-data-types": "Select Data Types",
	"connect-fitbit-prompt": "Connect Fitbit",
	"connect-garmin-prompt": "Connect Garmin",
	"today": "Hoy",
	"yesterday": "Ayer",
	"severe-shortened": "graves",
	"moderate-shortened": "mod",
	"mild-shortened": "leves",
	"add-notes": "Agregar notas",
	"notes": "Notas",
	"daily-overall-experience": "Experiencia general diaria",
	"average": "Promedio",
	"enter-symptom-name": "Ingresar el nombre del síntoma",
	"enter-treatment-name": "Ingresar el nombre del tratamiento",
	"severity-tracking-none": "No hacer un seguimiento de la gravedad",
	"severity-tracking-3point": "Calificación leve/moderado/grave",
	"severity-tracking-10point": "Calificación de 1 a 10 puntos",
	"muted": "Silenciado",
	"not-muted": "No silenciado",
	"delete": "Eliminar",
	"severity": "Gravedad",
	"sync-in-progress": "Sync in progress.  Check back in a few moments to view latest data.",
	"symptom-setup-intro": "What symptoms do you want to track?",
	"symptom-setup-description": "Enter a list of symptoms you want to track over time.  Don't worry about remembering all of them - you can always add more later!",
	"symptom-setup-next": "Next: Treatments",
	"symptom-setup-placeholder": "Symptom name, e.g. Headache",
	"symptom-edit-description": "Select 'Options' to change symptom color, enable tracking symptom severity or to mute a symptom.",
	"symptom-edit-next": "Save Symptoms",
	"treatment-setup-intro": "What treatments do you want to track?",
	"treatment-setup-description": "Treatments can be anything you do to help reduce your symptoms.  For best results, focus on \"as needed\" treatments and not things that you always do each day(like daily medications).",
	"treatment-setup-next": "Next: Reminders",
	"treatment-setup-placeholder": "Treatment name, e.g. Tylenol",
	"treatment-edit-description": "Select \"Options\" to change treatment color or to mute a treatment.",
	"treatment-edit-next": "Save Treatments",
	"select-some-data-types": "Select one or more data types above.",
	"pacing-notifications-intro-ios": "Do you have a wearable device, such as a Fitbit, Apple Watch, or Garmin?",
	"pacing-notifications-intro": "Do you have a Fitbit?",
	"long-covid-can-cause": "Long COVID and ME/CFS can cause ",
	"post-exertional-malaise": "Malestar posesfuerzo (PEM)",
	"pacing-notifications-intro-1": "If you experience PEM, or just want to pace yourself, Symptom Shark can send you <strong>pacing notifications</strong>.",
	"pacing-notifications-intro-2": "Pacing notifications can be sent when you go over specific activity or heart rate levels.  When you receive a notification, you can decide if you want to pace yourself.",
	"pacing-notifications-get-started-ios": "To get started with pacing notifications, connect to Fitbit or Apple Health.",
	"pacing-notifications-get-started": "To get started with pacing notifications, connect to Fitbit.",
	"pacing-notifications-troubleshooting": " <strong>Notifications may be delayed</strong> if your tracker or smartwatch has not synced recently. ",
	"learn-more": "Learn More",
	"pacing-notification-steps": "Get notified when daily steps are near or above your recent average.",
	"pacing-notification-heart-rate": "Get notified when recent heart rates were above a specified level.Heart rates only sync to Symptom Shark when your phone is unlocked.",
	"pacing-notification-elevated-heart-rate": "Get notified when Fitbit heart rate zone minutes go up.",
	"pacing-notification-calories": "Get notified when calories burned are near or above your recent average.  Calories burned are calculated from, among other things, activity and heart rate data.",
	"send-via-sms": "Send via SMS?",
	"send-via-sms-no-phone": "Set up a phone number under \"Account\" to receive pacing notifications via SMS",
	"send-via-sms-with-phone": "Get pacing notifications sent via SMS text to ",
	"message-rates-apply": "Message and data rates may apply.",
	"send-via-push": "Send via Push?",
	"send-via-push-description": "Get pacing notifications via push notification to your phone.",
	"notify-when-above": "Notify when above:",
	"beats-per-minute": "beats per minute",
	"steps": "Steps",
	"heart-rate": "Heart Rate",
	"elevated-heart-rate": "Elevated Heart Rate",
	"calories-burned": "Calories Burned",
	"sleep-time": "Sleep Time",
	"rem-sleep-time": "REM Sleep Time",
	"light-sleep-time": "Light Sleep Time",
	"deep-sleep-time": "Deep Sleep Time",
	"in-bed-time": "In Bed Time",
	"flights-climbed": "Flights Climbed",
	"activity-time": "Activity Time",
	"lightly-active-time": "Lightly Active Time",
	"fairly-active-time": "Fairly Active Time",
	"very-active-time": "Very Active Time",
	"floors-climbed": "Floors Climbed",
	"stand-time": "Stand Time",
	"max-heart-rate": "Max Heart Rate",
	"elevated-heart-rate-time": "Elevated Heart Rate Time",
	"fat-burn-time": "Fat Burn Time",
	"cardio-time": "Cardio Time",
	"peak-time": "Peak Time",
	"resting-heart-rate": "Resting Heart Rate",
	"heart-rate-variability": "Heart Rate Variability",
	"walking-heart-rate": "Walking Heart Rate",
	"breathing-rate": "Breathing Rate",
	"stress-time": "Stress Time",
	"low-stress-time": "Low Stress Time",
	"medium-stress-time": "Medium Stress Time",
	"high-stress-time": "High Stress Time",
	"max-stress-level": "Max Stress Level",
	"sleep-score": "Sleep Score",
	"adjust-notifications-later": "You can always adjust your pacing notifications later under \"Settings\"",
	"reports": "Informes",
	"edit-symptoms": "Editar síntomas",
	"edit-treatments": "Editar tratamientos",
	"active-symptoms": "Síntomas activos",
	"active-treatments": "Tratamientos activos",
	"edit-reminders": "Editar recordatorios",
	"no-reminders": "No hay recordatorios",
	"pacing-notifications": "Pacing Notifications",
	"disabled": "Disabled",
	"account": "Account",
	"account-description": "Update email / phone and other settings",
	"faqs": "Frequently Asked Questions",
	"faqs-description": "View frequently asked questions",
	"provide-feedback": "Provide Feedback",
	"provide-feedback-description": "Share comments, feedback, or feature suggestions",
	"loading": "Cargando...",
	"settings": "Configuración",
	"entries": "Entradas",
	"dr-q-a": "Dr. Q&A"
};

export default strings;