import React from "react";
import { TrackerItem } from "@careevolution/mydatahelps-ui";
import "./LogEntryContent.css"
import { DailyLogEntry, SymptomSharkParticipantInfo } from "../../../services/typed_data";
import language from "../../../helpers/language";

interface LogEntryContentProps {
	logEntry?: DailyLogEntry;
	participantInfo: SymptomSharkParticipantInfo;
	highlightedSymptoms?: string[];
	highlightedTreatments?: string[];
	include?: ("symptoms" | "treatments")[];
}

interface DailySymptom {
	id: string,
	name: string,
	severity: string | undefined,
	color: string
}

interface DailyTreatment {
	id: string,
	name: string,
	color: string
}

export default function (props: LogEntryContentProps) {
	var symptoms: DailySymptom[] = [];
	var treatments: DailyTreatment[] = [];

	if (props.logEntry) {
		if (!props.include || props.include.includes("symptoms")) {
			props.logEntry.symptoms.forEach((s) => {
				var symptomDefinition = props.participantInfo.symptoms.find(s2 => s2.id == s.id);
				if (!symptomDefinition) { return; }

				var severity;
				if (symptomDefinition.severityTracking == "10PointScale") {
					severity = s.severity ? s.severity.toString() : undefined;
				}
				if (symptomDefinition.severityTracking == "3PointScale") {
					if (s.severity! > 7) { severity = language["severe-shortened"]; }
					else if (s.severity! > 4) { severity = language["moderate-shortened"]; }
					else if (s.severity! > 0) { severity = language["mild-shortened"]; }
				}
				symptoms.push({
					id: s.id,
					severity: severity,
					name: symptomDefinition.name,
					color: symptomDefinition.color
				});
			});
			symptoms.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
		}
		if (!props.include || props.include.includes("treatments")) {
			props.logEntry.treatments.forEach((s) => {
				var treatmentDefinition = props.participantInfo.treatments.find(s2 => s2.id == s.id);
				if (!treatmentDefinition) { return; }
				treatments.push({
					id: s.id,
					name: treatmentDefinition.name,
					color: treatmentDefinition.color
				})
			});
			treatments.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
		}
	}

	if (!props.logEntry || !(symptoms.length > 0 || treatments.length > 0 || props.logEntry.notes.length > 0)) {
		return null;
	}

	var highlightedSymptoms: string[] = [];
	var highlightedTreatments: string[] = [];
	if (props.highlightedSymptoms) {
		highlightedSymptoms = props.highlightedSymptoms;
	}
	if (props.highlightedTreatments) {
		highlightedTreatments = props.highlightedTreatments;
	}

	return <div className="log-entry-content">
		{(props.logEntry.symptoms.length > 0 || props.logEntry.treatments.length > 0) &&
			<div className="items-group">
				<div className="items" style={{ paddingBottom: 0 }}>
					{symptoms.filter((s) => highlightedSymptoms.indexOf(s.id) != -1).map((s) =>
						<TrackerItem className="item" selected={true} color={s.color} badge={s.severity} key={s.id} text={s.name} />
					)}
					{treatments.filter((s) => highlightedTreatments.indexOf(s.id) != -1).map((s) =>
						<TrackerItem className="item" selected={true} color={s.color} key={s.id} text={s.name} bordered={true} />
					)}
					{symptoms.filter((s) => highlightedSymptoms.indexOf(s.id) == -1).map((s) =>
						<span key={s.id} style={{ opacity: highlightedSymptoms.length > 0 || highlightedTreatments.length > 0 ? .5 : 1 }}>
							<TrackerItem className="item" selected={true} color={s.color} badge={s.severity} text={s.name} />
						</span>
					)}
					{treatments.filter((s) => highlightedTreatments.indexOf(s.id) == -1).map((s) =>
						<span key={s.id} style={{ opacity: highlightedSymptoms.length > 0 || highlightedTreatments.length > 0 ? .5 : 1 }}>
							<TrackerItem className="item" selected={true} color={s.color} text={s.name} bordered={true} />
						</span>
					)}
				</div>
				<div style={{ clear: "both" }}></div>
			</div>
		}
		{props.logEntry.notes &&
			<div className={"notes" + (!(symptoms.length > 0 || treatments.length > 0) ? " no-border" : "")}>{props.logEntry.notes}</div>
		}
	</div>;

}