import React, { useEffect, useState } from 'react'
import { Calendar, DateRangeNavigator, Layout, LoadingIndicator, NavigationBar, Section, StatusBarBackground } from "@careevolution/mydatahelps-ui"
import MyDataHelps from "@careevolution/mydatahelps-js"
import symptomSharkData, { DailyLogEntry, SymptomSharkParticipantInfo } from '../../services/typed_data';
import { add } from 'date-fns';
import getDayKey from '../../helpers/get-day-key';
import "./CalendarView.css"
import { SymptomTreatmentFilters, SymptomTreatmentHistograms, OverallExperienceChart, SymptomSeverityChart, CalendarDay } from '../../components';
import "./CalendarView.css"

export interface CalendarProps {
	colorScheme?: "light" | "dark" | "auto";
	onVisit?(): void;
	onDaySelected(d: Date): void;
	onSymptomSelected(symptom: string, year: number, month: number): void;
}

export default function (props: CalendarProps) {
	var currentDate = new Date();
	currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0);
	var initialIntervalStart = currentDate;
	while (initialIntervalStart.getDate() != 1) {
		initialIntervalStart = add(initialIntervalStart, { days: -1 });
	}
	const [loading, setLoading] = useState(true);
	const [logEntries, setLogEntries] = useState<{ [key: string]: DailyLogEntry }>({});
	const [intervalStartDate, setIntervalStartDate] = useState(initialIntervalStart);
	const [selectedSymptoms, setSelectedSymptoms] = useState<string[]>([]);
	const [selectedTreatments, setSelectedTreatments] = useState<string[]>([]);
	const [expandedDropdown, setExpandedDropdown] = useState<"Symptoms" | "Treatments" | null>(null);
	const [symptomSharkParticipantInfo, setSymptomSharkParticipantInfo] = useState<SymptomSharkParticipantInfo | null>(null);

	function trackPageVisit() {
		props.onVisit?.();
	}

	useEffect(() => {
		load();
		trackPageVisit();
		MyDataHelps.on("applicationDidBecomeVisible", load);
		MyDataHelps.on("applicationDidBecomeVisible", trackPageVisit);
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", load);
			MyDataHelps.off("applicationDidBecomeVisible", trackPageVisit);
		}
	}, []);

	function load() {
		symptomSharkData.getParticipantInfo().then(function (info) {
			symptomSharkData.getDailyLogEntries().then(function (logEntries) {
				setSymptomSharkParticipantInfo(info);
				setLogEntries(logEntries);
				setLoading(false);
			})
		})
	}

	function viewDay(d: number) {
		var date = new Date(intervalStartDate.getFullYear(), intervalStartDate.getMonth(), d);
		if (date > new Date()) {
			return;
		}
		props.onDaySelected(date)
	}

	function renderDay(year: number, month: number, day?: number) {
		if (!day) {
			return <div />;
		}
		var date = new Date(year, month, day);
		var logEntry = logEntries[getDayKey(date)];

		var future = false;
		if (date > new Date()) {
			future = true;
		}

		return <CalendarDay onClick={(d) => viewDay(d)}
			day={day}
			logEntry={logEntry}
			symptomConfigurations={getSelectedSymptomConfigurations()}
			treatmentConfigurations={getSelectedTreatmentConfigurations()}
			future={future} />;
	}

	function getSelectedSymptomConfigurations() {
		return symptomSharkParticipantInfo!.symptoms.filter((s) => !selectedSymptoms.length || selectedSymptoms.indexOf(s.id) != -1);
	}

	function getSelectedTreatmentConfigurations() {
		return symptomSharkParticipantInfo!.treatments.filter((t) => !selectedTreatments.length || selectedTreatments.indexOf(t.id) != -1);
	}

	return (
		<Layout className="calendar-view symptom-shark-view" colorScheme={props.colorScheme ?? "light"}>
			<NavigationBar className="calendar-navigation-bar" variant='compressed'>
				<DateRangeNavigator variant="rounded" intervalStart={intervalStartDate} intervalType="Month" onIntervalChange={(d) => setIntervalStartDate(d)} />
			</NavigationBar>
			{loading &&
				<LoadingIndicator />
			}
			{!loading && symptomSharkParticipantInfo &&
				<>
					<Section noTopMargin>
						<SymptomTreatmentFilters
							selectedSymptoms={selectedSymptoms}
							selectedTreatments={selectedTreatments}
							expandedDropdown={expandedDropdown}
							onExpandedDropdownChange={(e) => setExpandedDropdown(e)}
							onSymptomsSelectionChange={(s) => setSelectedSymptoms(s)}
							onTreatmentsSelectionChange={(t) => setSelectedTreatments(t)}
							symptomSharkParticipantInfo={symptomSharkParticipantInfo} />
					</Section>
					<Section>
						<Calendar month={intervalStartDate.getMonth()}
							year={intervalStartDate.getFullYear()}
							dayRenderer={renderDay} />
					</Section>
					<SymptomTreatmentHistograms symptoms={getSelectedSymptomConfigurations()}
						treatments={getSelectedTreatmentConfigurations()}
						currentMonth={intervalStartDate.getMonth()}
						currentYear={intervalStartDate.getFullYear()}
						logEntries={logEntries}
						onSymptomSelected={(s) => props.onSymptomSelected(s, intervalStartDate.getFullYear(), intervalStartDate.getMonth())} />
					<OverallExperienceChart currentYear={intervalStartDate.getFullYear()}
						currentMonth={intervalStartDate.getMonth()}
						logEntries={logEntries} />
					{selectedSymptoms.length > 0 && getSelectedSymptomConfigurations().map(s =>
						<SymptomSeverityChart symptom={s}
							showAllDays={false}
							currentMonth={intervalStartDate.getMonth()}
							currentYear={intervalStartDate.getFullYear()}
							logEntries={logEntries} />
					)}
					<div style={{ height: "16px" }} />
				</>
			}
		</Layout>
	)
}