import React, { useState } from 'react';
import "./DeviceActivityFilters.css";
import { SymptomSharkParticipantInfo } from '../../../services/typed_data';
import { MetricConfiguration } from '../../../types/MetricConfiguration';
import SymptomTreatmentFilters from '../SymptomTreatmentFilters/SymptomTreatmentFilters';
import DeviceDataTypeFilter from '../DeviceDataTypeFilter/DeviceDataTypeFilter';

export interface DeviceActivityFiltersProps {
	appleHealthConnectionSurvey?: string;
	availableMetricConfigurations: MetricConfiguration[];
	selectedMetrics: string[];
	selectedSymptoms: string[];
	selectedTreatments: string[];
	highlightOutOfRange: boolean;
	onHighlightOutOfRangeChange(highlightOutOfRange: boolean): void;
	symptomSharkParticipantInfo: SymptomSharkParticipantInfo;
	onMetricsSelectionChange(metrics: string[]): void;
	onSymptomsSelectionChange(symptoms: string[]): void;
	onTreatmentsSelectionChange(treatments: string[]): void;
	hasConnectedFitbit: boolean;
	hasConnectedGarmin: boolean;
	hasEnabledAppleHealth: boolean;
}

export default function (props: DeviceActivityFiltersProps) {
	const [expandedDropdown, setExpandedDropdown] = useState<"DataTypes" | "Symptoms" | "Treatments" | null>(null);

	return <div className="device-activity-filters">
		<DeviceDataTypeFilter
			appleHealthConnectionSurvey={props.appleHealthConnectionSurvey}
			allAvailableMetrics={props.availableMetricConfigurations}
			selectedMetrics={props.selectedMetrics}
			onSelectionChange={(m) => props.onMetricsSelectionChange(m)}
			expanded={expandedDropdown == "DataTypes"}
			onExpand={() => expandedDropdown == "DataTypes" ? setExpandedDropdown(null) : setExpandedDropdown("DataTypes")}
			hasConnectedFitbit={props.hasConnectedFitbit}
			hasConnectedGarmin={props.hasConnectedGarmin}
			hasEnabledAppleHealth={props.hasEnabledAppleHealth}
			highlightOutOfRange={props.highlightOutOfRange}
			onHighlightOutOfRangeChange={(h) => props.onHighlightOutOfRangeChange(h)} />
		{(props.symptomSharkParticipantInfo.symptoms.length > 0 || props.symptomSharkParticipantInfo.treatments.length > 0) &&
			<SymptomTreatmentFilters expandedDropdown={expandedDropdown as any}
				onExpandedDropdownChange={(d) => setExpandedDropdown(d)}
				selectedSymptoms={props.selectedSymptoms}
				selectedTreatments={props.selectedTreatments}
				onSymptomsSelectionChange={(s) => props.onSymptomsSelectionChange(s)}
				onTreatmentsSelectionChange={(t) => props.onTreatmentsSelectionChange(t)}
				symptomSharkParticipantInfo={props.symptomSharkParticipantInfo} />
		}
	</div>;
}