import React from "react";
import "./SymptomSeverityChart.css"
import { DailyLogEntry, SymptomConfiguration } from "../../../services/typed_data";
import getDayKey from "../../../helpers/get-day-key";
import { ShinyOverlay } from "@careevolution/mydatahelps-ui";
import { getDatesForMonth } from "../../../helpers/date-helpers";
import language from "../../../helpers/language";

interface SymptomSeverityChartProps {
	symptom: SymptomConfiguration;
	logEntries: { [key: string]: DailyLogEntry };
	currentMonth: number;
	currentYear: number;
	showAllDays?: boolean;
}

export default function (props: SymptomSeverityChartProps) {
	var monthDays = getDatesForMonth(props.currentYear, props.currentMonth);

	var symptomAverage = function () {
		var total = 0;

		var relevantEntries = [];
		for (var i = 0; i < monthDays.length; i++) {
			var day = getDayKey(monthDays[i]);
			if (props.logEntries[day]) {
				var matchingSymptom = props.logEntries[day].symptoms.find((s) => s.id == props.symptom.id);
				if (matchingSymptom && matchingSymptom.severity) {
					relevantEntries.push(matchingSymptom);
				}
			}
		}

		if (props.symptom.severityTracking == '3PointScale') {
			total += relevantEntries.filter((s) => s.severity && s.severity < 4).length;
			total += 2 * relevantEntries.filter((s) => s.severity && s.severity >= 4 && s.severity < 8).length;
			total += 3 * relevantEntries.filter((s) => s.severity && s.severity >= 8).length;
		}
		else {
			for (var j = 0; j < relevantEntries.length; j++) {
				total += relevantEntries[j].severity ?? 0;
			}
		}
		return total / relevantEntries.length;
	}

	var symptomSeverity = function (date: Date) {
		var day = getDayKey(date);
		if (props.logEntries[day]) {
			var matchingSymptom = props.logEntries[day].symptoms.find((s) => props.symptom.id == s.id);
			if (matchingSymptom && matchingSymptom.severity) {
				if (props.symptom.severityTracking == '3PointScale') {
					if (matchingSymptom.severity < 4) { return 1; }
					if (matchingSymptom.severity >= 4 && matchingSymptom.severity < 8) { return 2; }
					return 3;
				} else {
					return matchingSymptom.severity;
				}
			}
		}
		return 0;
	}

	var daysMatchingScore = function (score: number) {
		var result = 0;
		for (var i = 0; i < monthDays.length; i++) {
			var severity = symptomSeverity(monthDays[i]);
			if (severity == score) {
				result++;
			}
		}
		return result;
	}

	if (isNaN(symptomAverage())) {
		return null;
	}

	return <div className="graph-wrapper symptom-severity-chart">
		<div className="title">
			{props.symptom.name} {language["severity"]}
		</div>
		{props.symptom.severityTracking == "3PointScale" &&
			<div>
				<div className="symptom-graph three-point-scale">
					<div className="y-axis">
						<div className="value">
							<span className="total">{daysMatchingScore(3)}</span>sev
						</div>
						<div className="value">
							<span className="total">{daysMatchingScore(2)}</span>mod
						</div>
						<div className="value">
							<span className="total">{daysMatchingScore(1)}</span>mild
						</div>
						<div className="value">
							<span className="total"></span>n/a
						</div>
					</div>
					<div className="x-axis-line"></div>
					<div className="x-axis-line" style={{ top: "48px" }}></div>
					<div className="x-axis-line" style={{ top: "72px" }}></div>
					<div className="x-axis-line" style={{ top: "96px" }}></div>
					<div style={{ position: "relative", height: "100%" }}>
						{monthDays.map(m =>
							<div key={m.toISOString()} className="bar-wrapper" style={{ width: 100 / monthDays.length + '%' }}>
								{(props.showAllDays || (m.getDate() - 1) % 2 == 0) &&
									<div className="day-marker">
										{m.getDate()}
									</div>
								}
								<div className="bar" style={{ height: (symptomSeverity(m) * 24) + 'px', backgroundColor: props.symptom.color }}>
									<ShinyOverlay />
								</div>
							</div>
						)}
					</div>
				</div>
				{symptomAverage() &&
					<div className="average-wrapper">
						{language["average"]} <div className="average">
							{symptomAverage().toFixed(1)} / 3
						</div>
					</div>
				}
			</div>
		}
		{props.symptom.severityTracking == "10PointScale" &&
			<div>
				<div className="symptom-graph ten-point-scale">
					<div className="y-axis">
						{[10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((i) =>
							<div key={i} className="value">
								<span className="total">{daysMatchingScore(i)}</span><div className="value-label">{i}</div>
							</div>
						)}
						<div className="value">
							<span className="total"></span><div className="value-label">0</div>
						</div>
					</div>
					{[10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((i) =>
						<div key={i} className="x-axis-line" style={{ top: (11 - i) * 24 + 'px' }}></div>
					)}
					<div style={{ position: "relative", height: "100%" }}>
						{monthDays.map(m =>
							<div key={m.toISOString()} className="bar-wrapper" style={{ width: 100 / monthDays.length + '%' }}>
								{(props.showAllDays || (m.getDate() - 1) % 2 == 0) &&
									<div className="day-marker">
										{m.getDate()}
									</div>
								}
								<div className="bar" style={{ height: (symptomSeverity(m) * 24) + 'px', backgroundColor: props.symptom.color }}>
									<ShinyOverlay />
								</div>
							</div>
						)}
					</div>
				</div>

				{symptomAverage() &&
					<div className="average-wrapper">
						{language["average"]} <div className="average">
							{symptomAverage().toFixed(1)} / 10
						</div>
					</div>
				}
			</div>
		}
	</div>;
}