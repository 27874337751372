import React, { useEffect, useState } from 'react';
import { Layout, NavigationBar, SegmentedControl } from '@careevolution/mydatahelps-ui';
import MyDataHelps from "@careevolution/mydatahelps-js"
import symptomSharkData from '../../services/typed_data';
import { LogEntryList, QuestionList } from '../../components';
import "./LogView.css"
import language from '../../helpers/language';
import { SymptomSharkLogo } from '../../components/Presentational/SymptomSharkLogo/SymptomSharkLogo';

export interface LogViewProps {
	colorScheme?: "light" | "dark" | "auto";
	onDaySelected(d: Date): void;
	onQuestionSelected(d: string): void;
	onVisit?(): void;
}

export default function (props: LogViewProps) {
	const [section, setSection] = useState<string>('entries');

	function trackPageVisit() {
		props.onVisit?.();
	}

	useEffect(() => {
		trackPageVisit();
		symptomSharkData.getParticipantInfo().then(function (participantInfo) {
			if (participantInfo.promotePacingNotifications) {
				MyDataHelps.getDeviceInfo().then(function (info) {
					if (info?.platform != "Web" || participantInfo.phoneNumber) {
						symptomSharkData.hasSeenPacingNotifications().then(function (seenPacingNotifications) {
							if (!seenPacingNotifications) {
								MyDataHelps.openApplication(new URL("./pacing-notifications.html?popup=true", document.location as any).href, { modal: true });
								symptomSharkData.setHasSeenPacingNotifications();
							}
						});
					}
				});
			}
		});

		MyDataHelps.on("applicationDidBecomeVisible", trackPageVisit);
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", trackPageVisit);
		}
	}, []);

	return (
		<Layout className="log-view symptom-shark-view" colorScheme={props.colorScheme || "light"}>
			<NavigationBar className='log-navigation-bar' variant='compressed' title="">
				<SegmentedControl color="var(--ss-primary-theme-color)" className='log-view-picker' selectedSegment={section} segments={[{ key: "entries", title: language["entries"] }, { key: "questions", title: language["dr-q-a"] }]} onSegmentSelected={(s: string) => setSection(s)} />
			</NavigationBar>
			{section == "entries" &&
				<>
					<LogEntryList onDaySelected={(d) => props.onDaySelected(d)} />
					<SymptomSharkLogo />
				</>
			}
			{section == "questions" &&
				<QuestionList onQuestionSelected={(d) => props.onQuestionSelected(d)} />
			}
		</Layout>
	);
}