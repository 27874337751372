import { UnstyledButton } from "@careevolution/mydatahelps-ui";
import React from "react";
import { QuestionForDoctor } from "../../../services/typed_data";
import "./Question.css";

export interface QuestionProps {
	question: QuestionForDoctor;
	onQuestionSelected(questionDateString: string): void;
}

export default function (props: QuestionProps) {
	var startEditing = function () {
		props.onQuestionSelected(props.question.date);
	}

	return <UnstyledButton className="question" onClick={() => startEditing()}>
		{props.question.answer &&
			<i className="fa fa-check-circle-o"></i>
		}
		{!props.question.answer &&
			<i className="fa fa-circle-o"></i>
		}
		<div className="question-text">{props.question.question}</div>
		{props.question.answer &&
			<div className="answer-text">{props.question.answer}</div>
		}
	</UnstyledButton>;
}