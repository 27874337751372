import MyDataHelps from "@careevolution/mydatahelps-js"
import metricsConfiguration from "./metrics";
import { checkDailyDataAvailability } from "@careevolution/mydatahelps-ui";

export default function (): Promise<string[]> {
	return MyDataHelps.queryDeviceData({
		namespace: 'Project',
		type: 'AvailableMetrics'
	}).then(function (availableMetricsResult) {
		var metrics: string[] = [];
		if (availableMetricsResult.deviceDataPoints.length) {
			metrics = JSON.parse(availableMetricsResult.deviceDataPoints[0].value);
		}

		var availabilityChecks: Promise<Boolean>[] = [];
		var uncheckedMetrics = metricsConfiguration.filter((m) =>
			metrics.indexOf(m.key) == -1
		);

		uncheckedMetrics.forEach((m) => {
			availabilityChecks.push(checkDailyDataAvailability(m.dailyDataType));
		});

		metrics = metrics.filter(met => metricsConfiguration.find((m) =>
			m.key == met
		));

		if (availabilityChecks.length) {
			var update = false;
			return Promise.all(availabilityChecks).then((values) => {
				values.forEach((v, index) => {
					if (v) {
						update = true;
						metrics.push(uncheckedMetrics[index].key);
					}
				});

				if (update) {
					MyDataHelps.persistDeviceData([{
						identifier: "",
						type: "AvailableMetrics",
						value: JSON.stringify(metrics)
					}]);
				}
				return metrics;
			});
		} else {
			return metrics;
		}
	});
}