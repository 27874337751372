import React from 'react';
import "./FeatureContent.css"

export interface FeatureContentProps {
	children?: React.ReactNode;
}

export default function (props: FeatureContentProps) {
	return (
		<div className="feature-content">
			{props.children}
		</div>
	);
}