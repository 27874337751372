import React, { useEffect, useState } from "react";
import { LoadingIndicator } from "@careevolution/mydatahelps-ui";
import symptomSharkData, { DailyLogEntry, SymptomSharkParticipantInfo } from "../../../services/typed_data";
import getDayKey from "../../../helpers/get-day-key";
import { add } from "date-fns";
import LogEntry from "../../Presentational/LogEntry/LogEntry";
import MyDataHelps from "@careevolution/mydatahelps-js"
import OnVisibleTrigger from "../../../helpers/OnVisibleTrigger";

export interface LogEntryListProps {
	onDaySelected(d: Date): void;
}

export default function (props: LogEntryListProps) {
	const [logEntries, setLogEntries] = useState<{ [key: string]: DailyLogEntry }>({});
	const [participantInfo, setParticipantInfo] = useState<SymptomSharkParticipantInfo | null>(null);
	const [days, setDays] = useState<Date[]>([new Date()]);

	function addDays() {
		var newDays = [...days];
		for (var i = 0; i < 30; i++) {
			var newDate = add(newDays[newDays.length - 1], { days: -1 });
			newDays.push(newDate);
		}
		setDays(newDays);
	}

	function initialize() {
		if (getDayKey(days[0]) != getDayKey(new Date())) {
			setDays([new Date()]);
		}

		symptomSharkData.getParticipantInfo().then(function (participantInfo) {
			symptomSharkData.getDailyLogEntries().then(function (logEntries) {
				setParticipantInfo(participantInfo);
				setLogEntries(logEntries);
			});
		});
	}

	useEffect(() => {
		initialize();
		MyDataHelps.on("applicationDidBecomeVisible", initialize);
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", initialize);
		}
	}, []);

	return <div className="log-entry-list">
		{participantInfo && days.map(d =>
			<LogEntry key={getDayKey(d)} date={d} participantInfo={participantInfo} logEntry={logEntries[getDayKey(d)]} onDaySelected={(d) => props.onDaySelected(d)} />
		)}
		<OnVisibleTrigger enabled={!!participantInfo} onTrigger={() => addDays()} />
		<LoadingIndicator />
	</div>

}