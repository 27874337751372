import React from 'react';
import "./FeatureImage.css"

export interface FeatureImageProps {
	children?: React.ReactNode;
}

export default function (props: FeatureImageProps) {
	return (
		<div className="feature-image-wrapper">
			<div className="feature-image">
				{props.children}
			</div>
		</div>
	);
}