import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { faBed, faHeartbeat, faLungs, faPerson, faPersonRunning, faShoePrints, faStairs } from '@fortawesome/free-solid-svg-icons';
import { MetricConfiguration } from '../types/MetricConfiguration';
import language from './language';
import { DailyDataType } from '@careevolution/mydatahelps-ui';

var metricsConfiguration: MetricConfiguration[] = [
	{
		key: "AppleHealthSteps",
		dailyDataType: "AppleHealthSteps",
		label: language["steps"],
		source: "Apple Health",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.floor(number).toLocaleString()
		},
		icon: <FontAwesomeIcon rotate={270} icon={faShoePrints} />
	},
	{
		key: "AppleHealthSleep",
		dailyDataType: "AppleHealthSleepMinutes",
		label: language["sleep-time"],
		source: "Apple Health",
		color: "#7b88c6",
		formatter: function (number: number) {
			var hours = Math.floor(number / 60);
			var displayValue = hours + "h " + Math.round(number - (hours * 60)) + "m";
			return displayValue;
		},
		icon: <FontAwesomeIcon icon={faBed} />
	},
	{
		key: "FitbitSleep",
		dailyDataType: "FitbitSleepMinutes",
		label: language["sleep-time"],
		source: "Fitbit",
		color: "#7b88c6",
		formatter: function (number: number) {
			var hours = Math.floor(number / 60);
			var displayValue = hours + "h " + Math.round(number - (hours * 60)) + "m";
			return displayValue;
		},
		icon: <FontAwesomeIcon icon={faBed} />
	},
	{
		key: "FitbitRemSleepMinutes",
		dailyDataType: "FitbitRemSleepMinutes",
		label: language["rem-sleep-time"],
		source: "Fitbit",
		color: "#7b88c6",
		formatter: function (number: number) {
			var hours = Math.floor(number / 60);
			var displayValue = hours + "h " + Math.round(number - (hours * 60)) + "m";
			return displayValue;
		},
		icon: <FontAwesomeIcon icon={faBed} />
	},
	{
		key: "FitbitLightSleepMinutes",
		dailyDataType: "FitbitLightSleepMinutes",
		label: language["light-sleep-time"],
		source: "Fitbit",
		color: "#7b88c6",
		formatter: function (number: number) {
			var hours = Math.floor(number / 60);
			var displayValue = hours + "h " + Math.round(number - (hours * 60)) + "m";
			return displayValue;
		},
		icon: <FontAwesomeIcon icon={faBed} />
	},
	{
		key: "FitbitDeepSleepMinutes",
		dailyDataType: "FitbitDeepSleepMinutes",
		label: language["deep-sleep-time"],
		source: "Fitbit",
		color: "#7b88c6",
		formatter: function (number: number) {
			var hours = Math.floor(number / 60);
			var displayValue = hours + "h " + Math.round(number - (hours * 60)) + "m";
			return displayValue;
		},
		icon: <FontAwesomeIcon icon={faBed} />
	},
	{
		key: "FitbitSteps",
		dailyDataType: "FitbitSteps",
		label: language["steps"],
		source: "Fitbit",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.floor(number).toLocaleString()
		},
		icon: <FontAwesomeIcon rotate={270} icon={faShoePrints} />
	},
	{
		key: "AppleHealthInBed",
		dailyDataType: "AppleHealthInBedMinutes",
		label: language["in-bed-time"],
		source: "Apple Health",
		color: "#7b88c6",
		formatter: function (number: number) {
			var hours = Math.floor(number / 60);
			var displayValue = hours + "h " + Math.round(number - (hours * 60)) + "m";
			return displayValue;
		},
		icon: <FontAwesomeIcon icon={faBed} />
	},
	{
		key: "AppleHealthFlightsClimbed",
		dailyDataType: "AppleHealthFlightsClimbed",
		label: language["flights-climbed"],
		source: "Apple Health",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.round(number).toString()
		},
		icon: <FontAwesomeIcon rotate={270} icon={faStairs} />
	},
	{
		key: "FitbitCalories",
		dailyDataType: "FitbitCaloriesBurned",
		label: language["calories-burned"],
		source: "Fitbit",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.floor(number).toString();
		},
		icon: <FontAwesomeIcon icon={faPersonRunning} />
	},
	{
		key: "FitbitTotalActivityMinutes",
		dailyDataType: "FitbitActiveMinutes",
		label: language["activity-time"],
		source: "Fitbit",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faPersonRunning} />
	},
	{
		key: "FitbitLightlyActiveMinutes",
		dailyDataType: "FitbitLightlyActiveMinutes",
		label: language["lightly-active-time"],
		source: "Fitbit",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faPersonRunning} />
	},
	{
		key: "FitbitFairlyActiveMinutes",
		dailyDataType: "FitbitFairlyActiveMinutes",
		label: language["fairly-active-time"],
		source: "Fitbit",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faPersonRunning} />
	},
	{
		key: "FitbitVeryActiveMinutes",
		dailyDataType: "FitbitVeryActiveMinutes",
		label: language["very-active-time"],
		source: "Fitbit",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faPersonRunning} />
	},
	{
		key: "FitbitFloors",
		dailyDataType: "FitbitFloors",
		label: language["floors-climbed"],
		source: "Fitbit",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.round(number).toString();
		},
		icon: <FontAwesomeIcon icon={faStairs} />
	},
	{
		key: "AppleHealthStandTime",
		dailyDataType: "AppleHealthStandMinutes",
		label: language["stand-time"],
		source: "Apple Health",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faPerson} />
	},
	{
		key: "AppleHealthMaxHeartRate",
		dailyDataType: "AppleHealthMaxHeartRate",
		label: language["max-heart-rate"],
		source: "Apple Health",
		color: "#e35c33",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " bpm";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: "FitbitElevatedHeartRateMinutes",
		dailyDataType: "FitbitElevatedHeartRateMinutes",
		label: language["elevated-heart-rate-time"],
		source: "Fitbit",
		color: "#e35c33",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: "FitbitFatBurnHeartRateMinutes",
		dailyDataType: "FitbitFatBurnHeartRateMinutes",
		label: language["fat-burn-time"],
		source: "Fitbit",
		color: "#e35c33",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: "FitbitCardioHeartRateMinutes",
		dailyDataType: "FitbitCardioHeartRateMinutes",
		label: language["cardio-time"],
		source: "Fitbit",
		color: "#e35c33",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: "FitbitPeakHeartRateMinutes",
		dailyDataType: "FitbitPeakHeartRateMinutes",
		label: language["peak-time"],
		source: "Fitbit",
		color: "#e35c33",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: "FitbitRestingHeartRate",
		dailyDataType: "FitbitRestingHeartRate",
		label: language["resting-heart-rate"],
		source: "Fitbit",
		color: "#e35c33",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " bpm";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: "AppleHealthHeartRateVariability",
		dailyDataType: "AppleHealthHrv",
		label: language["heart-rate-variability"],
		source: "Apple Health",
		color: "#5db37e",
		formatter: function (number: number) {
			return Math.floor(number).toString();
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: "AppleHealthRestingHeartRate",
		dailyDataType: "AppleHealthRestingHeartRate",
		label: language["resting-heart-rate"],
		source: "Apple Health",
		color: "#e35c33",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " bpm";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: "AppleHealthWalkingHeartRateAverage",
		dailyDataType: "AppleHealthWalkingHeartRateAverage",
		label: language["walking-heart-rate"],
		source: "Apple Health",
		color: "#5db37e",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " bpm";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: "FitbitHeartRateVariability",
		dailyDataType: "FitbitHrv",
		label: language["heart-rate-variability"],
		source: "Fitbit",
		color: "#5db37e",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " ms";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: "FitbitSpO2",
		dailyDataType: "FitbitSpO2",
		label: "SpO2",
		source: "Fitbit",
		color: "#5db37e",
		formatter: function (number: number) {
			return Math.floor(number).toString() + "%";
		},
		icon: <FontAwesomeIcon icon={faLungs} />
	},
	{
		key: "FitbitBreathingRate",
		dailyDataType: "FitbitBreathingRate",
		label: language["breathing-rate"],
		source: "Fitbit",
		color: "#5db37e",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " bpm";
		},
		icon: <FontAwesomeIcon icon={faLungs} />
	},
	{
		key: DailyDataType.GarminSteps,
		dailyDataType: DailyDataType.GarminSteps,
		label: language["steps"],
		source: "Garmin",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.floor(number).toLocaleString()
		},
		icon: <FontAwesomeIcon icon={faShoePrints} />
	},
	{
		key: DailyDataType.GarminFloors,
		dailyDataType: DailyDataType.GarminFloors,
		label: language["floors-climbed"],
		source: "Garmin",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.round(number).toString();
		},
		icon: <FontAwesomeIcon icon={faStairs} />
	},
	{
		key: DailyDataType.GarminActiveMinutes,
		dailyDataType: DailyDataType.GarminActiveMinutes,
		label: language["activity-time"],
		source: "Garmin",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faPersonRunning} />
	},
	{
		key: DailyDataType.GarminRestingHeartRate,
		dailyDataType: DailyDataType.GarminRestingHeartRate,
		label: language["resting-heart-rate"],
		source: "Garmin",
		color: "#e35c33",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " bpm";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: DailyDataType.GarminMaxHeartRate,
		dailyDataType: DailyDataType.GarminMaxHeartRate,
		label: language["max-heart-rate"],
		source: "Garmin",
		color: "#e35c33",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " bpm";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: DailyDataType.GarminTotalStressMinutes,
		dailyDataType: DailyDataType.GarminTotalStressMinutes,
		label: language["stress-time"],
		source: "Garmin",
		color: "#5db37e",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: DailyDataType.GarminLowStressMinutes,
		dailyDataType: DailyDataType.GarminLowStressMinutes,
		label: language["low-stress-time"],
		source: "Garmin",
		color: "#5db37e",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: DailyDataType.GarminMediumStressMinutes,
		dailyDataType: DailyDataType.GarminMediumStressMinutes,
		label: language["medium-stress-time"],
		source: "Garmin",
		color: "#5db37e",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: DailyDataType.GarminHighStressMinutes,
		dailyDataType: DailyDataType.GarminHighStressMinutes,
		label: language["high-stress-time"],
		source: "Garmin",
		color: "#5db37e",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: DailyDataType.GarminMaxStressLevel,
		dailyDataType: DailyDataType.GarminHighStressMinutes,
		label: language["max-stress-level"],
		source: "Garmin",
		color: "#5db37e",
		formatter: function (number: number) {
			return Math.floor(number).toString();
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />
	},
	{
		key: DailyDataType.GarminSleepScore,
		dailyDataType: DailyDataType.GarminSleepScore,
		label: language["sleep-score"],
		source: "Garmin",
		color: "#7b88c6",
		formatter: function (number: number) {
			return Math.floor(number).toString();
		},
		icon: <FontAwesomeIcon icon={faBed} />
	},
	{
		key: DailyDataType.GarminTotalSleepMinutes,
		dailyDataType: DailyDataType.GarminTotalSleepMinutes,
		label: language["sleep-time"],
		source: "Garmin",
		color: "#7b88c6",
		formatter: function (number: number) {
			var hours = Math.floor(number / 60);
			var displayValue = hours + "h " + Math.round(number - (hours * 60)) + "m";
			return displayValue;
		},
		icon: <FontAwesomeIcon icon={faBed} />
	},
	{
		key: DailyDataType.GarminDeepSleepMinutes,
		dailyDataType: DailyDataType.GarminDeepSleepMinutes,
		label: language["deep-sleep-time"],
		source: "Garmin",
		color: "#7b88c6",
		formatter: function (number: number) {
			var hours = Math.floor(number / 60);
			var displayValue = hours + "h " + Math.round(number - (hours * 60)) + "m";
			return displayValue;
		},
		icon: <FontAwesomeIcon icon={faBed} />
	},
	{
		key: DailyDataType.GarminRemSleepMinutes,
		dailyDataType: DailyDataType.GarminRemSleepMinutes,
		label: language["rem-sleep-time"],
		source: "Garmin",
		color: "#7b88c6",
		formatter: function (number: number) {
			var hours = Math.floor(number / 60);
			var displayValue = hours + "h " + Math.round(number - (hours * 60)) + "m";
			return displayValue;
		},
		icon: <FontAwesomeIcon icon={faBed} />
	},
	{
		key: DailyDataType.GarminLightSleepMinutes,
		dailyDataType: DailyDataType.GarminLightSleepMinutes,
		label: language["light-sleep-time"],
		source: "Garmin",
		color: "#7b88c6",
		formatter: function (number: number) {
			var hours = Math.floor(number / 60);
			var displayValue = hours + "h " + Math.round(number - (hours * 60)) + "m";
			return displayValue;
		},
		icon: <FontAwesomeIcon icon={faBed} />
	}
];

export default metricsConfiguration;