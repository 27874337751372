import React, { useEffect, useState } from 'react';
import "./FAQ.css"

export interface FAQProps {
	question: string;
	children?: React.ReactNode;
	initiallyExpanded?: boolean;
}

export default function (props: FAQProps) {
	var [expanded, setExpanded] = useState(false);

	useEffect(function () {
		if (props.initiallyExpanded) {
			setExpanded(true);
		}
	}, [])

	return (
		<div className={"faq" + (expanded ? " expanded" : "")}>
			<div className="faq-question" onClick={() => setExpanded(!expanded)}>
				{props.question}
				<i className='fa fa-chevron-down'></i>
				<i className='fa fa-chevron-up'></i>
			</div>
			<div className="faq-answer">
				{props.children}
			</div>
		</div>
	);
}