import React, { useState, useEffect } from 'react';
import MyDataHelps from "@careevolution/mydatahelps-js"
import symptomSharkData, { DailyLogEntry, SymptomSharkParticipantInfo } from '../../services/typed_data';
import { Layout, LoadingIndicator, NavigationBar } from '@careevolution/mydatahelps-ui';
import { ReportBuilder } from '../../components';
import language from '../../helpers/language';

export interface ReportsViewProps {
	colorScheme?: "light" | "dark" | "auto";
	productLogo?: string;
	productName?: string;
	productUrl?: string;
	onVisit?(): void;
}

export default function (props: ReportsViewProps) {
	const [loading, setLoading] = useState(true);
	const [participantInfo, setParticipantInfo] = useState<SymptomSharkParticipantInfo | null>(null);
	const [logEntries, setLogEntries] = useState<{ [key: string]: DailyLogEntry }>({});

	function initialize() {
		symptomSharkData.getParticipantInfo().then(function (participantInfo) {
			symptomSharkData.getDailyLogEntries().then(function (logEntries) {
				setParticipantInfo(participantInfo);
				setLogEntries(logEntries);
				setLoading(false);
			});
		});
	}

	function trackPageVisit() {
		props.onVisit?.();
	}

	useEffect(() => {
		initialize();
		trackPageVisit();
		MyDataHelps.on("applicationDidBecomeVisible", initialize);
		MyDataHelps.on("applicationDidBecomeVisible", trackPageVisit);
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", initialize);
			MyDataHelps.off("applicationDidBecomeVisible", trackPageVisit);
		}
	}, []);

	return (
		<Layout className="symptom-shark-view"  colorScheme={props.colorScheme || "light"}>
			<NavigationBar variant='compressed' title={language["reports"]} />
			{loading &&
				<LoadingIndicator />
			}
			{!loading &&
				<ReportBuilder participantInfo={participantInfo!}
					logEntries={logEntries}
					productLogo={props.productLogo}
					productUrl={props.productUrl}
					productName={props.productName} />
			}
		</Layout>
	);
}