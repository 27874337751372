import React from "react";
import { LoadingIndicator } from "@careevolution/mydatahelps-ui";
import "./SyncIndicator.css"
import MyDataHelps, { ExternalAccount, ExternalAccountStatus } from "@careevolution/mydatahelps-js"
import { parseISO, isAfter, add } from 'date-fns';
import language from "../../../helpers/language";

interface SyncIndicatorProps {
	externalAccounts: ExternalAccount[] | null,
	appleHealthEnabledDate: Date | null
}

export default function (props: SyncIndicatorProps) {
	let fetchingData: boolean = false;
	props.externalAccounts?.forEach(account => {
		if (account.status == "fetchComplete") {
			var fetchDate = parseISO(account.lastRefreshDate);
			var threshold = add(fetchDate, { hours: 3 });
			if (isAfter(new Date(), threshold)) {
				MyDataHelps.refreshExternalAccount(account.id);
				fetchingData = true;
			}
		}
		if (account.status == "fetchingData") {
			fetchingData = true;
		}
	});

	if (fetchingData || (props.appleHealthEnabledDate && add(props.appleHealthEnabledDate, { minutes: 3 }) > new Date())) {
		return <div className="sync-indicator">
			<div className="sync-indicator-loading-indicator">
				<LoadingIndicator variant="inline" />
			</div> {language["sync-in-progress"]}
		</div>
	}
	return null;
}