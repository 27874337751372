import React, { useEffect, useRef, useState } from 'react';
import MyDataHelps from "@careevolution/mydatahelps-js";
import { Card, WeekCalendar } from '@careevolution/mydatahelps-ui';
import getDayKey from '../../../helpers/get-day-key';
import { add } from 'date-fns';
import "./Correlator.css"
import { MetricConfiguration } from '../../../types/MetricConfiguration';
import { queryCachedMetrics } from '../../../helpers/query-metrics';
import { MetricsSummaryResult, summarizeMetrics } from '../../../helpers/summarize-metrics';
import symptomSharkData, { DailyLogEntry, SymptomSharkParticipantInfo } from '../../../services/typed_data';
import DeviceActivityLogEntry from '../../Presentational/DeviceActivityLogEntry/DeviceActivityLogEntry';
import { getDayOfWeek, getFullDateString } from '../../../helpers/date-helpers';
import DailyDeviceActivityMetrics from '../../Presentational/DailyDeviceActivityMetrics/DailyDeviceActivityMetrics';
import DeviceActivityCalendarDay from '../../Presentational/DeviceActivityCalendarDay/DeviceActivityCalendarDay';

interface CorrelatorProps {
	metrics: MetricConfiguration[];
	selectedDate: Date;
	intervalStartDate: Date;
	onDateSelected(d: Date): void;
	onIntervalChange(startDate: Date): void;
	symptomSharkParticipantInfo: SymptomSharkParticipantInfo;
	selectedSymptoms: string[];
	selectedTreatments: string[];
	highlightOutOfRange: boolean;
	onDaySelected(d: Date): void;
}

export default function (props: CorrelatorProps) {
	const [metricsLookup, setMetricsLookup] = useState<{ [key: string]: { [key: string]: number } }>({});
	const [logEntries, setLogEntries] = useState<{ [key: string]: DailyLogEntry }>({});
	const [loading, setLoading] = useState(false);

	const lastIntervalStart = useRef<Date | null>(null);
	const requestID = useRef<number>(0);

	var loadStart = add(props.intervalStartDate, { days: -33 });
	var weekEnd = add(props.intervalStartDate, { days: 8 });

	function load() {
		requestID.current++;
		var currentRequestID = requestID.current;
		symptomSharkData.getDailyLogEntries(add(props.intervalStartDate, { days: -1 }).toISOString(), add(props.intervalStartDate, { days: 8 }).toISOString()).then(function (logEntries) {
			queryCachedMetrics(loadStart, weekEnd, props.metrics).then(function (result) {
				if (requestID.current != currentRequestID) { return; }
				setLogEntries(logEntries);
				setMetricsLookup(result.metricsByDay);
				setLoading(false);
			});
		});
	}

	//if the actual set of available metrics changes, reset the whole view
	useEffect(() => {
		setLoading(true);
		setLogEntries({});
		setMetricsLookup({});
		lastIntervalStart.current = props.intervalStartDate;
		load();
		MyDataHelps.on("applicationDidBecomeVisible", load);
		MyDataHelps.on("externalAccountSyncComplete", load);
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", load);
			MyDataHelps.off("externalAccountSyncComplete", load);
		}
	}, [props.metrics, props.intervalStartDate]);

	var listDates: Date[] = [];
	var date = props.intervalStartDate;
	for (var i = 0; i < 7; i++) {
		listDates.push(date);
		date = add(date, { days: 1 });
	}

	var summarizedMetrics: MetricsSummaryResult = {
		averageFillPercent: 0,
		dailyMetrics: {}
	};
	if (!loading && lastIntervalStart.current == props.intervalStartDate) {
		summarizedMetrics = summarizeMetrics(listDates, metricsLookup, props.metrics);
	}

	function getDay(year: number, month: number, day: number, selectedWeek: boolean) {
		var date = new Date(year, month, day);
		var dayKey = getDayKey(date);
		if (!selectedWeek || loading || lastIntervalStart.current != props.intervalStartDate) {
			return <DeviceActivityCalendarDay
				key={getDayKey(date)}
				loading={loading}
				selected={dayKey == getDayKey(props.selectedDate)}
				averageFillPercent={summarizedMetrics.averageFillPercent}
				metricConfigurations={props.metrics}
				metricValues={{}}
				date={date}
				participantInfo={props.symptomSharkParticipantInfo!}
				selectedSymptoms={props.selectedSymptoms}
				selectedTreatments={props.selectedTreatments}
				highlightOutOfRange={props.highlightOutOfRange} />
		}
		return <DeviceActivityCalendarDay
			key={getDayKey(date)}
			loading={loading}
			selected={false}
			averageFillPercent={summarizedMetrics.averageFillPercent}
			metricConfigurations={props.metrics}
			metricValues={summarizedMetrics.dailyMetrics[dayKey]}
			date={date}
			logEntry={logEntries[getDayKey(date)]}
			participantInfo={props.symptomSharkParticipantInfo!}
			selectedSymptoms={props.selectedSymptoms}
			selectedTreatments={props.selectedTreatments}
			highlightOutOfRange={props.highlightOutOfRange} />
	}

	return (
		<div className="correlator">
			<div className="legend"><div className="average-marker-representation"></div> 30 day average</div>
			<WeekCalendar dayRenderer={getDay}
				selectedDate={props.selectedDate}
				loading={loading}
				startDate={props.intervalStartDate}
				onDateSelected={(d) => props.onDateSelected(d)}
				onStartDateChange={(d) => props.onIntervalChange(d)} />
			{!(loading || lastIntervalStart.current != props.intervalStartDate) &&
				<>
					<div className="title">
						{getDayOfWeek(props.selectedDate) + ", " + getFullDateString(props.selectedDate)}
					</div>
					{summarizedMetrics.dailyMetrics[getDayKey(props.selectedDate)] &&
						<Card>
							<DailyDeviceActivityMetrics key={props.selectedDate.getTime()}
								averageFillPercent={summarizedMetrics.averageFillPercent}
								metricConfigurations={props.metrics}
								metricValues={summarizedMetrics.dailyMetrics[getDayKey(props.selectedDate)]}
								highlightOutOfRange={props.highlightOutOfRange}
							/>
						</Card>
					}
					{props.symptomSharkParticipantInfo && props.symptomSharkParticipantInfo.symptoms.length > 0 &&
						<DeviceActivityLogEntry date={props.selectedDate}
							participantInfo={props.symptomSharkParticipantInfo}
							logEntry={logEntries[getDayKey(props.selectedDate)]}
							highlightedSymptoms={props.selectedSymptoms}
							highlightedTreatments={props.selectedTreatments}
							onDaySelected={(d) => props.onDaySelected(d)} />
					}
				</>
			}
		</div>
	)
}