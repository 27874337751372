import React, { useEffect } from 'react';
import { Layout } from '@careevolution/mydatahelps-ui';
import { QuestionEdit } from '../../components';

export interface QuestionEditViewProps {
	colorScheme?: "light" | "dark" | "auto";
	onVisit?(): void;
}

export default function (props: QuestionEditViewProps) {
	var search = new URLSearchParams(window.location.search);

	useEffect(() => {
		props.onVisit?.();
	}, []);

	return (
		<Layout className="question-edit-view symptom-shark-view" colorScheme={props.colorScheme || "light"} bodyBackgroundColor="var(--mdhui-background-color-0)" >
			<QuestionEdit date={search.get("questionDate") ? search.get("questionDate")! : undefined} />
		</Layout>
	);
}