import React, { useEffect, useState } from 'react';
import "./Hero.css"
import Wave from '../Wave/Wave';
import { DayTrackerSymbol, TrackerItem, useInterval } from '@careevolution/mydatahelps-ui';
import { DailyLogEntry, SymptomConfiguration, SymptomSharkParticipantInfo, TreatmentConfiguration } from '../../../services/typed_data';
import { demoSymptoms, demoTreatments } from '../DemoData';

export default function () {
	var defaultLogEntry = {
		symptoms: [],
		treatments: [],
		notes: "",
		icon: "",
		overallFeeling: 4
	};

	const [logEntry, setLogEntry] = useState<DailyLogEntry>(defaultLogEntry);
	const [state, setState] = useState<"Symptoms" | "Treatments">("Symptoms")

	const [animationStep, setAnimationStep] = useState<number>(0);

	var symptoms: SymptomConfiguration[] = demoSymptoms;
	var treatments: TreatmentConfiguration[] = demoTreatments;

	function getDayTracker() {
		var primaryColors = logEntry.symptoms.map(t => symptoms.find(s => s.id == t.id)?.color).filter(t => !!t).map(t => t!);
		var secondaryColors = logEntry.treatments.map(t => treatments.find(s => s.id == t.id)?.color).filter(t => !!t).map(t => t!);
		return <DayTrackerSymbol className="scaled-up-tracker" key={primaryColors.join('|') + ',' + secondaryColors.join('|')} primaryColors={primaryColors} secondaryColors={secondaryColors} />
	}

	function selectRandomTreatment() {
		var allTreatments = treatments.filter(s => !logEntry.treatments.find(s2 => s2.id == s.id));
		var randomIndex = Math.floor(Math.random() * allTreatments.length);
		var newLogEntry = { ...logEntry };
		newLogEntry.treatments = [...newLogEntry.treatments];
		newLogEntry.treatments.push({ id: allTreatments[randomIndex].id });
		setLogEntry(newLogEntry);
	}

	function selectRandomSymptom() {
		var allSymptoms = symptoms.filter(s => !logEntry.symptoms.find(s2 => s2.id == s.id));
		var randomIndex = Math.floor(Math.random() * allSymptoms.length);
		var newLogEntry = { ...logEntry };
		newLogEntry.treatments = [...newLogEntry.treatments];
		newLogEntry.symptoms.push({ id: allSymptoms[randomIndex].id });
		setLogEntry(newLogEntry);
	}

	function advanceAnimation() {
		if (animationStep == 10) {
			selectRandomSymptom();
		}
		if (animationStep == 15) {
			selectRandomSymptom();
		}
		if (animationStep == 24) {
			selectRandomSymptom();
		}
		if (animationStep == 38) {
			selectRandomSymptom();
		}

		if (animationStep == 50) {
			setState("Treatments");
		}

		if (animationStep == 70) {
			selectRandomTreatment();
		}
		if (animationStep == 82) {
			selectRandomTreatment();
		}
		if (animationStep == 92) {
			selectRandomTreatment();
		}

		if (animationStep == 110) {
			setAnimationStep(0);
			setLogEntry(defaultLogEntry);
			setState("Symptoms")
		}
		else {
			setAnimationStep(animationStep + 1);
		}
	}

	useInterval(advanceAnimation, 100);

	return (
		<div className="hero-wrapper">
			<img className="hero-spice" src='/images/hero-spice.png' />
			<div className="hero">
				<div className="hero-image">
					<div className="canvas">
						<div className="surface">
							<div className="surface-content">
								<div className="log-entry-edit">
									<div style={{ width: "100%", overflow: "hidden" }}>
										{getDayTracker()}
									</div>
									{state == "Symptoms" &&
										<div className="fadein-block">
											<h3>What symptoms are you experiencing?</h3>
											<div className="items">
												{symptoms.map(s =>
													<TrackerItem className="item" selected={!!logEntry.symptoms.find(s2 => s2.id == s.id)}
														color={s.color}
														text={s.name}
														key={s.id}
														onClick={() => { }} />
												)}
											</div>
										</div>
									}
									{state == "Treatments" &&
										<div className="fadein-block">
											<h3>What treatments have you performed?</h3>
											<div className="items">
												{treatments.map(s =>
													<TrackerItem className="item" selected={!!logEntry.treatments.find(s2 => s2.id == s.id)}
														color={s.color}
														text={s.name}
														key={s.id}
														onClick={() => { }} />
												)}
											</div>
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="hero-content">
					<h1>Advocating for Your Health Made Easy</h1>
					<div className="tagline">Symptom Shark is a simple and free daily symptom tracker</div>
					<div className="tagline smaller">
						Download <strong>MyDataHelps</strong> and select <strong>Symptom Shark</strong> to get started
					</div>
					<div className="app-download">
						<a href="https://apps.apple.com/us/app/mydatahelps/id1286789190"><img src="images/download-apple.svg" /></a>
						<a href="https://play.google.com/store/apps/details?id=com.careevolution.mydatahelps"><img src="images/download-android.svg" /></a>
						<a className="online-signup" href="https://mydatahelps.org/identityserver/local/participant?projectCode=SHARKS">Sign Up Online</a>
						<div style={{ clear: "both" }} />
					</div>
				</div>
			</div>
			<Wave reverseX={false} reverse={true} />
		</div>
	);
}