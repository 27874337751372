import React from 'react';
import "./SymptomSharkLogo.css";

export function SymptomSharkLogo() {
    return (
        <div className="ss-logo">
            <img src="images/ss_new.png" />
            <div className="product-name">Symptom Shark</div>
        </div>
    );
}