import React, { useState, useEffect, useMemo, useRef } from 'react';
import MyDataHelps from "@careevolution/mydatahelps-js"
import symptomSharkData, { QuestionForDoctor } from '../../../services/typed_data';
import { Button, LoadingIndicator, UnstyledButton } from '@careevolution/mydatahelps-ui';
import "./QuestionEdit.css"
import { formatISO } from 'date-fns';
import _ from "underscore";
import language from '../../../helpers/language';

export interface QuestionEditProps {
	date?: string;
}

export default function (props: QuestionEditProps) {
	const [question, setQuestion] = useState<QuestionForDoctor | null>(null);
	const [saving, setSaving] = useState<boolean>(false);
	const answerInput = useRef<HTMLTextAreaElement>(null);
	const questionInput = useRef<HTMLTextAreaElement>(null);

	function initialize() {
		if (props.date) {
			symptomSharkData.getQuestionForDoctor(props.date).then(function (question) {
				setQuestion(question);
			});
		} else {
			setQuestion({
				date: formatISO(new Date()),
				question: "",
				answer: ""
			});
		}
	}

	useEffect(() => {
		initialize();
	}, []);

	useEffect(() => {
		if (questionInput.current && answerInput.current) {
			questionInput.current!.style.height = "1px";
			questionInput.current!.style.height = questionInput.current!.scrollHeight + "px";
			answerInput.current!.style.height = "1px";
			answerInput.current!.style.height = answerInput.current!.scrollHeight + "px";
		}
	}, [question]);

	function saveQuestion(question: QuestionForDoctor) {
		setSaving(true);
		return symptomSharkData.saveQuestion(question).then(function () {
			setSaving(false);
		});
	}

	const debouncedSave = useMemo(
		() => _.debounce(saveQuestion, 300)
		, []);

	function updateQuestionText(text: string) {
		var newEntry = { ...question! };
		newEntry.question = text.replace(/(\r\n|\n|\r)/gm, "");
		setQuestion(newEntry);
		debouncedSave(newEntry);
	}

	function updateAnswerText(text: string) {
		var newEntry = { ...question! };
		newEntry.answer = text;
		setQuestion(newEntry);
		debouncedSave(newEntry);
	}

	function deleteQuestion() {
		var newEntry = { ...question! };
		newEntry.answer = "";
		newEntry.question = "";
		saveQuestion(newEntry).then(function () {
			back();
		});
	}

	function handleKeyUp(keyCode: number) {
		if (keyCode === 13) {
			answerInput.current?.focus();
			questionInput.current!.style.height = "1px";
			questionInput.current!.style.height = questionInput.current!.scrollHeight + "px";
		}
	}

	var back = function () {
		if (saving) {
			return;
		}
		MyDataHelps.back();
	};

	return (
		<div className="question-edit">
			<div className="question-edit-header">
				<div className="nav-wrapper">
					<a href="javascript:{}" onClick={() => back()} className="back-button"><i className="fa fa-chevron-left"></i> {language["back"]}</a>
					{language["question-for-doctor"]}
					<UnstyledButton title="Delete Question" className="delete" onClick={() => deleteQuestion()}>
						<i className="fa fa-trash"></i>
					</UnstyledButton>
				</div>
			</div>
			{!question &&
				<LoadingIndicator />
			}
			{question &&
				<>
					<div className="question-text">
						<label htmlFor="question">{language["question"]}</label>
						<textarea id="question" title={language["question"]} ref={questionInput} onChange={(e) => updateQuestionText(e.target.value)} value={question.question} placeholder={language["question-placeholder"]} onKeyUp={(e) => { handleKeyUp(e.keyCode); e.stopPropagation() }}></textarea>
					</div>
					<div className="answer-text">
						<label htmlFor="answer">{language["answer"]}</label>
						<textarea id="answer" ref={answerInput} onChange={(e) => updateAnswerText(e.target.value)} value={question.answer ? question.answer : ""} placeholder={language["answer-placeholder"]}></textarea>
					</div>
				</>
			}
			<div className="button-wrapper">
				<Button onClick={() => back()}>{language["done"]}</Button>
			</div>
		</div>
	);
}