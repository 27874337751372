import React, { useState, useEffect } from 'react';
import MyDataHelps from "@careevolution/mydatahelps-js"
import symptomSharkData, { DailyLogEntry, SymptomConfiguration, SymptomSharkParticipantInfo } from '../../services/typed_data';
import { DateRangeNavigator, Layout, LoadingIndicator, NavigationBar, TextBlock } from '@careevolution/mydatahelps-ui';
import { add } from 'date-fns';
import getDayKey from '../../helpers/get-day-key';
import { SymptomSeverityChart, SymptomSeveritySummary } from '../../components';
import "./SymptomDetailView.css"
import language from '../../helpers/language';

export interface SymptomDetailViewProps {
	colorScheme?: "light" | "dark" | "auto";
	onVisit?(symptom: string): void;
}

export default function (props: SymptomDetailViewProps) {
	var currentDate = new Date();
	currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0);
	var initialIntervalStart = currentDate;
	while (initialIntervalStart.getDate() != 1) {
		initialIntervalStart = add(initialIntervalStart, { days: -1 });
	}
	var search = new URLSearchParams(window.location.search);
	if (search.get("year") && search.get("month")) {
		initialIntervalStart = new Date(parseInt(search.get("year")!), parseInt(search.get("month")!), 1);
	}
	const [loading, setLoading] = useState(true);
	const [participantInfo, setParticipantInfo] = useState<SymptomSharkParticipantInfo | null>(null);
	const [logEntries, setLogEntries] = useState<{ [key: string]: DailyLogEntry }>({});
	const [intervalStartDate, setIntervalStartDate] = useState(initialIntervalStart);

	function initialize() {
		symptomSharkData.getParticipantInfo().then(function (participantInfo) {
			symptomSharkData.getDailyLogEntries().then(function (logEntries) {
				setParticipantInfo(participantInfo);
				setLogEntries(logEntries);
				setLoading(false);
				props.onVisit?.(getSymptom()!.name);
			});
		});
	}

	function getSymptom() {
		var symptomId = search.get("symptom");
		if (!symptomId) {
			symptomId = participantInfo!.symptoms[0].id;
		}
		return participantInfo!.symptoms.find(s => s.id == symptomId);
	}

	useEffect(() => {
		initialize();
		MyDataHelps.on("applicationDidBecomeVisible", initialize);
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", initialize);
		}
	}, []);

	var hasAnyData = false;
	var symptom: SymptomConfiguration | undefined;
	if (participantInfo) {
		symptom = getSymptom();
		if (symptom) {
			var startDate = intervalStartDate;
			while (startDate.getMonth() == intervalStartDate.getMonth()) {
				var logEntry = logEntries[getDayKey(startDate)];
				if (logEntry && logEntry.symptoms.find(s => s.id == symptom!.id && s.severity)) {
					hasAnyData = true;
				}
				startDate = add(startDate, { days: 1 });
			}
		}
	}

	return (
		<Layout colorScheme={props.colorScheme || "light"} className="symptom-detail-view symptom-shark-view">
			<NavigationBar className="symptom-detail-navbar" title={symptom ? symptom.name : language["loading"]} showCloseButton={true}>
				<DateRangeNavigator className="symptom-detail-navigator" variant="rounded" intervalStart={intervalStartDate} intervalType="Month" onIntervalChange={(d) => setIntervalStartDate(d)} />
			</NavigationBar>
			{loading &&
				<LoadingIndicator />
			}
			{!loading && symptom && hasAnyData &&
				<>
					<SymptomSeveritySummary symptom={symptom} currentMonth={intervalStartDate.getMonth()} currentYear={intervalStartDate.getFullYear()} logEntries={logEntries} />
					<SymptomSeverityChart symptom={symptom} currentMonth={intervalStartDate.getMonth()} currentYear={intervalStartDate.getFullYear()} logEntries={logEntries} />
				</>
			}
			{!loading && !hasAnyData &&
				<TextBlock>
					No severity data for this month
				</TextBlock>
			}
		</Layout>
	);
}