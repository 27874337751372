import React from "react";
import "./SymptomSeveritySummary.css"
import { DailyLogEntry, SymptomConfiguration } from "../../../services/typed_data";
import getDayKey from "../../../helpers/get-day-key";
import { getDatesForMonth } from "../../../helpers/date-helpers";
import language from "../../../helpers/language";

interface SymptomSeveritySummaryProps {
	symptom: SymptomConfiguration;
	logEntries: { [key: string]: DailyLogEntry };
	currentMonth: number;
	currentYear: number;
}

export default function (props: SymptomSeveritySummaryProps) {
	var monthDays = getDatesForMonth(props.currentYear, props.currentMonth);

	var symptomSeverity = function (date: Date) {
		var day = getDayKey(date);
		if (props.logEntries[day]) {
			var matchingSymptom = props.logEntries[day].symptoms.find((s) => props.symptom.id == s.id);
			if (matchingSymptom && matchingSymptom.severity) {
				return matchingSymptom.severity;
			}
		}
		return 0;
	}

	var daysMatchingScore = function (minScore: number, maxScore: number) {
		var result = 0;
		for (var i = 0; i < monthDays.length; i++) {
			var severity = symptomSeverity(monthDays[i]);
			if (severity >= minScore && severity <= maxScore) {
				result++;
			}
		}
		return result;
	}

	if (props.symptom.severityTracking != '3PointScale') {
		return null;
	}

	return <div className="symptom-severity-summary">
		<div className="severity">
			<div className="severity-label">{language["severe"]}</div>
			<div className="severity-count">{daysMatchingScore(8, 10)}</div>
		</div>
		<div className="severity">
			<div className="severity-label">{language["moderate"]}</div>
			<div className="severity-count">{daysMatchingScore(4, 7)}</div>
		</div>
		<div className="severity">
			<div className="severity-label">{language["mild"]}</div>
			<div className="severity-count">{daysMatchingScore(1, 3)}</div>
		</div>
		<div style={{ clear: "both" }}></div>
	</div>;
}