import $ from 'jquery'
import "./core.css"
import React from 'react';
import ReactDOM from 'react-dom';
import MyDataHelps from "@careevolution/mydatahelps-js";
import { SetupStep } from './components';
import getDayKey from './helpers/get-day-key';
import symptomSharkData from './services/typed_data';
import { CalendarView, DayView, DeviceActivityView, LogView, PacingNotificationsView, QuestionEditView, ReportsView, SettingsView, SymptomDetailView } from './views';
import language from './helpers/language';
import HomePage from './components/Website/HomePage/HomePage';
import StoryPage from './components/Website/StoryPage/StoryPage';
import PacingNotificationsPage from './components/Website/PacingNotificationsPage/PacingNotificationsPage';
import FAQsPage from './components/Website/FAQsPage/FAQsPage';
import { StandaloneSetupStep } from './components/Container/SetupStep/SetupStep';

if (process.env.NODE_ENV === 'development') {
	if (location.hostname === "localhost") {
		MyDataHelps.setParticipantAccessToken({ "access_token": "5b32324a80599d99de9fdf44b3bbd8fb", "expires_in": 21600, "token_type": "Bearer" }, "https://mydatahelps.org/");
	}
}

function selectDay(date) {
	MyDataHelps.openApplication(new URL("day.html?date=" + getDayKey(date), document.location).href);
}

function selectQuestion(questionDateString) {
	var url = "question.html";
	if (questionDateString) {
		url += "?questionDate=" + questionDateString;
	}
	MyDataHelps.openApplication(new URL(url, document.location).href);
}

function setupElement(selector, element, autoStatusBar) {
	if ($(selector).length) {
		ReactDOM.render(
			element,
			$(selector)[0]
		);

		if (autoStatusBar) {
			MyDataHelps.setStatusBarStyle("default");
		}
	}

}

setupElement(".log-view",
	<LogView onDaySelected={(d) => selectDay(d)}
		onQuestionSelected={(q) => selectQuestion(q)}
		onVisit={() => symptomSharkData.trackViewVisit("Log")}
		colorScheme="auto" />,
	true
)

setupElement(".calendar-view",
	<CalendarView
		onDaySelected={(d) => selectDay(d)}
		onSymptomSelected={(s, y, m) => MyDataHelps.openApplication(new URL("symptom-detail.html?month=" + m + "&year=" + y + "&symptom=" + s, document.location).href, { modal: true })}
		onVisit={() => symptomSharkData.trackViewVisit("Calendar")}
		colorScheme="auto" />,
	true
)

setupElement(".device-activity-view",
	<DeviceActivityView onDaySelected={(d) => selectDay(d)}
		onVisit={() => symptomSharkData.trackViewVisit("Activity")}
		appleHealthConnectionSurvey="EnableAppleHealth"
		colorScheme="auto" />,
	true
)

setupElement(".reports-view",
	<ReportsView colorScheme='auto' productLogo="https://symptomshark.org/app/images/ss_new.png"
		productName="Symptom Shark"
		productUrl="www.symptomshark.org"
		onVisit={() => symptomSharkData.trackViewVisit("Reports")} />,
	true
)

setupElement(".settings-view",
	<SettingsView
		onVisit={() => symptomSharkData.trackViewVisit("Settings")}
		colorScheme="auto" />,
	true
)

setupElement(".day-view",
	<DayView
		onVisit={(d) => symptomSharkData.trackViewVisit("Day", { "Day": d })}
		colorScheme="auto" />,
	true
)

setupElement(".symptom-detail-view",
	<SymptomDetailView
		onVisit={(d) => symptomSharkData.trackViewVisit("SymptomDetail", { "Symptom": d })}
		colorScheme="auto" />,
	true
)

setupElement(".pacing-notifications-view",
	<PacingNotificationsView
		onVisit={() => symptomSharkData.trackViewVisit("PacingNotifications")}
		colorScheme="auto" />,
	true
)

setupElement(".question-edit-view",
	<QuestionEditView
		onVisit={() => symptomSharkData.trackViewVisit("QuestionEdit")}
		colorScheme="auto" />
	, true
)

setupElement(".symptom-setup-initial",
	<SetupStep
		title={language["symptom-setup-intro"]}
		description={language["symptom-setup-description"]}
		doneButtonText={language["symptom-setup-next"]}
		initialSetup={true}
		requireItems={true}
		inputPlaceholder={language["symptom-setup-placeholder"]}
		stepIdentifier="Symptoms"
		concept="Symptoms" />
)

setupElement(".symptom-edit",
	<SetupStep
		title={language["symptom-setup-intro"]}
		description={language["symptom-edit-description"]}
		doneButtonText={language["symptom-edit-next"]}
		initialSetup={false}
		requireItems={true}
		inputPlaceholder={language["symptom-setup-placeholder"]}
		concept="Symptoms" />
)


setupElement(".treatment-setup-initial",
	<SetupStep
		title={language["treatment-setup-intro"]}
		description={language["treatment-setup-description"]}
		doneButtonText={language["treatment-setup-next"]}
		initialSetup={true}
		requireItems={false}
		inputPlaceholder={language["treatment-setup-placeholder"]}
		stepIdentifier="Treatments"
		concept="Treatments" />
)

setupElement(".treatment-edit",
	<SetupStep
		title={language["treatment-setup-intro"]}
		description={language["treatment-edit-description"]}
		doneButtonText={language["treatment-edit-next"]}
		initialSetup={false}
		requireItems={false}
		inputPlaceholder={language["treatment-setup-placeholder"]}
		concept="Treatments" />
)

setupElement(".website-home",
	<HomePage />
)

setupElement(".website-story",
	<StoryPage />
)

setupElement(".website-pacing-notifications",
	<PacingNotificationsPage />
)

setupElement(".website-faqs",
	<FAQsPage />
)

setupElement(".symptom-setup-standalone",
	<StandaloneSetupStep
		title={language["symptom-setup-intro"]}
		description={language["symptom-setup-description"]}
		doneButtonText={language["next"]}
		initialSetup={true}
		requireItems={true}
		inputPlaceholder={language["symptom-setup-placeholder"]}
		stepIdentifier="Symptoms"
		concept="Symptoms" />
)

setupElement(".treatment-setup-standalone",
	<StandaloneSetupStep
		title={language["treatment-setup-intro"]}
		description={language["treatment-setup-description"]}
		doneButtonText={language["next"]}
		initialSetup={true}
		requireItems={false}
		inputPlaceholder={language["treatment-setup-placeholder"]}
		stepIdentifier="Treatments"
		concept="Treatments" />
)