import React from "react";
import { DailyLogEntry, SymptomConfiguration, TreatmentConfiguration } from "../../../services/typed_data";
import getDayKey from "../../../helpers/get-day-key";
import "./BulletChart.css"
import { getDatesForMonth } from "../../../helpers/date-helpers";
import language from "../../../helpers/language";

interface BulletChartProps {
	logEntries: { [key: string]: DailyLogEntry };
	symptoms: SymptomConfiguration[];
	treatments: TreatmentConfiguration[];
	currentMonth: number;
	currentYear: number;
}

export default function (props: BulletChartProps) {
	if (!props.symptoms.length && !props.treatments.length) {
		return null;
	}

	var monthDays: Date[] = getDatesForMonth(props.currentYear, props.currentMonth);

	var symptomSelected = function (date: Date, symptomId: string) {
		var key = getDayKey(date);
		if (!props.logEntries[key]) {
			return false;
		}
		return props.logEntries[key].symptoms.find((s) => s.id == symptomId);
	}

	var treatmentSelected = function (date: Date, treatmentId: string) {
		var key = getDayKey(date);
		if (!props.logEntries[key]) {
			return false;
		}
		return props.logEntries[key].treatments.find((s) => s.id == treatmentId);
	}

	var symptomTotal = function (symptom: SymptomConfiguration) {
		var total = 0;
		for (var i = 0; i < monthDays.length; i++) {
			if (symptomSelected(monthDays[i], symptom.id)) {
				total++;
			}
		}
		return total;
	}

	var treatmentTotal = function (treatment: TreatmentConfiguration) {
		var total = 0;
		for (var i = 0; i < monthDays.length; i++) {
			if (treatmentSelected(monthDays[i], treatment.id)) {
				total++;
			}
		}
		return total;
	}

	return <div className="bullet-chart">
		{props.symptoms.length > 0 &&
			<table cellPadding="0" cellSpacing="0">
				<thead>
					<tr>
						<th colSpan={100}>{language["symptoms"]}</th>
					</tr>
					<tr>
						<td className="item-name"></td>
						<td></td>
						{monthDays.map((m) =>
							<td key={m.toISOString()} style={{ width: (75 / monthDays.length) + '%' }}>
								<div className="day-number">{m.getDate()}</div>
							</td>
						)}
					</tr>
				</thead>
				<tbody>
					{props.symptoms.map((s) =>
						<tr>
							<td className="item-name">{s.name} </td>
							<td className="total">
								<span>{symptomTotal(s) > 0 ? symptomTotal(s) : <>&nbsp;</>}</span>
							</td>
							{monthDays.map((m) =>
								<td key={m.toISOString()} style={{ width: (75 / monthDays.length) + '%' }}>
									<div className="circle" style={{ backgroundColor: symptomSelected(m, s.id) ? s.color : '#ddd' }}>
									</div>
								</td>
							)}
						</tr>
					)}
				</tbody>
			</table>
		}
		{props.treatments.length > 0 &&
			<table cellPadding="0" cellSpacing="0">
				<thead>
					<tr>
						<th colSpan={100} style={{ paddingTop: '16px' }}>{language["treatments"]}</th>
					</tr>
					<tr>
						<td className="item-name"></td>
						<td></td>
						{monthDays.map((m) =>
							<td key={m.toISOString()} style={{ width: (75 / monthDays.length) + '%' }}>
								<div className="day-number">{m.getDate()}</div>
							</td>
						)}
					</tr>
				</thead>
				<tbody>
					{props.treatments.map((t) =>
						<tr>
							<td className="item-name">{t.name}</td>
							<td className="total">
								{treatmentTotal(t) > 0 ? treatmentTotal(t) : <>&nbsp;</>}
							</td>
							{monthDays.map((m) =>
								<td key={m.toISOString()} style={{ width: (75 / monthDays.length) + '%' }}>
									<div className="circle" style={{ backgroundColor: treatmentSelected(m, t.id) ? t.color : '#ddd' }}></div>
								</td>
							)}
						</tr>
					)}
				</tbody>
			</table>
		}
	</div>;
}