import React, { useEffect, useState } from 'react'
import { Button, Layout, LoadingIndicator, NavigationBar, Section, TextBlock } from "@careevolution/mydatahelps-ui"
import MyDataHelps, { ExternalAccount } from "@careevolution/mydatahelps-js"
import getFitbitProviderID from '../../helpers/get-fitbit-provider-id';
import { faHeartbeat, faMobileScreen, faPersonRunning, faSms } from '@fortawesome/free-solid-svg-icons';
import symptomSharkData, { PacingNotificationsConfiguration, pacingNotificationType, SymptomSharkParticipantInfo } from '../../services/typed_data';
import { PacingNotificationConfiguration } from '../../components';
import language from '../../helpers/language';

export interface PacingNotificationsViewProps {
	colorScheme?: "light" | "dark" | "auto";
	onVisit?(): void;
}

export default function (props: PacingNotificationsViewProps) {
	const [loading, setLoading] = useState(true);
	const [fitbitExternalAccount, setFitbitExternalAccount] = useState<ExternalAccount | null>(null);
	const [participantInfo, setParticipantInfo] = useState<SymptomSharkParticipantInfo | null>(null);
	const [platform, setPlatform] = useState<string | null>(null);
	const [configuration, setConfiguration] = useState<PacingNotificationsConfiguration | null>(null);

	function load() {
		MyDataHelps.getDeviceInfo().then(function (info) {
			setPlatform(info?.platform ?? 'iOS');

			symptomSharkData.getPacingNotificationsConfiguration().then(function (config) {
				setConfiguration(config);

				MyDataHelps.getExternalAccounts().then(function (accounts) {
					for (let i = 0; i < accounts.length; i++) {
						if (accounts[i].provider.id == getFitbitProviderID()) {
							setFitbitExternalAccount(accounts[i]);
						}
					}
					symptomSharkData.getParticipantInfo().then(function (info) {
						setParticipantInfo(info);
						setLoading(false);
					});
				});
			});
		});
	}

	useEffect(() => {
		load();
		props.onVisit?.();
		MyDataHelps.on("applicationDidBecomeVisible", load);
		MyDataHelps.on("externalAccountSyncComplete", load);
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", load);
			MyDataHelps.off("externalAccountSyncComplete", load);
		}
	}, []);

	function setHeartRateLimit(limit: number) {
		if (!configuration) { return; }
		var newConfig: PacingNotificationsConfiguration = { notificationsEnabled: [...configuration.notificationsEnabled], appleHealthHeartRateLimit: configuration.appleHealthHeartRateLimit, sendSms: configuration.sendSms, sendPush: configuration.sendPush };
		newConfig.appleHealthHeartRateLimit = limit;
		setConfiguration(newConfig);
		symptomSharkData.updatePacingNotificationsConfiguration(newConfig);
	}

	function toggleNotification(type: pacingNotificationType) {
		if (!configuration) { return; }
		var newConfig: PacingNotificationsConfiguration = { notificationsEnabled: [...configuration.notificationsEnabled], appleHealthHeartRateLimit: configuration.appleHealthHeartRateLimit, sendSms: configuration.sendSms, sendPush: configuration.sendPush };
		var index = newConfig.notificationsEnabled.indexOf(type);
		if (index == -1) {
			newConfig.notificationsEnabled.push(type);
			MyDataHelps.trackCustomEvent({
				eventType: "PacingNotificationEnabled",
				properties: {
					"Notification": type as any
				}
			}).catch();
		} else {
			newConfig.notificationsEnabled.splice(index, 1);
			MyDataHelps.trackCustomEvent({
				eventType: "PacingNotificationDisabled",
				properties: {
					"Notification": type as any
				}
			}).catch();
		}
		setConfiguration(newConfig);
		symptomSharkData.updatePacingNotificationsConfiguration(newConfig);
	}

	function toggleSms() {
		if (!configuration || !participantInfo?.phoneNumber) { return; }
		var newConfig: PacingNotificationsConfiguration = { notificationsEnabled: [...configuration.notificationsEnabled], appleHealthHeartRateLimit: configuration.appleHealthHeartRateLimit, sendSms: !configuration.sendSms, sendPush: configuration.sendPush };
		setConfiguration(newConfig);
		symptomSharkData.updatePacingNotificationsConfiguration(newConfig);
	}

	function togglePush() {
		if (!configuration) { return; }
		var newConfig: PacingNotificationsConfiguration = { notificationsEnabled: [...configuration.notificationsEnabled], appleHealthHeartRateLimit: configuration.appleHealthHeartRateLimit, sendSms: configuration.sendSms, sendPush: !configuration.sendPush };
		setConfiguration(newConfig);
		symptomSharkData.updatePacingNotificationsConfiguration(newConfig);
	}

	var popup = false;
	if (new URLSearchParams(window.location.search).get("popup") == "true") {
		popup = true;
	}

	return (
		<Layout className="pacing-notifications-view symptom-shark-view" colorScheme={props.colorScheme || "light"}>
			<NavigationBar showCloseButton={true} title={language["pacing-notifications"]} />
			<TextBlock>
				{!loading && !participantInfo?.appleHealthEnabled && !fitbitExternalAccount &&
					<>
						{(platform == 'iOS') &&
							<p><strong>{language["pacing-notifications-intro-ios"]}</strong></p>
						}
						{!(platform == 'iOS') &&
							<p><strong>{language["pacing-notifications-intro"]}</strong></p>
						}
					</>
				}
				<p>{language["long-covid-can-cause"]} <a href="javascript:{}" onClick={() => MyDataHelps.openEmbeddedUrl("https://www.cdc.gov/me-cfs/healthcare-providers/clinical-care-patients-mecfs/treating-most-disruptive-symptoms.html#anchor_1528128659388")}>{language["post-exertional-malaise"]}</a>.</p>
				<p dangerouslySetInnerHTML={{ __html: language["pem-intro"] }}></p>
				<p>{language["pacing-notifications-intro-2"]}</p>
				{!loading && !participantInfo?.appleHealthEnabled && !fitbitExternalAccount &&
					<p>{platform == 'iOS' ? language["pacing-notifications-get-started-ios"] : language["pacing-notifications-get-started"]}</p>
				}
				{loading &&
					<LoadingIndicator />

				}
				{!loading && (participantInfo?.appleHealthEnabled || fitbitExternalAccount) &&
					<p><span dangerouslySetInnerHTML={{ __html: language["pacing-notifications-troubleshooting"] }} /> <a href="javascript:{}" onClick={() => MyDataHelps.openEmbeddedUrl(new URL("../faqs.html?hideNav=true#pacing", document.location as any).href)}>{language["learn-more"]}</a> </p>
				}
			</TextBlock>
			{!loading && configuration && participantInfo &&
				<>
					<Section>
						{(participantInfo?.appleHealthEnabled || fitbitExternalAccount) &&
							<PacingNotificationConfiguration
								icon={faPersonRunning}
								color="#f5b722" title={language["steps"]}
								description={language["pacing-notification-steps"]}
								enabled={configuration.notificationsEnabled.indexOf("steps") != -1}
								onChange={() => toggleNotification("steps")} />
						}
						{participantInfo?.appleHealthEnabled &&
							<PacingNotificationConfiguration
								icon={faHeartbeat}
								color="#e35c33"
								title={language["heart-rate"] + " - Apple Health"}
								description={language["pacing-notification-heart-rate"]}
								enabled={configuration.notificationsEnabled.indexOf("appleHealthHeartRate") != -1}
								onChange={() => toggleNotification("appleHealthHeartRate")} >
								{configuration.notificationsEnabled.indexOf("appleHealthHeartRate") != -1 &&
									<div className="heartrate-threshold">
										<div className="heartrate-threshold-title">{language["notify-when-above"]}</div>
										<select style={{ fontSize: "1.3em" }} value={configuration.appleHealthHeartRateLimit.toString()} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setHeartRateLimit(parseInt(e.target.value))}>
											<option value="60">60 {language["beats-per-minute"]}</option>
											<option value="65">65 {language["beats-per-minute"]}</option>
											<option value="70">70 {language["beats-per-minute"]}</option>
											<option value="75">75 {language["beats-per-minute"]}</option>
											<option value="80">80 {language["beats-per-minute"]}</option>
											<option value="85">85 {language["beats-per-minute"]}</option>
											<option value="90">90 {language["beats-per-minute"]}</option>
											<option value="95">95 {language["beats-per-minute"]}</option>
											<option value="100">100 {language["beats-per-minute"]}</option>
											<option value="105">105 {language["beats-per-minute"]}</option>
											<option value="110">110 {language["beats-per-minute"]}</option>
											<option value="115">115 {language["beats-per-minute"]}</option>
											<option value="120">120 {language["beats-per-minute"]}</option>
											<option value="125">125 {language["beats-per-minute"]}</option>
											<option value="130">130 {language["beats-per-minute"]}</option>
											<option value="135">135 {language["beats-per-minute"]}</option>
											<option value="140">140 {language["beats-per-minute"]}</option>
											<option value="145">145 {language["beats-per-minute"]}</option>
											<option value="150">150 {language["beats-per-minute"]}</option>
											<option value="155">155 {language["beats-per-minute"]}</option>
											<option value="160">160 {language["beats-per-minute"]}</option>
										</select>
									</div>
								}
							</PacingNotificationConfiguration>
						}
						{fitbitExternalAccount &&
							<>
								<PacingNotificationConfiguration
									icon={faHeartbeat}
									color="#e35c33"
									title={language["elevated-heart-rate"] + " - Fitbit"}
									description={language["pacing-notification-elevated-heart-rate"]}
									enabled={configuration.notificationsEnabled.indexOf("fitbitElevatedHeartRate") != -1}
									onChange={() => toggleNotification("fitbitElevatedHeartRate")} />
								<PacingNotificationConfiguration
									icon={faPersonRunning}
									color="#f5b722"
									title={language["calories-burned"] + " - Fitbit"}
									description={language["pacing-notification-calories"]}
									enabled={configuration.notificationsEnabled.indexOf("fitbitCaloriesBurned") != -1}
									onChange={() => toggleNotification("fitbitCaloriesBurned")} />
							</>
						}
					</Section>
					<TextBlock>
					</TextBlock>
					<Section>
						{(fitbitExternalAccount || participantInfo?.appleHealthEnabled) &&
							<PacingNotificationConfiguration
								icon={faSms}
								color="#429bdf"
								title={language["send-via-sms"]}
								description={!participantInfo?.phoneNumber ? language["send-via-sms-no-phone"] : language["send-via-sms-with-phone"] + participantInfo.phoneNumber + ". " + language["message-rates-apply"]}
								enabled={configuration.sendSms}
								onChange={() => toggleSms()} />
						}
						{(fitbitExternalAccount || participantInfo?.appleHealthEnabled) && platform != 'Web' &&
							<PacingNotificationConfiguration
								icon={faMobileScreen}
								color="#429bdf"
								title={language["send-via-push"]}
								description={language["send-via-push-description"]}
								enabled={configuration.sendPush}
								onChange={() => togglePush()} />
						}
					</Section>
					<TextBlock>
						<div className="connect-buttons">
							{!participantInfo?.appleHealthEnabled && (platform == 'iOS') &&
								<Button variant="subtle" onClick={() => MyDataHelps.startSurvey("EnableAppleHealth")}>{language["apple-health-connect-button"]}</Button>
							}
							{!fitbitExternalAccount &&
								<Button variant="subtle" onClick={() => MyDataHelps.connectExternalAccount(getFitbitProviderID())}>{language["fitbit-connect-button"]}</Button>
							}
						</div>
						<Button onClick={() => MyDataHelps.dismiss()}>{language["done"]}</Button>
						{popup &&
							<p>{language["adjust-notifications-later"]}</p>
						}
					</TextBlock>
				</>
			}
		</Layout>
	)
}