import React, { useState } from "react";
import { Card, Face, ShinyOverlay, UnstyledButton } from "@careevolution/mydatahelps-ui";
import "./LogEntry.css"
import symptomSharkData, { DailyLogEntry, SymptomSharkParticipantInfo } from "../../../services/typed_data";
import getDayKey from "../../../helpers/get-day-key";
import IconSelector from "../IconSelector/IconSelector";
import LogEntryContent from "../LogEntryContent/LogEntryContent";
import { useEffect } from "react";
import { getDayOfWeek, getFullDateString } from "../../../helpers/date-helpers";
import language from "../../../helpers/language";

interface LogEntryProps {
	date: Date;
	logEntry?: DailyLogEntry;
	participantInfo: SymptomSharkParticipantInfo;
	onDaySelected(d: Date): void;
}

export default function (props: LogEntryProps) {
	const [selectedIcon, setSelectedIcon] = useState(props.logEntry?.icon);
	const [showIconSelector, setShowIconSelector] = useState(false);

	function startEditing() {
		props.onDaySelected(props.date);
	}

	var title = getDayOfWeek(props.date);
	var today = (title == language["today"]);
	var subtitle = getFullDateString(props.date);

	function updateLogEntryIcon(icon: string) {
		setSelectedIcon(icon);
		var logEntry = { ...props.logEntry! };
		logEntry.icon = icon;
		if (icon == 'star-o') {
			logEntry.icon = "";
		}
		symptomSharkData.saveDailyLogEntry(getDayKey(props.date), logEntry);
		setShowIconSelector(false);
	}

	useEffect(() => {
		setSelectedIcon(props.logEntry?.icon)
	}, [props.logEntry]);

	var noLogEntry = function () {
		if (!props.logEntry || !(props.logEntry.symptoms.length > 0 || props.logEntry.treatments.length > 0 || (props.logEntry && props.logEntry.notes.length > 0))) {
			return true;
		}
		return false;
	}

	if (noLogEntry()) {
		return <Card className={"log-entry" + (!today && noLogEntry() ? " no-shadow" : "")} >
			<UnstyledButton className={"day-header unstyled-button no-log-entry" + (today ? ' today-highlight' : "")} onClick={() => startEditing()}>
				<span className="day-title">{title}</span>
				<span className="day-subtitle">{subtitle}</span>
				{today &&
					<div className="message">{language["tap-to-log-today"]}</div>
				}
				<div className="day-options">
					<i className="fa fa-plus-circle" style={{ color: "#429bdf" }}></i>
				</div>
				{today &&
					<ShinyOverlay />
				}
			</UnstyledButton>
		</Card>;
	}

	return <Card className="log-entry">
		<UnstyledButton style={{ width: "100%" }} className="day-header" onClick={() => startEditing()}>
			<span className="day-title">{title}</span>
			<span className="day-subtitle">{subtitle}</span>
			<div className="day-options">
				{props.logEntry?.overallFeeling &&
					<Face className="ss-face" faceValue={props.logEntry?.overallFeeling} selected />
				}
				<i className={"day-icon fa fa-" + (selectedIcon ? selectedIcon : 'star-o')} onClick={(e) => { setShowIconSelector(!showIconSelector); e.stopPropagation() }}></i>
			</div>
		</UnstyledButton>
		{showIconSelector &&
			<IconSelector onIconSelect={(i) => updateLogEntryIcon(i)} />
		}
		{props.logEntry &&
			<UnstyledButton onClick={() => startEditing()} style={{ width: "100%" }}>
				<LogEntryContent
					participantInfo={props.participantInfo}
					logEntry={props.logEntry} />
			</UnstyledButton>
		}
	</Card>;

}