import React from 'react';
import "./WebPage.css"

export interface WebPageProps {
	children?: React.ReactNode;
	className?: string;
}

export default function (props: WebPageProps) {
	return (
		<div className={"ss-webpage" + (props.className ? " " + props.className : "")}>
			{props.children}
		</div>
	);
}