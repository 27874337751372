import MyDataHelps, { DeviceDataPointQuery, Guid, ParticipantInfo } from "@careevolution/mydatahelps-js";
import { add, parseISO, formatISO } from 'date-fns';

export interface SymptomSharkDataService {
	getParticipantInfo(): Promise<SymptomSharkParticipantInfo>;
	getDailyLogEntries(after?: string, before?: string): Promise<{ [key: string]: DailyLogEntry }>;
	getQuestionsForDoctor(): Promise<QuestionForDoctor[]>;
	getQuestionForDoctor(date: string): Promise<QuestionForDoctor>;
	saveDailyLogEntry(date: string, entry: DailyLogEntry): Promise<any>;
	saveQuestion(question: QuestionForDoctor): Promise<any>;
	saveReportHtml(reportHtml: string): Promise<any>;
	getPacingNotificationsConfiguration(): Promise<PacingNotificationsConfiguration>;
	updatePacingNotificationsConfiguration(config: PacingNotificationsConfiguration): Promise<void>;
	hasSeenPacingNotifications(): Promise<boolean>;
	setHasSeenPacingNotifications(): Promise<any>;
	trackViewVisit(view: string, properties?: { [key: string]: string }): Promise<any>;
}

export interface SymptomSharkParticipantInfo {
	phoneNumber: string;
	participantID: Guid;
	symptoms: SymptomConfiguration[];
	treatments: TreatmentConfiguration[];
	reminderTime1: string;
	reminder1Enabled: boolean;
	reminderTime2: string;
	reminder2Enabled: boolean;
	promotePacingNotifications: boolean;
	appleHealthEnabled: boolean;
}

export interface SymptomConfiguration {
	id: string;
	color: string;
	name: string;
	severityTracking: 'None' | '3PointScale' | '10PointScale';
	inactive?: boolean;
	locked?: boolean;
}

export interface TreatmentConfiguration {
	id: string;
	color: string;
	name: string;
	inactive?: boolean;
	locked?: boolean;
}

export interface DailyLogEntry {
	symptoms: SymptomReference[];
	treatments: TreatmentReference[];
	overallFeeling?: number;
	notes: string;
	icon?: string;
}

export interface SymptomReference {
	id: string;
	severity?: number;
}

export interface TreatmentReference {
	id: string;
}

export interface QuestionForDoctor {
	date: string;
	question: string;
	answer: string | null;
}

export type pacingNotificationType = 'steps' | 'appleHealthHeartRate' | 'fitbitElevatedHeartRate' | 'fitbitCaloriesBurned';

export interface PacingNotificationsConfiguration {
	notificationsEnabled: pacingNotificationType[];
	appleHealthHeartRateLimit: number;
	sendSms: boolean;
	sendPush: boolean;
}

export function convertToSymptomSharkParticipantInfo(info: ParticipantInfo) {
	if (!info.customFields["Symptoms"]?.length) {
		info.customFields["Symptoms"] = "[]";
	}
	if (!info.customFields["Treatments"]?.length) {
		info.customFields["Treatments"] = "[]";
	}

	var symptoms: SymptomConfiguration[] = [];
	try {
		symptoms = JSON.parse(info.customFields["Symptoms"]) as SymptomConfiguration[];
	} catch (e) {
		symptoms = JSON.parse(info.customFields["Symptoms"].replace(/\\/g, "")) as SymptomConfiguration[];
	}
	symptoms.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
	symptoms.forEach(s => {
		if (!s.severityTracking) { s.severityTracking = 'None'; }
	});

	var treatments: TreatmentConfiguration[] = [];
	try {
		treatments = JSON.parse(info.customFields["Treatments"]) as TreatmentConfiguration[];
	} catch (e) {
		treatments = JSON.parse(info.customFields["Treatments"].replace(/\\/g, "")) as TreatmentConfiguration[];
	}
	treatments.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

	return {
		phoneNumber: info.demographics.mobilePhone,
		participantID: info.participantID,
		symptoms: symptoms,
		treatments: treatments,
		reminderTime1: info.customFields["ReminderTime1"],
		reminder1Enabled: info.customFields["Reminder1Enabled"]?.toLowerCase() == "true",
		reminderTime2: info.customFields["ReminderTime2"],
		reminder2Enabled: info.customFields["Reminder2Enabled"]?.toLowerCase() == "true",
		promotePacingNotifications: info.customFields["PromotePacingNotifications"] == "true",
		appleHealthEnabled: info.customFields["AppleHealthEnabled"] == "Yes" || info.customFields["AppleHealthEnabled"] == "true"
	};
}

var service: SymptomSharkDataService = {
	getParticipantInfo: function () {
		return MyDataHelps.getParticipantInfo().then(function (info) {
			return convertToSymptomSharkParticipantInfo(info);
		});
	},
	getDailyLogEntries: function (after?: string, before?: string) {
		var result: { [key: string]: DailyLogEntry } = {};

		var getLogEntryPage = function (pageId?: Guid): Promise<{ [key: string]: DailyLogEntry }> {
			var parameters: DeviceDataPointQuery = {
				namespace: "Project",
				type: "DailyLogEntry",
				pageID: pageId
			};

			if (after) {
				parameters.observedAfter = after;
			}

			if (before) {
				parameters.observedBefore = before;
			}

			return MyDataHelps.queryDeviceData(parameters).then(function (page) {
				var dataPoints = page.deviceDataPoints;
				for (var i = 0; i < dataPoints.length; i++) {
					var logEntry = JSON.parse(dataPoints[i].value);
					var observationDate = dataPoints[i].observationDate;
					if (observationDate) {
						result[observationDate.substring(0, 10)] = upgradeLogEntry(logEntry);
					}
				}

				if (page.nextPageID) {
					return getLogEntryPage(page.nextPageID);
				} else {
					return result;
				}
			});
		}
		return getLogEntryPage() as Promise<{ [key: string]: DailyLogEntry }>;
	},
	getQuestionsForDoctor: function () {
		var result: QuestionForDoctor[] = [];
		var getQuestionsPage = function (pageId?: Guid): Promise<QuestionForDoctor[]> {
			return MyDataHelps.queryDeviceData({
				namespace: "Project",
				type: "QuestionForDoctor",
				pageID: pageId
			}).then(function (page) {
				var dataPoints = page.deviceDataPoints;
				for (var i = 0; i < dataPoints.length; i++) {
					var content = JSON.parse(dataPoints[i].value);
					if (content.question || content.answer) {
						result.push({
							date: dataPoints[i].observationDate!,
							question: content.question,
							answer: content.answer
						});
					}
				}
				if (page.nextPageID) {
					return getQuestionsPage(page.nextPageID);
				} else {
					return result;
				}
			});
		}
		return getQuestionsPage();
	},
	getQuestionForDoctor: function (date: string) {
		var after = add(parseISO(date), { seconds: -1 });
		var before = add(parseISO(date), { seconds: 1 });
		return MyDataHelps.queryDeviceData({
			namespace: "Project",
			type: "QuestionForDoctor",
			observedAfter: after.toISOString(),
			observedBefore: before.toISOString()
		}).then(function (page) {
			var dataPoints = page.deviceDataPoints;
			var content = JSON.parse(dataPoints[0].value);
			return {
				date: dataPoints[0].observationDate!,
				question: content.question,
				answer: content.answer
			}
		});
	},
	saveDailyLogEntry: function (date: string, entry: DailyLogEntry) {
		return MyDataHelps.persistDeviceData([
			{
				type: "DailyLogEntry",
				observationDate: date + "T00:00:00.000Z",
				value: JSON.stringify(entry)
			}
		]);
	},
	saveQuestion: function (question: QuestionForDoctor) {
		return MyDataHelps.persistDeviceData([
			{
				type: "QuestionForDoctor",
				observationDate: question.date,
				value: JSON.stringify({ question: question.question, answer: question.answer })
			}
		]);
	},
	saveReportHtml: function (reportHtml: string) {
		return MyDataHelps.persistDeviceData([
			{
				type: "ReportHtml",
				observationDate: formatISO(new Date()),
				value: reportHtml
			}
		]);
	},
	getPacingNotificationsConfiguration: function () {
		return MyDataHelps.queryDeviceData({
			limit: 1,
			namespace: "Project",
			type: "PacingNotificationsConfiguration"
		}).then(function (result) {
			if (result.deviceDataPoints.length) {
				return JSON.parse(result.deviceDataPoints[0].value);
			} else {
				return {
					notificationsEnabled: [],
					appleHealthHeartRateLimit: 100,
					sendSms: false,
					sendPush: true
				};
			}
		});
	},
	updatePacingNotificationsConfiguration: function (config: PacingNotificationsConfiguration) {
		return MyDataHelps.persistDeviceData([{
			identifier: "",
			value: JSON.stringify(config),
			type: "PacingNotificationsConfiguration"
		}]);
	},
	hasSeenPacingNotifications: function () {
		return MyDataHelps.queryDeviceData({
			limit: 1,
			namespace: "Project",
			type: "HasSeenPacingNotifications"
		}).then(function (result) {
			if (result.deviceDataPoints.length) {
				return true;
			}
			return false;
		});
	},
	setHasSeenPacingNotifications: function () {
		return MyDataHelps.persistDeviceData([{
			identifier: "",
			value: "true",
			type: "HasSeenPacingNotifications"
		}]);
	},
	trackViewVisit: function (view: string, properties?: { [key: string]: string }) {
		var newProps: { [key: string]: any } = {};
		if (properties) {
			for (var key in properties) {
				newProps[key] = properties[key] as any;
			}
		}
		newProps["View"] = view as any;
		return MyDataHelps.trackCustomEvent({
			eventType: "ViewVisit",
			properties: newProps
		});
	}
}

function upgradeLogEntry(logEntry: any) {
	if (logEntry.starred) {
		logEntry.icon = "star";
	}

	var newEntry: DailyLogEntry = {
		overallFeeling: logEntry.overallFeeling,
		notes: logEntry.notes,
		symptoms: logEntry.symptoms.map((s: any) => {
			if (typeof s === 'string') {
				return { id: s };
			}
			return s;
		}),
		treatments: logEntry.treatments.map((s: any) => {
			if (typeof s === 'string') {
				return { id: s };
			}
			return s;
		}),
		icon: logEntry.icon
	}
	return newEntry;
}

export default service;